<template>
  <v-container
    v-if="schedulePeriods.length === 0"
    class="schedule"
    fluid
    fill-height
  >
    <portal to="page-title">
      <v-container class="schedule-header py-0 mx-0">
        <v-row justify="space-between">
          <v-col
            align-self="center"
            class="pl-0"
            cols="auto"
          >
            <v-row
              align="center"
              class="ml-1"
            >
              <DepartmentSelector
                :width="`${pageTitleWidth}px`"
              />
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </portal>
    <v-row
      justify="center"
      style="margin-top: -150px"
    >
      <v-col
        md="6"
        sm="12"
      >
        <div class="mt-10 mx-3 pa-6">
          <v-row justify="center">
            <v-img
              contain
              max-width="50%"
              src="@/assets/images/oops-penguin.svg"
            />
          </v-row>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="darken-2 grey--text mt-5 px-5 text--darken-3 text-center "
            v-html="$t('descriptions.noSchedules')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    class="schedule"
    fluid
  >
    <template v-if="hideSchedule">
      <v-row>
        <v-col cols="auto">
          <v-menu
            v-model="showSchedulePicker"
            min-width="250px"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, value }">
              <v-btn
                class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                color="primary"
                elevation="0"
                outlined
                width="250px"
                v-on="on"
              >
                <span class="body-2 font-weight-medium">
                  {{ selectedScheduleText }}
                </span>
                <v-icon
                  size="20"
                >
                  {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                </v-icon>
              </v-btn>
            </template>
            <ScheduleSelection
              :department="department"
              :selected-schedule-id="selectedScheduleId"
              @select="setSelectedSchedule"
              @close="showSchedulePicker = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-container
        class="schedule"
        fluid
        fill-height
      >
        <portal to="page-title">
          <v-container class="schedule-header py-0 mx-0">
            <v-row justify="space-between">
              <v-col
                align-self="center"
                class="pl-0"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector
                    :width="`${pageTitleWidth}px`"
                  />
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </portal>
        <v-row
          justify="center"
          style="margin-top: -150px"
        >
          <v-col
            md="6"
            sm="12"
          >
            <div class="mt-10 mx-3 pa-6">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="darken-2 grey--text mb-5 px-5 text--darken-3 text-center balancing"
                v-html="$t('descriptions.balancingSchedule')"
              />
              <v-row justify="center">
                <v-img
                  contain
                  max-width="50%"
                  src="@/assets/images/work-hard-on-desktop-penguin.svg"
                />
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="retrievingSchedule && !gridInitialized && showSkeletonLoader">
      <v-container
        class="schedule"
        fluid
      >
        <v-row dense>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col
              v-for="n in 2"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="n in 5"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              class="no-action"
              type="table"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="!retrievingSchedule">
      <v-container
        class="schedule"
        fluid
        :style="gridMaxWidth"
      >
        <portal to="page-title">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-container class="schedule-header py-0">
              <v-row justify="space-between">
                <v-col
                  align-self="center"
                  class="pl-0"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <DepartmentSelector
                      :width="`${pageTitleWidth}px`"
                    />
                  </v-row>
                </v-col>
                <v-spacer />
                <v-col
                  align-self="center"
                  cols="auto"
                >
                  <v-chip
                    :class="['font-weight-medium white--text', scheduleStateIndicator.stateLabelCssClass]"
                    small
                  >
                    {{ $t(scheduleStateIndicator.stateLabelKey) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-row
              class="schedule-header"
              justify="space-between"
            >
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector
                    :width="`${pageTitleWidth}px`"
                  />
                </v-row>
              </v-col>
              <v-spacer />
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <v-chip
                    :class="['font-weight-medium ml-5 white--text', scheduleStateIndicator.stateLabelCssClass]"
                    small
                  >
                    {{ $t(scheduleStateIndicator.stateLabelKey) }}
                  </v-chip>
                  <span :class="['caption font-weight-medium ml-3', scheduleStateIndicator.stateDescCssClass]">
                    {{ $t(scheduleStateIndicator.stateDescKey, { date: scheduleStateIndicator.date }) }}
                  </span>
                </v-row>
              </v-col>
              <v-spacer />
              <template v-if="!$vuetify.breakpoint.smAndDown">
                <v-col
                  align-self="center"
                  class="pr-0"
                  cols="auto"
                >
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="mx-1"
                        icon
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': toggleHelpPanel}"
                      >
                        <v-icon color="primary">
                          fal fa-info-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.information') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-row>
          </template>
        </portal>
        <portal
          v-if="$vuetify.breakpoint.smAndDown"
          to="page-menu"
        >
          <v-list-item
            @click="toggleHelpPanel"
          >
            <v-list-item-action>
              <v-icon small>
                fal fa-info-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('labels.information') }}
            </v-list-item-title>
          </v-list-item>
        </portal>
        <v-row
          justify="end"
          no-gutters
        >
          <v-col cols="12">
            <v-tabs
              class="job-types"
              mobile-breakpoint="768"
              show-arrows
              slider-color="accent"
              slider-size="3"
              :value="selectedJobTypeTab"
            >
              <v-tab
                v-for="(jobTypeGroup, index) in groupedJobTypes"
                :key="index"
                @click.native.prevent.stop.capture="onJobTypeChange(index)"
              >
                <template
                  v-for="(jobType, jobIndex) in jobTypeGroup"
                >
                  <span
                    v-if="jobIndex > 0"
                    :key="`${jobType.id}-plus`"
                    class="ml-2"
                  >
                    +
                  </span>
                  <span
                    :key="`${jobType.id}-name`"
                    :class="jobIndex > 0 ? 'ml-2' : ''"
                  >
                    {{ jobType.name }}
                  </span>
                </template>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          class="grid-action-bar"
          justify="center"
          justify-md="end"
        >
          <v-col cols="auto">
            <v-menu
              v-model="showSchedulePicker"
              min-width="250px"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, value }">
                <v-btn
                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                  color="primary"
                  elevation="0"
                  outlined
                  width="250px"
                  v-on="on"
                >
                  <span class="body-2 font-weight-medium">
                    {{ selectedScheduleText }}
                  </span>
                  <v-icon
                    size="20"
                  >
                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <ScheduleSelection
                :department="department"
                :selected-schedule-id="selectedScheduleId"
                @select="setSelectedSchedule"
                @close="showSchedulePicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.filters.button.classes"
                  icon
                  value="filters"
                  v-bind="attrs"
                  v-on="on"
                  @click="setOpenedPanelName('filters')"
                >
                  <v-icon
                    v-if="hasFilters && openedPanelName !== 'filters'"
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fas fa-filter
                  </v-icon>
                  <v-icon
                    v-else
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fal fa-filter
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.filter') }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.download.button.classes"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="download.dialogOpened = true"
                >
                  <v-icon
                    :class="actionStyles.download.icon.classes"
                    size="16"
                  >
                    fal fa-download
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.download') }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            v-if="scheduleIsSelfSchedule"
            cols="auto"
          >
            <v-switch
              v-model="selfScheduleEnabled"
              class="mt-1 ml-4 show-self-schedule"
              color="success"
              dense
              hide-details
              inset
              @change="(value) => value ? showSelfSchedulePanel() : closeAllPanels()"
            >
              <template v-slot:label>
                <span
                  class="body-2 grey--text text--darken-3"
                  :title="$t('labels.selfScheduleMode')"
                >
                  {{ $t('labels.selfScheduleMode') }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-text-field
          v-model.trim="staffFilter"
          :append-icon="staffFilter ? '' : 'fal fa-search'"
          class="search-staff py-3 ml-4"
          :clearable="!!staffFilter"
          dense
          hide-details
          :placeholder="$t('labels.searchByName')"
          solo
          :style="searchStaffStyle"
        />
        <div
          ref="gridParent"
          class="grid-parent"
          :style="gridStyle"
        />
      </v-container>
      <v-footer
        v-if="missingWeekendShifts > 0 && scheduleIsSelfSchedule"
        fixed
        class="weekend-shifts-details elevation-0 py-1"
        :style="{ 'left': $vuetify.breakpoint.smAndDown ? '0px' : '100px'}"
      >
        <v-row no-gutters>
          <v-col class="py-1">
            <span class="caption white--text">
              {{ $t('descriptions.missingWeekendShifts', { count: missingWeekendShifts }) }}
            </span>
          </v-col>
        </v-row>
      </v-footer>
      <v-dialog
        v-model="download.dialogOpened"
        eager
        persistent
        fullscreen
      >
        <v-card
          id="downloadMasterSchedule"
        >
          <v-card-title class="body-2 d-block mb-2">
            <span class="body-2 font-weight-medium">
              {{ $t('labels.downloadMasterSchedule') }}
            </span>
            <span class="body-2 font-weight-medium primary--text">
              {{ selectedScheduleText }}
            </span>
            <v-btn
              class="float-right mt-n1"
              icon
              small
              @click="download.dialogOpened = false"
            >
              <v-icon small>
                fal fa-times
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            class="pa-0 ma-0"
            :style="downloadGridContainerStyle"
          >
            <div
              ref="downloadGrid"
              class="download-grid"
              :style="downloadGridStyle"
            />
          </v-card-text>
          <v-footer
            color="white"
            fixed
            height="75px"
          >
            <v-select
              v-model="download.pageSize"
              dense
              hide-details
              :items="[{text: $t('labels.letter'), value: 'LETTER'}, {text: $t('labels.legal'), value: 'LEGAL'}]"
              :label="$t('labels.pageSize')"
              outlined
              style="max-width: 184px"
            />
            <v-spacer />
            <v-btn
              text
              @click="download.dialogOpened = false"
            >
              {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn
              class="start-download"
              color="secondary"
              @click="downloadSchedule"
            >
              <v-progress-circular
                v-if="download.inProgress"
                class="px-12"
                color="white"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.downloadPDF') }}
              </span>
            </v-btn>
          </v-footer>
        </v-card>
      </v-dialog>
      <SidePanel
        :panels="sidePanels"
        @transitionend="redrawGrid(true)"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE_TABLE, SEARCH_INPUT_DEBOUNCE } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';
import Mousetrap from '@/plugins/mousetrap';
import DailySummary from '@/views/scheduling/panels/DailySummary';
import ActivityDetails from '@/views/scheduling/panels/ActivityDetails';
import ShiftDetails from '@/views/scheduling/panels/ShiftDetails';
import EventDetails from '@/views/scheduling/panels/EventDetails';
import SelectCell from '@/views/scheduling/panels/SelectCell';
import EmptyCell from '@/views/scheduling/panels/EmptyCell';
import Help from '@/views/scheduling/panels/Help';
import Filters from '@/views/scheduling/panels/Filters';
import SelfSchedule from '@/views/scheduling/panels/SelfSchedule';
import SidePanel from '@/components/SidePanel';
import DepartmentSelector from '@/components/DepartmentSelector';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { calculateScheduleRangeForDate, getDefaultFilters, isProductiveShift, isWorkingShiftForDisplay, shiftMatchesFilters, wasShiftModifiedByManagement } from '@/utils/scheduling';
import { userMatchesText } from '@/utils/org';
import scssVariables from '@/assets/sass/_variables.scss';

const DeepDiff = require('deep-diff').diff;
const cheetahGrid = require('@nurse-brite/cheetah-grid');

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;

export default {
  components: {
    DepartmentSelector,
    ScheduleSelection,
    SidePanel
  },

  props: {
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },

  data () {
    const ALL_JOB_TYPES = 0;

    return {
      ALL_JOB_TYPES,
      COL_WIDTH_SCHEDULE: 40,
      COL_WIDTH_USER: 200,
      FROZEN_COLUMN_COUNT: 1,
      SIDE_PANEL_WIDTH: 440,
      download: {
        dialogOpened: false,
        grid: null,
        inProgress: false,
        limit: {
          'LEGAL': 18,
          'LETTER': 23
        },
        page: 0,
        pageSize: 'LETTER'
      },
      filters: getDefaultFilters(),
      grid: null,
      gridCallbacks: [],
      gridHeight: 500,
      gridTop: 0,
      gridWidth: 500,
      gridState: {
        canScrollLeft: false,
        canScrollRight: false,
        scrollLeft: 0,
        todaysRectangle: null
      },
      gridfilterDataSource: null,
      helpPanel: [],
      helpPanelData: {
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      },
      showHelp: false,
      missingWeekendShifts: 0,
      mousetrap: new Mousetrap(this.$el),
      openedPanelName: undefined,
      pageTitleWidth: 100,
      panels: [], // Opened right side panels
      persistentPanels: [],
      retrievingSchedule: true, // Flag for paginating through different time period of the schedule.
      selectedCell: {
        date: null,
        user: null
      },
      selectedJobTypeTab: ALL_JOB_TYPES,
      selectedSchedule: null,
      selectedScheduleId: this.$store.state.scheduling.activeScheduleId,
      selfScheduleEnabled: false,
      showPanelOnCellSelect: false,
      showSchedulePicker: false,
      staffFilter: ''
    };
  },
  computed: {
    actionStyles () {
      const defaultButtonClasses = ['grey', 'lighten-2', 'mr-2'];
      const defaultIconClasses = ['grey--text', 'text--darken-3'];
      const styles = {
        download: {
          button: {
            classes: defaultButtonClasses.concat(['download'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        filters: {
          button: {
            classes: defaultButtonClasses.concat(['filters'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        viewOpenShift: {
          button: {
            classes: defaultButtonClasses.concat(['view-open-shift'])
          },
          icon: {
            classes: defaultIconClasses
          }
        }
      };

      if (this.hasFilters) {
        styles.filters = {
          button: {
            classes: ['primary', 'lighten-2', 'mr-2', 'filters']
          },
          icon: {
            classes: ['white--text']
          }
        };
      }

      if (this.openedPanelName && styles[this.openedPanelName]) {
        styles[this.openedPanelName].button.classes = ['primary', 'mr-2', this.openedPanelName];
        styles[this.openedPanelName].icon.classes = ['white--text'];
      }

      return styles;
    },
    allDepartments () {
      return this.$store.state.org.departments.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    allRecords () {
      return _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['records'], []);
    },
    allUserRecordMap () {
      return _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['userRecordMap'], {});
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getActiveDepartment']() || {};
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    downloadGridContainerStyle () {
      return {
        height: `${this.innerHeight - 130}px`,
        width: `${this.innerWidth}px`,
        overflow: 'scroll'
      };
    },
    downloadGridStyle () {
      let width = 16;
      for (let h of this.printHeaders) {
        width += h.width;
      }
      let height = (this.download.limit[this.download.pageSize] * 60) + 60 + 16;
      return {
        height: `${height * 5}px`,
        width: `${width}px`,
        opacity: 1.0
      };
    },
    emptyMessage () {
      let message = '';
      const containerClasses = 'body-1 font-weight-medium grey--text text--darken-3 pa-5';
      if (this.staffFilter) {
        message = `
          <div class="${containerClasses}">
            ${this.$t('descriptions.emptyGridStaffFilter', { staff_filter: _.escape(this.staffFilter) })}
            <hr role="separator" aria-orientation="horizontal" class="my-5 v-divider theme--light">
            <div class="mb-5">
              ${this.$t('descriptions.emptyGridHelpTitle')}
            </div>
            <ul>
              <li>${this.$t('descriptions.emptyGridHelpRemoveFilters')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShiftTypes')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShorterWords')}</li>
              <li>${this.$t('descriptions.emptyGridHelpSpelling')}</li>
            </ul>
          </div>
        `;
      } else {
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES && this.jobTypes[this.selectedJobTypeTab]) {
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridSpecificJobType', { job_type: _.escape(this.jobTypes[this.selectedJobTypeTab].description.toLowerCase()) })}
            </div>
          `;
        } else {
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridAllJobTypes')}
            </div>
          `;
        }
      }

      return message;
    },
    eventTypes () {
      return this.$store.state.org.eventTypes.reduce(function (eventTypes, eventType) {
        eventTypes[eventType.id] = eventType;
        return eventTypes;
      }, {});
    },
    gridInitialized () {
      return !!this.grid;
    },
    gridLanguage () {
      return {
        'clear': this.$t('labels.clear'),
        'daysOfWeek': [
          this.$t('dates.dayOfWeek.0'),
          this.$t('dates.dayOfWeek.1'),
          this.$t('dates.dayOfWeek.2'),
          this.$t('dates.dayOfWeek.3'),
          this.$t('dates.dayOfWeek.4'),
          this.$t('dates.dayOfWeek.5'),
          this.$t('dates.dayOfWeek.6')
        ],
        'months': [
          this.$t('dates.month.0'),
          this.$t('dates.month.1'),
          this.$t('dates.month.2'),
          this.$t('dates.month.3'),
          this.$t('dates.month.4'),
          this.$t('dates.month.5'),
          this.$t('dates.month.6'),
          this.$t('dates.month.7'),
          this.$t('dates.month.8'),
          this.$t('dates.month.9'),
          this.$t('dates.month.10'),
          this.$t('dates.month.11')
        ],
        'disclaimerHint': this.$t('descriptions.disclaimer'),
        'visibilityHint': this.$t('descriptions.commentVisibilitySchedulers'),
        'notes': this.$t('labels.notes'),
        'notesPlaceholder': `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.userNotesPlaceholder')}`,
        'save': this.$t('labels.save'),
        'viewStaffDetails': this.$t('labels.viewStaffDetails'),
        'week': this.$t('labels.week')
      };
    },
    groupedJobTypes () {
      const groupedJobTypes = [];
      const jobTypes = this.jobTypes;
      let group = [jobTypes[0]];
      for (let i = 1, count = jobTypes.length; i < count; i++) {
        if (group[group.length - 1].groupRight) {
          group.push(jobTypes[i]);
        } else {
          groupedJobTypes.push([...group]);
          group = [jobTypes[i]];
        }
      }
      groupedJobTypes.push(group);

      return groupedJobTypes;
    },
    gridMaxWidth () {
      const MARGIN = 40; // Compensate left and right margin of the grid.
      const widthForScheduleCols = this.department.numOfWeeksPerSchedule * 7 * this.COL_WIDTH_SCHEDULE; // Hospital opens 7 days a week.
      const maxWidthForGrid = this.COL_WIDTH_USER + widthForScheduleCols + MARGIN;

      return {
        maxWidth: maxWidthForGrid + 'px'
      };
    },
    gridStyle () {
      // The grid has two parent containers and each one has 12px padding and here
      // we add an extra 6px buffer.
      let padding = 0;
      if (this.$vuetify.breakpoint.smAndDown) {
        // smAndDown triggers the navigation bar to the bottom of the screen therefore
        // the offset here is the height of that navigation bar.
        padding += 60;
      } else {
        padding += 50;
      }

      return {
        height: `${this.gridHeight - padding}px`,
        opacity: 1.0
      };
    },
    hasFilters () {
      const diff = DeepDiff(getDefaultFilters(), this.filters) || [];
      return diff.length > 0;
    },
    hasActivityFilters () {
      const activityFilters = _.cloneDeep(this.filters);
      delete activityFilters.user;
      const defaultFilters = getDefaultFilters();
      delete defaultFilters.user;
      const diff = DeepDiff(defaultFilters, activityFilters) || [];
      return diff.length > 0;
    },
    hasSelectedShiftTypes () {
      return this.selectedShiftTypes.length > 0;
    },
    hasTabFilters () {
      return this.selectedJobTypeTab !== this.ALL_JOB_TYPES;
    },
    headers () {
      const headers = _.cloneDeep(_.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['headers'], []));
      const filteredHeaders = [];
      for (let i = 0, len = headers.length; i < len; i++) {
        if (headers[i].type === 'user') {
          filteredHeaders.push({
            columnType: 'user',
            headerType: 'user',
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            showAvatar: true,
            showSettings: false,
            status: (user) => {
              let status = user.jobTypeName ? user.jobTypeName : '';
              status += user.jobStatusShortCode ? ` ${user.jobStatusShortCode}` : '';
              if (user.departmentId !== this.department.id) {
                status += ` │ ${user.departmentName}`;
              }
              return status;
            },
            ...headers[i]
          });
        } else if (headers[i].type === 'schedule') {
          filteredHeaders.push({
            columnType: 'schedule',
            headerType: 'schedule',
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            ...headers[i]
          });
        }
      }
      return filteredHeaders;
    },
    hideSchedule () {
      const state = _.find(this.$store.state.org.settings.scheduling.states, (s) => s.state === SCHEDULE_STATES.DRAFT);
      let hideScheduleFromStaff = false;
      if (state) {
        hideScheduleFromStaff = _.get(state, 'hideScheduleFromStaff', false);
      }
      return hideScheduleFromStaff && this.selectedSchedule && this.selectedSchedule.state === SCHEDULE_STATES.DRAFT;
    },
    jobStatus () {
      return this.$store.state.org.jobStatus.reduce(function (jobStatus, js) {
        jobStatus[js.id] = { ...js };
        return jobStatus;
      }, {});
    },
    jobTypes () {
      let jobTypes = [
        {
          description: 'All Job Types',
          id: 0,
          name: 'All',
          associatedShiftTypes: []
        }
      ];
      if (this.schedule) {
        const scheduleJobTypes = this.$store.getters['scheduling/getJobTypes'](this.selectedScheduleId);

        for (let i = 0, count = scheduleJobTypes.length; i < count; i++) {
          const jobInfo = scheduleJobTypes[i];
          jobTypes.push({
            ...jobInfo
          });
          jobTypes[0].associatedShiftTypes.push(...jobInfo.associatedShiftTypes);
        }

        jobTypes[0].associatedShiftTypes = _.uniq(jobTypes[0].associatedShiftTypes);
      }

      return jobTypes;
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    lastEditableDate () {
      return moment().subtract(1, 'day').format('YYYY-MM-DD');
    },
    paginatedPrintRecords () {
      let records = this.printRecords;
      if (this.download.inProgress) {
        const start = this.download.page * this.download.limit[this.download.pageSize];
        const end = start + this.download.limit[this.download.pageSize];
        records = records.slice(start, end);
      }
      return records;
    },
    printHeaders () {
      const headers = _.cloneDeep(this.headers);
      const idx = _.findIndex(headers, (h) => h.headerType === 'user');
      if (idx >= 0) {
        headers[idx].maxWidth = 150;
        headers[idx].minWidth = 150;
        headers[idx].width = 150;
        headers[idx].showAvatar = false;
        headers[idx].showSettings = false;
        headers[idx].renderNameAsLink = false;
      }
      return headers;
    },
    printRecords () {
      const filters = this.getGridFilter();
      let records = this.records;
      if (filters) {
        records = _.filter(this.records, (r) => filters(r));
      }
      return records;
    },
    records () {
      const records = _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['records'], []);
      const filteredRecords = [];
      const shiftFilterEnabled = _.get(this.filters, 'shift.enabled', true);
      const eventFilterEnabled = _.get(this.filters, 'event.enabled', true);
      const eventMatches = (a) => {
        if (this.filters.event) {
          return (this.filters.event.types.length === 0 || this.filters.event.types.includes(a.typeId));
        }
        return true;
      };
      for (let r of records) {
        const row = {
          user: r.user
        };
        let activityCount = 0;
        for (let d in r) {
          if (d !== 'user') {
            row[d] = {
              activities: [],
              request: r[d].request
            };

            for (let a of r[d].activities) {
              switch (a.type) {
                case 'shift':
                  if (shiftFilterEnabled && shiftMatchesFilters(a, this.filters, this.$store)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
                case 'event':
                  if (eventFilterEnabled && eventMatches(a)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
              }
            }
          }
        }
        if (!this.hasActivityFilters || activityCount > 0) {
          filteredRecords.push(row);
        }
      }
      return filteredRecords;
    },
    schedule () {
      return this.$store.state.scheduling.schedules[this.selectedScheduleId];
    },
    scheduleIsReadOnly () {
      return true;
    },
    scheduleIsSelfSchedule () {
      if (!this.$can('edit', 'masterSchedule')) {
        return false;
      }
      const schedulingSettings = this.$store.state.org.settings.scheduling;
      if (schedulingSettings.nurseSelfSchedule.alwaysOpen) {
        return true;
      } else {
        const profile = this.$store.state.account.profile;
        if (this.schedule.state === SCHEDULE_STATES.SELF_SCHEDULE) {
          const selfScheduleLatent = schedulingSettings.nurseSelfSchedule.latent;
          const selfSchedulePeriod = schedulingSettings.nurseSelfSchedule.period;
          const schedulePeriod = schedulingSettings.period;
          let selfScheduleStartDate = moment(`${this.schedule.startOn} ${schedulingSettings.nurseSelfSchedule.startTime}`).subtract(schedulePeriod - selfScheduleLatent, 'd');
          const selfScheduleEndDate = moment(selfScheduleStartDate).add(selfSchedulePeriod - 1, 'd');

          if (Array.isArray(schedulingSettings.nurseSelfSchedule.segments) && schedulingSettings.nurseSelfSchedule.segments.length > 0) {
            const segmentIdx = _.findIndex(schedulingSettings.nurseSelfSchedule.segments, (s) => s.allowedJobStatus.includes(profile.jobStatusId));
            if (segmentIdx >= 0) {
              if (schedulingSettings.nurseSelfSchedule.segments[segmentIdx].latent === null) {
                return false;
              }
              // Have to subtract 1 since the latent value is the day number from the start of self schedule which
              // is 1-based
              selfScheduleStartDate.add(schedulingSettings.nurseSelfSchedule.segments[segmentIdx].latent - 1, 'd');
            }
          }

          const today = moment();
          return today.isSameOrAfter(selfScheduleStartDate) && today.isSameOrBefore(selfScheduleEndDate);
        }
        return false;
      }
    },
    schedulePeriods () {
      const schedulingPeriods = this.department.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          if (!schedulingPeriods[i].id) {
            continue;
          }
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    schedulePeriodSettings () {
      return this.$store.state.org.settings.scheduling;
    },
    schedulePublishDueDate () {
      let date = moment(this.schedule.startOn);
      date.subtract(this.schedulePeriodSettings.postLeadTime, 'd');
      date.add(this.schedulePeriodSettings.nurseReviewPeriod, 'd');
      return date;
    },
    scheduleStateIndicator () {
      return this.getScheduleStateIndicator({
        state: this.state,
        startOn: this.schedule.startOn,
        endOn: this.schedule.endOn
      }, this.$vuetify.breakpoint.smAndDown);
    },
    scheduleStates () {
      return _.get(this.$store.state.org, 'settings.scheduling.states', []).map((item) => item.state);
    },
    scheduleSymbols () {
      return this.$store.state.org.settings.scheduling.symbols;
    },
    searchStaffStyle () {
      return {
        width: `${this.COL_WIDTH_USER * 0.85}px`
      };
    },
    selectedShiftTypes () {
      return _.get(this.filters, 'shift.types', []);
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(function (shiftTypes, shiftType) {
        shiftTypes[shiftType.id] = { ...shiftType };
        // Filter out empty properties from the styles object that have empty values to allow code to easily
        // override styles for validation errors.
        shiftTypes[shiftType.id].styles = _.pickBy(shiftTypes[shiftType.id].styles, _.identity);
        return shiftTypes;
      }, {});
    },
    shiftTypesForSelectedJobType () {
      return _.map(this.jobTypes[this.selectedJobTypeTab].associatedShiftTypes, (id) => {
        return this.shiftTypes[id];
      });
    },
    shiftTypesForSelectedJobTypeWithoutOnCall () {
      return _.filter(this.shiftTypesForSelectedJobType, (shiftType) => !shiftType.onCall);
    },
    showDailyScheduleRedirect () {
      return false;
    },
    sidePanels () {
      const helpPanel = [{
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      }];

      if (this.showHelp) {
        return helpPanel;
      } else {
        return [...this.persistentPanels, ...this.panels];
      }
    },
    state () {
      return this.schedule ? this.schedule.state : '';
    },
    todaysColumnIndex () {
      return _.findIndex(this.headers, (h) => h.columnType === 'schedule' && moment(h.caption).format('YYYY-MM-DD') === this.lastEditableDate);
    },
    userRecordMap () {
      const userRecordMap = {};
      for (let i = 0, count = this.records.length; i < count; i++) {
        userRecordMap[this.records[i].user.userId] = i;
      }
      return userRecordMap;
    },
    selectedScheduleText () {
      const startFrom = moment(this.selectedSchedule.startOn);
      const endBy = moment(this.selectedSchedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    ...mapState(['sidePanelOpened'])
  },
  watch: {
    department () {
      this.redraw();
    },
    'download.dialogOpened' () {
      if (this.download.dialogOpened) {
        this.renderDownloadGrid();
      } else {
        if (this.download.grid) {
          this.download.grid.dispose();
        }
        this.download.grid = null;
        this.download.inProgress = false;
        this.download.page = 0;
      }
    },
    gridLanguage () {
      if (this.grid) {
        this.grid.language = this.gridLanguage;
        this.updateGridEmptyMessage();
      }
    },
    openedPanelName (openedPanelName) {
      if (openedPanelName) {
        switch (openedPanelName) {
          case 'filters':
            this.closeAllPanels();
            this.openFiltersPanel();
            break;
          case 'viewOpenShift':
            this.openOpenShiftsListPanel();
            break;
        }
      } else {
        this.closeAllPanels();
        this.closeAllPersistentPanels();
      }
    },
    'panels.length' (newLength, prevLength) {
      if (newLength === 0) {
        this.selfScheduleEnabled = false;
        this.showPanelOnCellSelect = false;
        this.$store.commit('scheduling/update_panel', { panel: 'dailySummary', prop: 'tab', value: 'summary' });
      }
    },
    'records' () {
      if (this.grid) {
        const self = this;
        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
      }
    },
    selectedJobTypeTab () {
      this.refreshPanels();
    },
    sidePanelOpened (newValue, oldValue) {
      // This is the global side panel
      if (newValue !== oldValue) {
        this.redrawGrid(true);
      }
    },
    staffFilter: _.debounce(function () {
      this.filterGrid(false);
    }, SEARCH_INPUT_DEBOUNCE)
  },
  mounted: function () {
    if (this.selectedScheduleId) {
      this.retrieveScheduleById(this.selectedScheduleId).then((schedule) => {
        this.selectedSchedule = schedule;
        this.retrieveSchedule();
      }).catch(() => {
        if (this.schedulePeriods[0]) {
          this.selectedScheduleId = this.schedulePeriods[0].value;
          this.selectedSchedule = this.schedulePeriods[0];
          this.retrieveSchedule();
        }
      });
    } else {
      if (this.schedulePeriods[0]) {
        this.selectedScheduleId = this.schedulePeriods[0].value;
        this.selectedSchedule = this.schedulePeriods[0];
        this.retrieveSchedule();
      }
    }
    this.$nextTick(function () {
      this.updatePageTitleWidth();
    });
    window.addEventListener('resize', this.updatePageTitleWidth);
  },
  beforeDestroy: function () {
    this.unbindShortcuts();
    this.destroyGrid();
    window.removeEventListener('resize', this.updatePageTitleWidth);
  },
  methods: {
    bindShortcuts () {
      this.mousetrap.bind(['up', 'right', 'down', 'left'], (e, key) => {
        if (this.grid) {
          this.grid.keyDownMove(e);
        }
      });

      this.mousetrap.bind(['enter'], (e, key) => {
        if (this.grid && this.grid.hasFocusGrid()) {
          this.showPanelOnCellSelect = true;
          this.showCellDetails(this.grid.selection.select);
        }
      });

      this.mousetrap.bindGlobal(['mod+/'], (e, key) => {
        this.toggleHelpPanel();
      });
    },
    closeAllPanels () {
      this.panels.splice(0, this.panels.length);
    },
    closeAllPersistentPanels () {
      this.openedPanelName = undefined;
      this.persistentPanels.splice(0, this.persistentPanels.length);
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    closeLastPanel () {
      this.panels.pop();
    },
    closeLastPersistentPanel () {
      this.persistentPanels.pop();
    },
    destroyGrid () {
      if (this.grid) {
        this.grid.dispose();
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadSchedule () {
      const pageCount = Math.ceil(this.printRecords.length / this.download.limit[this.download.pageSize]);
      this.download.inProgress = true;
      setTimeout(() => {
        this.$nextTick(() => {
          const content = [];
          let printing = Promise.resolve();
          let page = 0;
          for (let i = 0; i < pageCount; i++) {
            printing = printing.then(() => {
              return new Promise((resolve, reject) => {
                this.download.page = page;
                this.$nextTick(() => {
                  this.renderDownloadGrid().then(() => {
                    this.$nextTick(() => {
                      const canvas = this.$refs.downloadGrid.getElementsByTagName('canvas')[0];
                      const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                      switch (this.download.pageSize) {
                        case 'LEGAL':
                          content.push({
                            image: dataURL,
                            width: 985,
                            height: 2640,
                            margin: [0, 0, 0, 0]
                          });
                          break;
                        case 'LETTER':
                        default:
                          content.push({
                            image: dataURL,
                            width: 772,
                            height: 2640,
                            margin: [0, 0, 0, 0]
                          });
                          break;
                      }
                      page++;
                      resolve();
                    });
                  });
                });
              });
            });
          }
          printing.then(() => {
            const docDefinition = {
              defaultStyle: {
                color: '#000000',
                fontSize: 12,
                lineHeight: 1.2
              },
              styles: {
                pageSubtitle: {
                  alignment: 'center',
                  bold: true,
                  margin: [0, 0, 0, 0]
                },
                pageTitle: {
                  alignment: 'center',
                  bold: true,
                  margin: [0, 10, 0, 0]
                }
              },
              content,
              header: () => {
                return [
                  {
                    stack: [
                      {
                        text: this.department.name,
                        style: ['pageTitle']
                      },
                      {
                        text: this.selectedScheduleText,
                        style: ['pageSubtitle']
                      }
                    ]
                  }
                ];
              },
              pageMargins: [10, 60, 10, 10],
              pageOrientation: 'landscape',
              pageSize: this.download.pageSize
            };
            pdfMake.createPdf(docDefinition).download(`${this.department.name} ${this.selectedScheduleText}`);
            this.download.dialogOpened = false;
          });
        });
      }, 0);
    },
    filterGrid (focusGrid) {
      if (this.grid) {
        const filter = this.getGridFilter();
        let newSelectedRow, field;
        // If a cell is selected by the user check if that cell is still on the grid after the filter
        // and select the cell at it's new index. If the cell is no longer in the grid then reset the
        // focus to the top left cell vertex (0, 0) which corresponds to the first cell of the 'user' column.
        // When we need to select the header row we set then row index to -1 because the focusGridCell
        // function adds 1 to the value we pass in.
        if (this.selectedCell.date) {
          if (moment.isMoment(this.selectedCell.date)) {
            field = this.selectedCell.date.valueOf();
          } else {
            field = this.selectedCell.date;
          }
          if (this.selectedCell.user) {
            const newRow = this.getCellRowByUser(this.selectedCell.user);
            if (newRow >= 0) {
              newSelectedRow = newRow;
            } else {
              newSelectedRow = -1;
              field = 'user';
            }
          } else {
            newSelectedRow = -1;
          }
        }

        this.gridfilterDataSource.filter = filter;
        this.updateGridEmptyMessage();
        this.redrawGrid();
        // Redraw a second time. Redrawing once does not render the grid correctly. Blank rows are being
        // inserted. This behavior can also be observed in the cheetah-grid demo page.
        this.redrawGrid(focusGrid);
        if (field) {
          this.grid.selectGridCell(field, newSelectedRow, focusGrid);
        }
      }
    },
    focusGrid () {
      if (this.grid) {
        this.grid.focus();
      }
    },
    getActivityDetailsPanelData (schedule) {
      let date, details, user;
      if (schedule.cell) {
        try {
          date = this.getCellDate(schedule.cell);
          details = this.getCellValue(schedule.cell);
          user = this.getCellUser(schedule.cell);
        } catch {
          // An error can happen if the cell is no on the grid due to filtering, in taht case
          // we display the empty cell panel.
          return {
            component: EmptyCell,
            props: {
              date
            },
            events: {}
          };
        }
      } else {
        date = schedule.date;
        details = schedule.details;
        user = schedule.user;
      }

      const activities = _.cloneDeep(_.get(details, 'activities', []));
      const shifts = [];
      const events = [];
      for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
        switch (activities[i].type) {
          case 'shift':
            shifts.push(activities[i]);
            break;
          case 'event':
            events.push(activities[i]);
            break;
        }
      }

      const shiftCount = shifts.length;
      const eventCount = events.length;
      if (eventCount > 1 || (shiftCount > 0 && eventCount > 0)) {
        return {
          component: ActivityDetails,
          props: {
            date,
            events,
            shifts,
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'show-event': (event) => {
              this.showEventDetails({
                date,
                event,
                user
              });
            },
            'show-shifts': (shifts) => {
              this.showShiftDetails({
                date,
                shifts,
                user
              });
            }
          }
        };
      } else if (shiftCount > 0) {
        return this.getShiftDetailsPanelData({
          date,
          shifts,
          user
        });
      } else if (eventCount > 0) {
        return this.getEventDetailsPanelData({
          date,
          event: events[0],
          user
        });
      }

      return {
        component: EmptyCell,
        props: {
          date
        },
        events: {}
      };
    },
    getCellDate (cell) {
      const col = this.grid.header[cell.col];
      return moment(col.caption);
    },
    getCellUser (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row['user'];
    },
    getCellRowByUser (userId) {
      // This only looks in the set of filtered rows and returns -1 when the row is not found
      const filter = this.getGridFilter();
      let newRow = 0;
      for (let i = 0, len = this.records.length; i < len; i++) {
        if (filter ? filter(this.records[i]) : true) {
          if (this.records[i].user.userId === userId) {
            return newRow;
          }
          newRow++;
        }
      }
      return -1;
    },
    getCellValue (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      const col = this.grid.header[cell.col];
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row[col.field];
    },
    getDailySummaryPanelData (date) {
      let staffNeeded = {};
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        if (this.hasSelectedShiftTypes) {
          for (let i = 0, len = this.jobTypes.length; i < len; i++) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (this.jobTypes[i].id !== this.ALL_JOB_TYPES &&
                  _.indexOf(this.jobTypes[i].associatedShiftTypes, shiftTypeId) >= 0) {
                _.setWith(
                  staffNeeded,
                  [this.jobTypes[i].id, shiftTypeId],
                  _.get(this.jobTypes[i], ['staffNeeded', shiftTypeId], 0),
                  Object
                );
              }
            }
          }
        } else {
          const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedScheduleId);
          for (let jobId in jobTypesById) {
            staffNeeded[jobId] = jobTypesById[jobId].staffNeeded;
          }
        }
      } else {
        const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedScheduleId);
        let jobTypeIds = _.keys(jobTypesById);
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
          jobTypeIds = this.getSelectedJobTypeIds();
        }
        for (let id of jobTypeIds) {
          let shifts = {};
          if (this.hasSelectedShiftTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
                shifts[shiftTypeId] = _.get(jobTypesById, [id, 'staffNeeded', shiftTypeId], 0);
              }
            }
          } else {
            shifts = _.get(jobTypesById, [id, 'staffNeeded'], {});
          }
          staffNeeded[id] = shifts;
        }
      }

      return {
        component: DailySummary,
        props: {
          date,
          scheduleId: this.selectedScheduleId,
          showSummary: false,
          staffNeeded,
          data: this.getDailySummaryShiftsData(date),
          requests: this.getDailySummaryRequestsData(date)
        },
        events: {
          'show-shift-details': ({ date, shifts, user }) => {
            this.showShiftDetails({
              date,
              shifts,
              user
            });
          },
          'show-event-details': ({ date, event, user }) => {
            this.showEventDetails({
              date,
              event,
              user
            });
          }
        }
      };
    },
    getDailySummaryShiftsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          const activities = _.get(this.records[i], [field, 'activities'], []);
          if (activities.length > 0) {
            summaryData.push({
              date,
              details: this.records[i][field],
              user: this.records[i].user
            });
          }
        }
      }
      return summaryData;
    },
    getDailySummaryRequestsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        const hasRequest = _.get(this.records[i], [field, 'request', 'id'], 0) > 0;
        if (matches && hasRequest) {
          summaryData.push({
            date,
            details: this.records[i][field].request,
            user: this.records[i].user
          });
        }
      }
      return summaryData;
    },
    getEventDetailsPanelData (data) {
      const { date, event, user } = data;
      const readOnly = this.isDateReadOnly(date);

      return {
        component: EventDetails,
        props: {
          'allow-delete': false,
          date,
          event,
          'read-only': readOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          }
        }
      };
    },
    getEventRequestDetails (request) {
      return {
        ...request,
        user: this.allRecords[this.allUserRecordMap[request.assigneeId]].user,
        type: 'event'
      };
    },
    getGridFilter () {
      const filters = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        let filterForCharge = false;
        const associatedJobTypes = this.getSelectedJobTypes().map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue);
          return accumulator;
        }, []);
        for (let i = 0, count = associatedJobTypes.length; i < count; i++) {
          if (this.jobTypesById[associatedJobTypes[i]] && _.get(this.jobTypesById[associatedJobTypes[i]], 'settings.isChargeNurse', false)) {
            filterForCharge = true;
            break;
          }
        }
        filters.push((record) => {
          return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0 ||
            (filterForCharge && record.user.charge);
        });
      }
      const status = _.get(this.filters, 'user.status', []);
      if (status.length > 0) {
        filters.push((record) => {
          return status.includes(record.user.jobStatusId);
        });
      }
      const shiftTypes = _.get(this.filters, 'user.shiftTypes', []);
      if (shiftTypes.length > 0) {
        filters.push((record) => {
          return shiftTypes.includes(record.user.shiftTypeId);
        });
      }
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filters.push((record) => {
          return userMatchesText(record.user, text);
        });
      }

      const filtersCount = filters.length;
      let filter = null;
      if (filtersCount > 0) {
        filter = (record) => {
          if (_.isEmpty(record)) {
            return false;
          }
          let match = true;
          for (let i = 0; i < filtersCount; i++) {
            if (_.isFunction(filters[i])) {
              match &= filters[i](record);
            } else {
              match &= _.get(record, filters[i].key, null) === filters[i].value;
            }
          }
          return match;
        };
      }
      return filter;
    },
    getJobTypeIndex (jobId) {
      return _.findIndex(this.jobTypes, function (o) { return o.id === jobId; });
    },
    getScheduleStateIndicator (schedule, mobile) {
      const stateIndicator = {
        date: '',
        stateDescCssClass: 'grey--text text--darken-1'
      };
      switch (schedule.state) {
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          stateIndicator.stateLabelCssClass = 'error';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePendingApprovalDirectorAbbr' : 'labels.scheduleStatePendingApprovalDirector';
          stateIndicator.stateDescCssClass = 'grey--text text--darken-1';
          stateIndicator.stateDescKey = 'descriptions.scheduleStatePendingApprovalDirector';
          break;

        case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
          stateIndicator.stateLabelCssClass = 'info';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateUnderReviewNurseAbbr' : 'labels.scheduleStateUnderReviewNurse';
          stateIndicator.stateDescCssClass = 'info--text';
          stateIndicator.stateDescKey = 'descriptions.scheduleUnderNurseReviewUntil';
          if (this.schedulePublishDueDate.isSame(moment(), 'year')) {
            stateIndicator.date = this.schedulePublishDueDate.format(this.dateFormatShort);
          } else {
            // Only displays year info when the due date is not in the current year.
            stateIndicator.date = this.schedulePublishDueDate.format(this.dateFormatLong);
          }
          break;

        case SCHEDULE_STATES.PUBLISHED:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePublishedAbbr' : 'labels.scheduleStatePublished';
          stateIndicator.stateDescCssClass = 'grey--text text--darken-1';
          stateIndicator.stateDescKey = 'descriptions.scheduleStatePublished';
          break;

        case SCHEDULE_STATES.DRAFT:
          stateIndicator.stateLabelCssClass = 'nb-orange';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateDraftAbbr' : 'labels.scheduleStateDraft';
          stateIndicator.stateDescKey = 'descriptions.scheduleStateDraft';
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateSelfScheduleAbbr' : 'labels.scheduleStateSelfSchedule';
          stateIndicator.stateDescKey = this.scheduleIsSelfSchedule ? 'descriptions.scheduleStateSelfSchedule' : 'descriptions.readOnlyMode';
          break;
        case SCHEDULE_STATES.CURRENT:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateCurrentAbbr' : 'labels.scheduleStateCurrent';
          stateIndicator.stateDescKey = 'descriptions.scheduleStateCurrent';
          break;
        case SCHEDULE_STATES.INITIAL:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateInitialAbbr' : 'labels.scheduleStateInitial';
          stateIndicator.stateDescKey = 'descriptions.scheduleStateInitial';
          break;
        case SCHEDULE_STATES.PAST:
          stateIndicator.stateLabelCssClass = 'grey darken-1';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePastAbbr' : 'labels.scheduleStatePast';
          stateIndicator.stateDescKey = 'descriptions.scheduleStatePast';
          break;
        default:
          stateIndicator.stateLabelCssClass = '';
          stateIndicator.stateLabelKey = '';
          stateIndicator.stateDescKey = '';
      }

      return stateIndicator;
    },
    getSelectedJobTypeIds () {
      if (this.groupedJobTypes[this.selectedJobTypeTab]) {
        return this.groupedJobTypes[this.selectedJobTypeTab].map((jt) => jt.id);
      }
      return [];
    },
    getSelectedJobTypes () {
      return this.groupedJobTypes[this.selectedJobTypeTab];
    },
    getSelfSchedulePanelData () {
      return {
        component: SelfSchedule,
        props: {
          schedule: this.selectedSchedule
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            this.selfScheduleEnabled = false;
            return true;
          },
          'update': (activities) => {
            this.$store.commit('scheduling/update_user_activities', {
              scheduleId: this.selectedScheduleId,
              userId: this.$store.state.account.userId,
              activities
            });
            if (this.grid) {
              this.redrawGrid();
            }
          }
        }
      };
    },
    getShiftDetailsPanelData (data) {
      const { date, shifts, user } = data;
      const readOnly = this.isDateReadOnly(date);
      return {
        component: ShiftDetails,
        props: {
          'allow-cancel-nurse': false,
          'allow-delete': false,
          date,
          shifts,
          'show-daily-schedule-redirect': this.showDailyScheduleRedirect,
          'read-only': readOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          }
        }
      };
    },
    getShiftRequestDetails (request) {
      return {
        ...request,
        user: this.allRecords[this.allUserRecordMap[request.assigneeId]].user,
        type: 'shift'
      };
    },
    getSwapRequestDetails (request) {
      return {
        ...request,
        sourceUser: this.records[this.userRecordMap[request.sourceUserId]].user,
        targetUser: this.records[this.userRecordMap[request.targetUserId]].user,
        type: 'swap'
      };
    },
    hasValidationErrors (job) {
      let errorCount = 0;

      if (job) {
        errorCount = this.$store.getters['scheduling/getErrorCount'](this.selectedScheduleId, job);
      } else {
        errorCount = this.totalErrorCount;
      }

      return errorCount > 0;
    },
    initGrid () {
      let self = this;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
        font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
      });

      const theme = cheetahGrid.themes.SCHEDULER.extends({
        holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
      });

      this.updateGridHeight();

      const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
      const getValidationStyle = (shift) => {
        const styles = {};
        for (let name in validators) {
          _.merge(styles, validators[name].style(shift));
        }
        if (shift.overtime) {
          styles.color = scssVariables.error;
        }
        return styles;
      };
      const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
      const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
      const sitterBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'sitter', 'bgColor'], null);
      this.$nextTick(() => {
        this.grid = new cheetahGrid.ScheduleGrid({
          parentElement: this.$refs.gridParent,
          onResize: () => {
            this.redrawGrid();
          },
          emptyMessage: this.emptyMessage,
          header: this.headers,
          firstDayOfWeek: 0,
          frozenColCount: this.FROZEN_COLUMN_COUNT,
          language: this.gridLanguage,
          showStaffPerDay: true,
          showWeeklyBalance: false,
          symbols: {
            borderColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'borderColor')) {
                  return validationStyle.borderColor;
                }
              }
              return null;
            },
            bgColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'bgColor')) {
                  return validationStyle.bgColor;
                }
              }
              return null;
            },
            event (value) {
              if (!self.eventTypes[value.typeId]) {
                return {};
              }
              return self.eventTypes[value.typeId].styles.web;
            },
            shift (shift) {
              if (!self.shiftTypes[shift.typeId]) {
                return {};
              }
              let style = _.cloneDeep(self.shiftTypes[shift.typeId].styles).web;
              if (shift.available) {
                style = _.cloneDeep(self.scheduleSymbols.shift.web.available);
              } else {
                const shiftType = self.shiftTypes[shift.typeId];
                let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
                let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
                if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                  let start = startTime.substring(0, 5);
                  let end = endTime.substring(0, 5);
                  if (shift.obligatory) {
                    start += '*';
                    end += '  ';
                  }
                  style.symbolType = 'time';
                  style.symbolValue = { start, end };
                }
              }

              if (shift.departmentId !== self.department.id && self.allDepartments[shift.departmentId]) {
                const departmentName = self.allDepartments[shift.departmentId].name;
                style.symbolType = 'text';
                style.symbolValue = departmentName;
              }

              if (shift.obligatory) {
                if (_.get(style, 'symbolType', '') === 'text') {
                  style.symbolValue += '*';
                }
              }

              const validationStyle = getValidationStyle(shift);
              // Validation background color will be applied to the whole cell not individual shifts.
              delete validationStyle.bgColor;
              let bgColor = null;
              if (shift.sitter) {
                bgColor = sitterBgColor;
              } else if (shift.canceled) {
                bgColor = canceledBgColor;
              } else if (shift.onCall) {
                bgColor = onCallBgColor;
              } else if (!isProductiveShift(shift, self.shiftFlags)) {
                bgColor = canceledBgColor;
              }
              return {
                ...style,
                ...validationStyle,
                bgColor,
                modified: wasShiftModifiedByManagement(shift, self.$store.state),
                differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
              };
            },
            request (value) {
              return _.get(self.$store.state.org, ['settings', 'scheduling', 'symbols', 'request', 'web', 'pendingApprovalOperator'], {
                color: '#FF5378'
              });
            }
          },
          theme
        });

        setTimeout(() => {
          if (this.todaysColumnIndex >= 0) {
            this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
          }
        }, 0);

        const { CLICK_CELL, SCROLL, SELECTED_CELL } = cheetahGrid.ScheduleGrid.EVENT_TYPE;
        this.grid.listen(CLICK_CELL, (cell) => {
          // At this moment we do not allow clicking on the user column therefore ignore the click
          if (cell.col > 0) {
            this.showPanelOnCellSelect = true;
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SELECTED_CELL, (cell) => {
          if (cell.selected) {
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SCROLL, (e) => {
          this.gridState.canScrollLeft = this.grid.canScrollLeft();
          this.gridState.canScrollRight = this.grid.canScrollRight();
          this.gridState.scrollLeft = e.event.target.scrollLeft;
        });

        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        const callbacks = this.gridCallbacks.splice(0, this.gridCallbacks.length);
        for (let i = 0, len = callbacks.length; i < len; i++) {
          callbacks[i]();
        }
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
        this.gridState.canScrollLeft = this.grid.canScrollLeft();
        this.gridState.canScrollRight = this.grid.canScrollRight();

        this.bindShortcuts();
        this.updateMissingWeekendShifts();
      });
    },
    isDateReadOnly (date) {
      return this.scheduleIsReadOnly;
    },
    jobTypeById (id, prop) {
      return this.$store.getters['org/getJobTypeById'](id, prop);
    },
    moment,
    onJobTypeChange (idx) {
      this.selectedJobTypeTab = idx;
      this.filterGrid(true);
    },
    openFiltersPanel () {
      this.showPersistentPanel(() => {
        this.openedPanelName = 'filters';
        return {
          props: {
            eventFilters: _.cloneDeep(_.get(this.filters, 'event', {})),
            shiftFilters: _.cloneDeep(_.get(this.filters, 'shift', {})),
            userFilters: _.cloneDeep(_.get(this.filters, 'user', {}))
          },
          component: Filters,
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            apply: (filters) => {
              this.filters = filters;
              this.refreshPersistentPanels();
            }
          }
        };
      }, [], true);
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPanel();
          }
        } else {
          this.closeLastPanel();
        }
      };
      return panelData;
    },
    preparePersistentPanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPersistentPanel();
          }
        } else {
          this.closeLastPersistentPanel();
        }
      };
      return panelData;
    },
    redraw () {
      this.retrievingSchedule = true;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      this.$nextTick(() => {
        this.staffFilter = '';
        this.selectedJobTypeTab = this.ALL_JOB_TYPES;

        const startOn = this.selectedSchedule.startOn;
        // Try to keep the same schedule period when switching departments
        this.retrieveScheduleByDate(startOn).then((schedule) => {
          // Try to keep the same schedule period when switching departments
          let selectedScheduleId = null;
          if (schedule) {
            selectedScheduleId = schedule.id;
            this.selectedScheduleId = schedule.id;
            this.selectedSchedule = schedule;
          } else {
            selectedScheduleId = this.schedulePeriods[0].value;
            this.selectedScheduleId = this.schedulePeriods[0].value;
            this.selectedSchedule = this.schedulePeriods[0];
          }
          this.$store.commit('scheduling/set_active_schedule_id', selectedScheduleId);
          this.retrieveSchedule();
        });
      });
    },
    redrawGrid (focusGrid) {
      if (this.grid) {
        this.updateMissingWeekendShifts();
        this.updateGridHeight();
        // After updating the grid height we need to wait for vue to finished re-evaluating
        // any grid height dependencies so that cheetah-grid can calculate the correct width
        // and height for the grid.
        this.$nextTick(() => {
          this.grid.resize();
          if (focusGrid) {
            this.focusGrid();
          }
          setTimeout(() => {
            if (this.todaysColumnIndex >= 0) {
              this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
            }
          }, 0);
        });
      }
    },
    refreshPanels (count) {
      if (!this.selfScheduleEnabled) {
        const len = count || this.panels.length;
        for (let i = 0; i < len; i++) {
          this.updatePanel(i, this.panels[i].panelDataCallback, this.panels[i].params);
        }
      }
    },
    refreshPersistentPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.persistentPanels.length;
        for (let i = 0; i < len; i++) {
          this.updatePersistentPanel(i, this.persistentPanels[i].panelDataCallback, this.persistentPanels[i].params);
        }
      }
    },
    renderDownloadGrid () {
      return new Promise((resolve) => {
        if (this.download.grid) {
          this.download.grid.dispose();
          this.download.grid = null;
        }
        let self = this;
        cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
          font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
        });

        const theme = cheetahGrid.themes.SCHEDULER.extends({
          borderColor: '#BDBDBD',
          holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
        });

        const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
        const getValidationStyle = (shift) => {
          const styles = {};
          for (let name in validators) {
            _.merge(styles, validators[name].style(shift));
          }
          if (shift.overtime) {
            styles.color = scssVariables.error;
          }
          return styles;
        };
        const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
        const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
        const sitterBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'sitter', 'bgColor'], null);
        this.$nextTick(() => {
          this.download.grid = new cheetahGrid.ScheduleGrid({
            parentElement: this.$refs.downloadGrid,
            emptyMessage: this.emptyMessage,
            header: this.printHeaders,
            firstDayOfWeek: 0,
            frozenColCount: this.FROZEN_COLUMN_COUNT,
            language: this.gridLanguage,
            showStaffPerDay: true,
            showWeeklyBalance: false,
            symbols: {
              borderColor (cell) {
                const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
                for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                  const validationStyle = getValidationStyle(shifts[i]);
                  if (_.has(validationStyle, 'borderColor')) {
                    return validationStyle.borderColor;
                  }
                }
                return null;
              },
              bgColor (cell) {
                const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
                for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                  const validationStyle = getValidationStyle(shifts[i]);
                  if (_.has(validationStyle, 'bgColor')) {
                    return validationStyle.bgColor;
                  }
                }
                return null;
              },
              event (value) {
                return self.eventTypes[value.typeId].styles.web;
              },
              shift (shift) {
                if (!self.shiftTypes[shift.typeId]) {
                  return {};
                }
                let style = _.cloneDeep(self.shiftTypes[shift.typeId].styles).web;
                if (shift.available) {
                  style = _.cloneDeep(self.scheduleSymbols.shift.web.available);
                } else {
                  const shiftType = self.shiftTypes[shift.typeId];
                  let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
                  let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
                  if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                    let start = startTime.substring(0, 5);
                    let end = endTime.substring(0, 5);
                    if (shift.obligatory) {
                      start += '*';
                      end += '  ';
                    }
                    style.symbolType = 'time';
                    style.symbolValue = { start, end };
                  }
                }

                if (shift.departmentId !== self.department.id && self.allDepartments[shift.departmentId]) {
                  const departmentName = self.allDepartments[shift.departmentId].name;
                  style.symbolType = 'text';
                  style.symbolValue = departmentName;
                }

                if (shift.obligatory) {
                  if (_.get(style, 'symbolType', '') === 'text') {
                    style.symbolValue += '*';
                  }
                }

                const validationStyle = getValidationStyle(shift);
                // Validation background color will be applied to the whole cell not individual shifts.
                delete validationStyle.bgColor;
                let bgColor = null;
                if (shift.sitter) {
                  bgColor = sitterBgColor;
                } else if (shift.canceled) {
                  bgColor = canceledBgColor;
                } else if (shift.onCall) {
                  bgColor = onCallBgColor;
                }

                return {
                  ...style,
                  ...validationStyle,
                  bgColor,
                  modified: wasShiftModifiedByManagement(shift, self.$store.state),
                  differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                  strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
                };
              },
              request (value) {
                return _.get(self.$store.state.org, ['settings', 'scheduling', 'symbols', 'request', 'web', 'pendingApprovalOperator'], {
                  color: '#FF5378'
                });
              },
              week (user, week) {
                let style = {};
                if (validators['OvertimeValidator']) {
                  style = validators['OvertimeValidator'].weekStyle(user, week);
                }
                return {
                  bgColor: '#BDBDBD',
                  ...style
                };
              }
            },
            theme
          });

          const filterSource = new cheetahGrid.data.FilterDataSource({
            get (index) {
              return self.paginatedPrintRecords[index] || {};
            },
            length: self.paginatedPrintRecords.length
          });
          filterSource.filter = null;
          this.download.grid.dataSource = filterSource;
          resolve();
        });
      });
    },
    retrieveSchedule () {
      if (!this.hideSchedule) {
        this.retrievingSchedule = true;
        const criteria = {
          scheduleId: this.selectedScheduleId,
          scheduleStartDate: this.selectedSchedule.startOn,
          scheduleEndDate: this.selectedSchedule.endOn,
          deptId: this.department.id
        };
        if (this.grid) {
          this.grid.dispose();
          this.grid = null;
        }
        const action = this.selectedScheduleId ? 'scheduling/retrieveSchedule' : 'scheduling/retrievePseudoSchedule';
        this.dispatch(action, criteria).then((scheduleId) => {
          // DO NOT move the code below to 'finally' block like other places.
          // We need to immediately hide the skeleton loader upon receiving response
          // from the web API and render the parent container for the grid. Otherwise
          // the grid won't get initialized.
          this.retrievingSchedule = false;
          // Wait for the next tick to ensure the skeleton loader is hidden and the
          // parent container for the grid is rendered.
          this.$nextTick(() => {
            this.initGrid();
          });
          this.$store.commit('scheduling/set_active_schedule_id', this.selectedScheduleId);
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleRetrievalFail'),
            type: 'error',
            data
          });
        });
      }
    },
    setOpenedPanelName (name) {
      this.openedPanelName = name;
    },
    setSelectedSchedule (schedule) {
      this.retrievingSchedule = true;
      this.showSchedulePicker = false;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      this.$nextTick(() => {
        this.selectedScheduleId = schedule.id;
        this.selectedSchedule = schedule;
        this.$store.commit('scheduling/set_active_schedule_id', schedule.id);
        this.closeAllPanels();
        this.retrieveSchedule();
      });
    },
    showCellDetails (cell) {
      if (cell.col > 0) {
        if (cell.row > 0) {
          const value = this.getCellValue(cell);
          const activities = _.get(value, ['activities'], []);
          const exclude = _.get(value, ['exclude'], false);
          this.selectedCell.date = this.getCellDate(cell);
          this.selectedCell.user = this.getCellUser(cell).userId;
          if (this.showPanelOnCellSelect) {
            if (this.isDateReadOnly(this.selectedCell.date) || exclude) {
              if (activities.length > 0) {
                this.showActivityDetails({ cell }, true);
              } else {
                this.showEmptyPanel(this.selectedCell.date);
              }
            } else {
              this.showActivityDetails({ cell }, true);
            }
          }
        } else if (cell.row === 0) {
          this.selectedCell.date = this.getCellDate(cell);
          this.selectedCell.user = null;
          if (this.showPanelOnCellSelect) {
            this.showDailySummary(this.getCellDate(cell), true);
          }
        }
      } else {
        this.selectedCell.date = null;
        this.selectedCell.user = null;
        if (this.showPanelOnCellSelect) {
          this.showSelectCellPanel();
        }
      }
      this.selfScheduleEnabled = false;
    },
    showActivityDetails (schedule, resetPanels) {
      this.showPanel(
        this.getActivityDetailsPanelData,
        [schedule],
        resetPanels
      );
    },
    showSelfSchedulePanel () {
      this.selectedCell.date = null;
      this.selectedCell.user = null;
      this.showPanelOnCellSelect = false;
      this.showPanel(
        this.getSelfSchedulePanelData,
        [],
        true
      );
    },
    showShiftDetails (data, resetPanels) {
      this.showPanel(
        this.getShiftDetailsPanelData,
        [data],
        resetPanels
      );
    },
    showEventDetails (data, resetPanels) {
      this.showPanel(
        this.getEventDetailsPanelData,
        [data],
        resetPanels
      );
    },
    showDailySummary (date, resetPanels) {
      this.showPanel(this.getDailySummaryPanelData, [date], resetPanels);
    },
    showEmptyPanel (date) {
      this.showPanel(() => {
        return {
          component: EmptyCell,
          props: {
            date
          },
          events: {}
        };
      }, [], true);
    },
    showSelectCellPanel () {
      this.showPanel(() => {
        return {
          component: SelectCell,
          props: {},
          events: {}
        };
      }, [], true);
    },
    showPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.panels.length > 0) {
        this.closePanels(1, this.panels.length);
        this.updatePanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.push(_.cloneDeep(panelData));
      }
    },
    showPersistentPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.persistentPanels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePersistentPanels(1, this.persistentPanels.length);
        this.updatePersistentPanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePersistentPanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.persistentPanels.push(_.cloneDeep(panelData));
      }
    },
    toggleHelpPanel () {
      this.showHelp = !this.showHelp;
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    updatePersistentPanel (panelIdx, panelDataCallback, params) {
      const panel = this.persistentPanels[panelIdx];
      const panelData = this.preparePersistentPanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.persistentPanels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    unbindShortcuts () {
      this.mousetrap.reset();
    },
    updateGrid () {
      if (this.grid) {
        this.grid.header = this.headers;
      }
    },
    updateGridHeight () {
      const grid = document.getElementsByClassName('grid-parent')[0];
      if (grid) {
        const top = grid.getBoundingClientRect().top;
        this.gridTop = top;
        this.gridHeight = window.innerHeight - top;
        this.gridWidth = grid.offsetWidth;
      }
    },
    updateGridEmptyMessage () {
      if (this.grid) {
        this.grid.emptyMessage = this.emptyMessage;
      }
    },
    updateMissingWeekendShifts () {
      const jobStatus = this.jobStatus[this.$store.state.account.profile.jobStatusId];
      const rules = _.filter(_.get(jobStatus, 'minShiftSettings.countRules.weekend', []), (r) => {
        return !!r.count;
      });
      const minimumCount = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId, 'MinimumCountValidator');
      let count = 0;
      if (rules.length > 0 && rules[0].count && minimumCount) {
        const errors = _.get(minimumCount.state, ['data', this.$store.state.account.userId, 'errors', 'weekend'], {});
        if (!_.isEmpty(errors) && errors.assigned < errors.min) {
          count = rules[0].count;
        }
      }
      this.missingWeekendShifts = count;
    },
    updatePageTitleWidth () {
      const header = document.getElementsByClassName('schedule-header')[0];
      if (header) {
        const cols = header.getElementsByClassName('col');
        const PADDING = this.$vuetify.breakpoint.smAndDown ? 10 : 45;
        const DEFAULT_WIDTH = 115;
        // Start at index 1 to ignore the title column.
        let width = header.offsetWidth;
        for (let i = 1, len = cols.length; i < len; i++) {
          width -= cols[i].offsetWidth ? cols[i].offsetWidth : DEFAULT_WIDTH;
        }
        this.pageTitleWidth = width - PADDING;
      }
    },
    retrieveScheduleByDate (date) {
      return new Promise((resolve, reject) => {
        const schedule = _.find(this.schedulePeriods, (schedule) => date >= schedule.startOn && date <= schedule.endOn);
        if (schedule) {
          resolve(schedule);
        } else {
          this.dispatch('scheduling/retrieveScheduleByDate', {
            departmentId: this.department.id,
            date
          }).then((schedule) => {
            if (schedule) {
              resolve(schedule);
            } else {
              resolve(calculateScheduleRangeForDate(date, this.schedulePeriods, this.$store.state.org.settings));
            }
          }).catch(error => {
            reject(error);
          });
        }
      });
    },
    retrieveScheduleById (scheduleId) {
      return this.dispatch('scheduling/retrieveScheduleById', scheduleId);
    }
  }
};
</script>

<style lang="scss">
.container.schedule {
  height: 100%;

  .cell-details {
    transition: width 0.3s;
  }

  .v-tabs {
    .v-tabs-bar {
      border-color: #eeeeee;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      margin-right: 10px;
    }
  }

  .grid-action-bar {
    background-color: white;
    border-color: map-get($grey, 'lighten-3');
    border-style: solid;
    border-width: 1px 1px 0 1px;
    margin-left: 0px;
    margin-right: 10px;

    .v-slide-group__content {
      > .v-btn.v-btn:not(:last-child ) {
        border-right: none !important;
      }

      > .v-btn.v-btn:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        &.v-slide-item--active {
          &::before {
            border-top-left-radius: 2px !important;
            border-bottom-left-radius: 2px !important;
          }
        }
      }

      > .v-btn.v-btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &.v-slide-item--active {
          &::before {
            border-top-right-radius: 2px !important;
            border-bottom-right-radius: 2px !important;
          }
        }
      }

      > .v-btn.v-btn {
        border-radius: 0;
        border-style: solid;
        border-width: thin;
        -webkit-box-shadow: none;
        box-shadow: none;
        box-shadow: none;
        opacity: 0.8;
        padding: 0 12px;
      }

      .v-slide-item--active {
        &::before {
          opacity: 1;
        }

        .v-btn__content {
          color: white;
        }
      }
    }
  }

  .schedule-dropdown {
      border: 1px solid map-get($grey, 'lighten-2');
      .v-btn__content {
        justify-content: left;
        i {
          position: absolute;
          right: 8px;
        }
      }
    }

  .grid-empty-container {
    text-align: left;
  }

  .search-staff {
    position: absolute;
    z-index: 1;

    .v-icon {
      margin-top: 0px !important;
    }
  }
  .shift-type {
    padding: 0px 5px !important;
    min-width: 34px !important;
    width: 34px !important;
  }
}

.weekend-shifts-details {
  background-color: $info !important;
}

</style>
