<template>
  <v-container
    class="schedule"
    fluid
  >
    <template v-if="noSnapshots">
      <v-row>
        <v-col cols="auto">
          <v-menu
            v-model="showSchedulePicker"
            min-width="250px"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, value }">
              <v-btn
                class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                color="primary"
                elevation="0"
                outlined
                width="250px"
                v-on="on"
              >
                <span class="body-2 font-weight-medium">
                  {{ selectedScheduleText }}
                </span>
                <v-icon
                  size="20"
                >
                  {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                </v-icon>
              </v-btn>
            </template>
            <ScheduleSelection
              :department="department"
              @select="setSelectedSchedule"
              @close="showSchedulePicker = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-container
        class="schedule"
        fluid
        fill-height
      >
        <portal to="page-title">
          <v-container class="schedule-header py-0 mx-0">
            <v-row justify="space-between">
              <v-col
                align-self="center"
                class="pl-0"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector
                    :width="`${pageTitleWidth}px`"
                  />
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </portal>
        <v-row
          justify="center"
          style="margin-top: -150px"
        >
          <v-col
            md="6"
            sm="12"
          >
            <div class="mt-10 mx-3 pa-6">
              <v-row justify="center">
                <v-img
                  contain
                  max-width="50%"
                  src="@/assets/images/vacation-penguin.svg"
                />
              </v-row>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="darken-2 grey--text mt-5 px-5 text--darken-3 text-center "
                v-html="$t('descriptions.noSnapshots')"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="retrievingSchedule && !gridInitialized && showSkeletonLoader">
      <v-container
        class="schedule"
        fluid
      >
        <v-row dense>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col
              v-for="n in 2"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="n in 5"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              class="no-action"
              type="table"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="!retrievingSchedule">
      <v-container
        class="schedule"
        fluid
        :style="gridMaxWidth"
      >
        <portal to="page-title">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-container class="schedule-header py-0">
              <v-row justify="space-between">
                <v-col
                  align-self="center"
                  class="pl-0"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <DepartmentSelector
                      :width="`${pageTitleWidth}px`"
                    />
                  </v-row>
                </v-col>
                <v-spacer />
                <v-col
                  align-self="center"
                  cols="auto"
                >
                  <v-chip
                    :class="['font-weight-medium white--text', scheduleStateIndicator.stateLabelCssClass]"
                    small
                  >
                    {{ $t(scheduleStateIndicator.stateLabelKey) }}
                  </v-chip>
                  <span class="caption font-weight-medium ml-3 grey--text text--darken-1">
                    {{ $t('labels.readOnlySnapshot') }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-row
              class="schedule-header"
              justify="space-between"
            >
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector
                    :width="`${pageTitleWidth}px`"
                  />
                </v-row>
              </v-col>
              <v-spacer />
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <v-chip
                    :class="['font-weight-medium ml-5 white--text', scheduleStateIndicator.stateLabelCssClass]"
                    small
                  >
                    {{ $t(scheduleStateIndicator.stateLabelKey) }}
                  </v-chip>
                  <span class="caption font-weight-medium ml-3 grey--text text--darken-1">
                    {{ $t('labels.readOnlySnapshot') }}
                  </span>
                </v-row>
              </v-col>
              <v-spacer />
              <template v-if="!$vuetify.breakpoint.smAndDown">
                <v-col
                  align-self="center"
                  class="pr-0"
                  cols="auto"
                >
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="mx-1"
                        icon
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': toggleHelpPanel}"
                      >
                        <v-icon color="primary">
                          fal fa-info-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.information') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-row>
          </template>
        </portal>
        <portal
          v-if="$vuetify.breakpoint.smAndDown"
          to="page-menu"
        >
          <v-list-item
            @click="toggleHelpPanel"
          >
            <v-list-item-action>
              <v-icon small>
                fal fa-info-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('labels.information') }}
            </v-list-item-title>
          </v-list-item>
        </portal>
        <v-row
          justify="end"
          no-gutters
        >
          <v-col cols="12">
            <v-tabs
              v-model="selectedJobTypeTab"
              mobile-breakpoint="768"
              show-arrows
              slider-color="accent"
              slider-size="3"
            >
              <v-tab
                v-for="(jobTypeGroup, index) in groupedJobTypes"
                :key="index"
                @click.native.prevent.stop.capture="onJobTypeChange(index)"
              >
                <template
                  v-for="(jobType, jobIndex) in jobTypeGroup"
                >
                  <span
                    v-if="jobIndex > 0"
                    :key="`${jobType.id}-plus`"
                    class="ml-2"
                  >
                    +
                  </span>
                  <span
                    :key="`${jobType.id}-name`"
                    :class="jobIndex > 0 ? 'ml-2' : ''"
                  >
                    {{ jobType.name }}
                  </span>
                  <template v-if="index > 0">
                    <v-icon
                      v-if="hasValidationErrors(jobType.id)"
                      :key="`${jobType.id}-validation`"
                      class="error--text ml-1 mr-1"
                      x-small
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      v-if="hasValidationErrors()"
                      :key="`${jobType.id}-validation`"
                      class="error--text ml-1 mr-1"
                      x-small
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                </template>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          class="grid-action-bar"
          justify="center"
          justify-md="end"
        >
          <v-col cols="auto">
            <v-menu
              v-model="showSchedulePicker"
              min-width="250px"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, value }">
                <v-btn
                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                  color="primary"
                  elevation="0"
                  outlined
                  width="250px"
                  v-on="on"
                >
                  <span class="body-2 font-weight-medium">
                    {{ selectedScheduleText }}
                  </span>
                  <v-icon
                    size="20"
                  >
                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <ScheduleSelection
                :department="department"
                @select="setSelectedSchedule"
                @close="showSchedulePicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col
            class="pr-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.filters.button.classes"
                  icon
                  value="filters"
                  v-bind="attrs"
                  v-on="on"
                  @click="setOpenedPanelName('filters')"
                >
                  <v-icon
                    v-if="hasFilters && openedPanelName !== 'filters'"
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fas fa-filter
                  </v-icon>
                  <v-icon
                    v-else
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fal fa-filter
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.filter') }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-btn
              :class="actionStyles.history.button.classes"
              icon
              @click="setOpenedPanelName(SNAPSHOT_HISTORY)"
            >
              <v-icon
                :class="actionStyles.history.icon.classes"
                size="16"
              >
                fal fa-history
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <StaffSearch
          v-model.trim="staffFilter"
          :append-icon="staffFilter ? '' : 'fal fa-search'"
          :clearable="!!staffFilter"
          dense
          hide-details
          nudge-right="100"
          solo
          target-class="search-staff py-3 ml-4"
          :target-style="searchStaffStyle"
        />
        <div
          ref="gridParent"
          class="grid-parent"
          :style="gridStyle"
        />
        <svg
          v-if="scheduleIsReadOnly"
          :style="gridOverlayStyle"
        >
          <polygon
            :points="gridOverlayPath"
            style="fill-opacity:0.05;stroke:transparent;stroke-width:0;"
          />
        </svg>
      </v-container>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
          :class="['elevation-0 schedule-summary-row-toggle', { active: mobileSummaryRow }]"
          fixed
          :ripple="false"
          small
          :style="{bottom: mobileSummaryRow ? `${footerHeight + 54}px` : '54px'}"
          @click="mobileSummaryRow = !mobileSummaryRow"
        >
          <v-icon
            color="error"
            x-small
          >
            {{ mobileSummaryRow ? 'far fa-chevron-down' : 'far fa-chevron-up' }}
          </v-icon>
        </v-btn>
        <v-footer
          class="elevation-0 schedule-summary-row pb-0"
          fixed
          :style="{bottom: mobileSummaryRow ? '56px' : `${56 - footerHeight}px`}"
        >
          <v-btn
            v-for="validator in validators"
            :key="`tab${getValidatorName(validator)}`"
            class="mx-2 mb-2 caption font-weight-medium text-capitalize"
            depressed
            @click="showErrorsOverview(validator)"
          >
            <v-chip
              class="mr-2 px-2 white--text"
              :color="getValidatorErrorCount(validator) > 0 ? 'error' : 'primary lighten-2'"
              label
              x-small
              @click.stop="showErrorsOverview(validator)"
            >
              {{ getValidatorErrorCount(validator) }}
            </v-chip>
            {{ $t(`labels.${getValidatorName(validator)}`) }}
            <v-icon
              class="pl-2 grey--text text--darken-1"
              :style="{ visibility: hasTabFilters ? 'visible' : 'hidden' }"
              x-small
            >
              fas fa-filter
            </v-icon>
          </v-btn>
        </v-footer>
      </template>
      <v-footer
        v-else
        fixed
        class="schedule-summary-row pr-8 pb-0"
        :style="summaryRowStyle"
      >
        <v-btn
          v-for="validator in validators"
          :key="`tab${getValidatorName(validator)}`"
          class="mx-2 caption font-weight-medium text-capitalize mb-2"
          depressed
          @click="showErrorsOverview(validator)"
        >
          <v-chip
            class="mr-2 px-2 white--text"
            :color="getValidatorErrorCount(validator) > 0 ? 'error' : 'primary lighten-2'"
            label
            x-small
            @click.stop="showErrorsOverview(validator)"
          >
            {{ getValidatorErrorCount(validator) }}
          </v-chip>
          {{ $t(`labels.${getValidatorName(validator)}`) }}
          <v-icon
            class="pl-2 grey--text text--darken-1"
            :style="{ visibility: hasTabFilters ? 'visible' : 'hidden' }"
            x-small
          >
            fas fa-filter
          </v-icon>
        </v-btn>
        <v-spacer />
      </v-footer>
      <SidePanel
        :panels="sidePanels"
        @transitionend="redrawGrid(true)"
      />
      <UserDialog
        v-if="nurseDetails"
        :show-hint="false"
        :user="{ ...nurseDetails }"
        @close="hideNurseDetails"
        @saved="updateNurseDetails"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE_TABLE, SEARCH_INPUT_DEBOUNCE } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import Mousetrap from '@/plugins/mousetrap';
import DailySummary from '@/views/scheduling/panels/DailySummary';
import ActivityDetails from '@/views/scheduling/panels/ActivityDetails';
import ShiftDetails from '@/views/scheduling/panels/ShiftDetails';
import EventDetails from '@/views/scheduling/panels/EventDetails';
import NurseDetails from '@/views/scheduling/panels/NurseDetails';
import SelectCell from '@/views/scheduling/panels/SelectCell';
import EmptyCell from '@/views/scheduling/panels/EmptyCell';
import Help from '@/views/scheduling/panels/Help';
import ErrorsOverview from '@/views/scheduling/panels/ErrorsOverview';
import SnapshotHistory from '@/views/scheduling/panels/snapshot/History';
import WeeklySummary from '@/views/scheduling/panels/WeeklySummary';
import UserWeeklySummary from '@/views/scheduling/panels/UserWeeklySummary';
import Filters from '@/views/scheduling/panels/Filters';
import SidePanel from '@/components/SidePanel';
import DepartmentSelector from '@/components/DepartmentSelector';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import StaffSearch from '@/components/StaffSearch';
import UserDialog from '@/views/admin/users/UserDialog';
import { getDefaultFilters, isProductiveShift, isWorkingShiftForDisplay, shiftMatchesFilters, wasShiftModifiedByManagement } from '@/utils/scheduling';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import scssVariables from '@/assets/sass/_variables.scss';
import { userMatchesText } from '@/utils/org';

const DeepDiff = require('deep-diff').diff;
const cheetahGrid = require('@nurse-brite/cheetah-grid');
const SNAPSHOT_HISTORY = 'history';

export default {
  components: {
    DepartmentSelector,
    ScheduleSelection,
    SidePanel,
    StaffSearch,
    UserDialog
  },

  props: {
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$store.getters['account/canAccessScheduleSnapshots']()) {
        next('/page_not_found');
      }
    });
  },

  data () {
    const ALL_SHIFT_TYPES = 0;
    const ALL_JOB_TYPES = 0;

    return {
      ALL_JOB_TYPES,
      ALL_SHIFT_TYPES,
      COL_WIDTH_SCHEDULE: 40,
      COL_WIDTH_USER: 200,
      FROZEN_COLUMN_COUNT: 1,
      SIDE_PANEL_WIDTH: 440,
      SNAPSHOT_HISTORY,
      filters: getDefaultFilters(),
      footerHeight: 55,
      grid: null,
      gridHeight: 500,
      gridTop: 0,
      gridWidth: 500,
      gridState: {
        canScrollLeft: false,
        canScrollRight: false
      },
      gridfilterDataSource: null,
      helpPanel: [],
      helpPanelData: {
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      },
      showHelp: false,
      mobileSummaryRow: false,
      mousetrap: new Mousetrap(this.$el),
      nurseDetails: null,
      noSnapshots: false,
      openedPanelName: '',
      pageTitleWidth: 100,
      panels: [], // Opened right side panels
      persistentPanels: [],
      retrievingSchedule: true, // Flag for paginating through different time period of the schedule.
      selectedCell: {
        col: null,
        user: null
      },
      selectedJobTypeTab: ALL_JOB_TYPES,
      selectedSchedule: this.$store.state.scheduling.activeSnapshotPeriod || {},
      selectedScheduleSnapshots: [],
      showPanelOnCellSelect: false,
      showSchedulePicker: false,
      staffFilter: '',
      viewWeeklyBalance: false
    };
  },

  computed: {
    actionStyles () {
      const defaultButtonClasses = ['grey', 'lighten-2', 'mr-2'];
      const defaultIconClasses = ['grey--text', 'text--darken-3'];
      const styles = {
        filters: {
          button: {
            classes: defaultButtonClasses.concat(['filters'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        history: {
          button: {
            classes: defaultButtonClasses.concat(['history'])
          },
          icon: {
            classes: defaultIconClasses
          }
        }
      };

      if (this.hasFilters) {
        styles.filters = {
          button: {
            classes: ['primary', 'lighten-2', 'mr-2', 'filters']
          },
          icon: {
            classes: ['white--text']
          }
        };
      }

      if (this.openedPanelName) {
        styles[this.openedPanelName].button.classes = ['primary', 'mr-2', this.openedPanelName];
        styles[this.openedPanelName].icon.classes = ['white--text'];
      }

      return styles;
    },
    allDepartments () {
      return this.$store.state.org.departments.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    emptyMessage () {
      let message = '';
      const containerClasses = 'body-1 font-weight-medium grey--text text--darken-3 pa-5';
      if (this.staffFilter) {
        message = `
          <div class="${containerClasses}">
            ${this.$t('descriptions.emptyGridStaffFilter', { staff_filter: _.escape(this.staffFilter) })}
            <hr role="separator" aria-orientation="horizontal" class="my-5 v-divider theme--light">
            <div class="mb-5">
              ${this.$t('descriptions.emptyGridHelpTitle')}
            </div>
            <ul>
              <li>${this.$t('descriptions.emptyGridHelpRemoveFilters')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShiftTypes')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShorterWords')}</li>
              <li>${this.$t('descriptions.emptyGridHelpSpelling')}</li>
            </ul>
          </div>
        `;
      } else {
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
          const descriptions = this.getSelectedJobTypes().map((jt) => jt.description.toLowerCase()).join('/');
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridSpecificJobType', { job_type: _.escape(descriptions) })}
            </div>
          `;
        } else {
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridAllJobTypes')}
            </div>
          `;
        }
      }

      return message;
    },
    eventTypes () {
      return this.$store.state.org.eventTypes.reduce(function (eventTypes, eventType) {
        eventTypes[eventType.id] = eventType;
        return eventTypes;
      }, {});
    },
    gridLanguage () {
      return {
        'clear': this.$t('labels.clear'),
        'daysOfWeek': [
          this.$t('dates.dayOfWeek.0'),
          this.$t('dates.dayOfWeek.1'),
          this.$t('dates.dayOfWeek.2'),
          this.$t('dates.dayOfWeek.3'),
          this.$t('dates.dayOfWeek.4'),
          this.$t('dates.dayOfWeek.5'),
          this.$t('dates.dayOfWeek.6')
        ],
        'months': [
          this.$t('dates.month.0'),
          this.$t('dates.month.1'),
          this.$t('dates.month.2'),
          this.$t('dates.month.3'),
          this.$t('dates.month.4'),
          this.$t('dates.month.5'),
          this.$t('dates.month.6'),
          this.$t('dates.month.7'),
          this.$t('dates.month.8'),
          this.$t('dates.month.9'),
          this.$t('dates.month.10'),
          this.$t('dates.month.11')
        ],
        'disclaimerHint': this.$t('descriptions.disclaimer'),
        'visibilityHint': this.$t('descriptions.commentVisibilitySchedulers'),
        'notes': this.$t('labels.notes'),
        'notesPlaceholder': `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.userNotesPlaceholder')}`,
        'save': this.$t('labels.save'),
        'viewStaffDetails': this.$t('labels.viewStaffDetails'),
        'week': this.$t('labels.week')
      };
    },
    hasFilters () {
      const diff = DeepDiff(getDefaultFilters(), this.filters) || [];
      return diff.length > 0;
    },
    hasActivityFilters () {
      const activityFilters = _.cloneDeep(this.filters);
      delete activityFilters.user;
      const defaultFilters = getDefaultFilters();
      delete defaultFilters.user;
      const diff = DeepDiff(defaultFilters, activityFilters) || [];
      return diff.length > 0;
    },
    groupedJobTypes () {
      const groupedJobTypes = [];
      const jobTypes = this.jobTypes;
      let group = [jobTypes[0]];
      for (let i = 1, count = jobTypes.length; i < count; i++) {
        if (group[group.length - 1].groupRight) {
          group.push(jobTypes[i]);
        } else {
          groupedJobTypes.push([...group]);
          group = [jobTypes[i]];
        }
      }
      groupedJobTypes.push(group);
      return groupedJobTypes;
    },
    hasTabFilters () {
      return this.selectedJobTypeTab !== this.ALL_JOB_TYPES;
    },
    jobTypes () {
      let jobTypes = [
        {
          description: 'All Job Types',
          id: 0,
          groupRight: false,
          name: 'All',
          associatedShiftTypes: [],
          associatedJobTypes: []
        }
      ];
      if (this.selectedSchedule.id) {
        const scheduleJobTypes = this.$store.getters['scheduling/getJobTypes'](this.selectedSchedule.id);
        for (let i = 0, count = scheduleJobTypes.length; i < count; i++) {
          const jobInfo = scheduleJobTypes[i];
          jobTypes.push({
            ...jobInfo
          });
          jobTypes[0].associatedShiftTypes.push(...jobInfo.associatedShiftTypes);
          jobTypes[0].associatedJobTypes.push(...jobInfo.associatedJobTypes);
        }

        jobTypes[0].associatedShiftTypes = _.uniq(jobTypes[0].associatedShiftTypes);
        jobTypes[0].associatedJobTypes = _.uniq(jobTypes[0].associatedJobTypes);
      }
      return jobTypes;
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getActiveDepartment']() || {};
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    scheduleSymbols () {
      return this.$store.state.org.settings.scheduling.symbols;
    },
    searchStaffStyle () {
      return {
        width: `${this.COL_WIDTH_USER * 0.85}px`
      };
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(function (shiftTypes, shiftType) {
        shiftTypes[shiftType.id] = { ...shiftType };
        // Filter out empty properties from the styles object that have empty values to allow code to easily
        // override styles for validation errors.
        shiftTypes[shiftType.id].styles = _.pickBy(shiftTypes[shiftType.id].styles, _.identity);
        return shiftTypes;
      }, {});
    },
    shiftTypesForSelectedJobType () {
      const jobTypes = this.getSelectedJobTypes();
      const shiftTypes = [];
      for (let jt of jobTypes) {
        shiftTypes.push(...jt.associatedShiftTypes);
      }
      return _.map(_.uniq(shiftTypes), (id) => {
        return this.shiftTypes[id];
      });
    },
    shiftTypesForSelectedJobTypeWithoutOnCall () {
      return _.filter(this.shiftTypesForSelectedJobType, (shiftType) => !shiftType.onCall);
    },
    gridOverlayStyle () {
      return {
        height: this.gridStyle.height,
        'pointer-events': 'none',
        position: 'absolute',
        top: `${this.gridTop - 48}px`,
        width: `${this.gridWidth}px`
      };
    },
    gridOverlayPath () {
      const USER_COLUMN_WIDTH = 200;
      const HEADER_HEIGHT = 60;
      // Subtract 10 for parent padding
      const WIDTH = parseInt(this.gridOverlayStyle.width) - 10;
      const HEIGHT = parseInt(this.gridOverlayStyle.height) - 10;
      let path = [
        `${USER_COLUMN_WIDTH},0`,
        `${WIDTH},0`,
        `${WIDTH},${HEIGHT}`,
        `0,${HEIGHT}`,
        `0,${HEADER_HEIGHT}`,
        `${USER_COLUMN_WIDTH},${HEADER_HEIGHT}`
      ];

      return path.join(' ');
    },
    gridStyle () {
      // The grid has two parent containers and each one has 12px padding and here
      // we add an extra 6px buffer.
      let padding = 0;
      if (this.$vuetify.breakpoint.smAndDown) {
        // smAndDown triggers the navigation bar to the bottom of the screen therefore
        // the offset here is the height of that navigation bar.
        padding += 60;
        if (this.mobileSummaryRow) {
          padding += 48;
        }
      } else {
        padding += 50;
      }

      return {
        height: `${this.gridHeight - padding - (this.footerHeight - 55)}px`,
        opacity: 1.0
      };
    },
    gridInitialized () {
      return !!this.grid;
    },
    gridMaxWidth () {
      const MARGIN = 40; // Compensate left and right margin of the grid.
      const widthForScheduleCols = this.department.numOfWeeksPerSchedule * 7 * this.COL_WIDTH_SCHEDULE; // Hospital opens 7 days a week.
      const maxWidthForGrid = this.COL_WIDTH_USER + widthForScheduleCols + MARGIN;

      return {
        maxWidth: maxWidthForGrid + 'px'
      };
    },
    hasSelectedShiftTypes () {
      return this.selectedShiftTypes.length > 0;
    },
    selectedShiftTypes () {
      return _.get(this.filters, 'shift.types', []);
    },
    scheduleIsReadOnly () {
      return true;
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    sidePanels () {
      const helpPanel = [{
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      }];

      if (this.showHelp) {
        return helpPanel;
      } else {
        return [...this.persistentPanels, ...this.panels];
      }
    },
    schedule () {
      return this.$store.state.scheduling.schedules[this.selectedSchedule.id];
    },
    summaryRowStyle () {
      return {
        'left': this.$vuetify.breakpoint.smAndDown ? '0px' : '100px'
      };
    },
    headers () {
      const headers = _.cloneDeep(_.get(this.$store.state.scheduling.grids[this.selectedSchedule.id], ['headers'], []));
      const filteredHeaders = [];
      for (let i = 0, len = headers.length; i < len; i++) {
        if (headers[i].type === 'user') {
          filteredHeaders.push({
            columnType: 'user',
            headerType: 'user',
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            renderNameAsLink: true,
            showSettings: true,
            status: (user) => {
              let status = user.jobTypeName ? user.jobTypeName : '';
              status += user.jobStatusShortCode ? ` ${user.jobStatusShortCode}` : '';
              if (user.departmentId !== this.department.id) {
                status += ` │ ${user.departmentName}`;
              }
              return status;
            },
            ...headers[i]
          });
        } else if (headers[i].type === 'schedule') {
          filteredHeaders.push({
            columnType: 'schedule',
            headerType: 'schedule',
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            ...headers[i]
          });
        } else if (headers[i].type === 'week') {
          filteredHeaders.push({
            columnType: 'week',
            headerType: 'week',
            maxWidth: 40,
            minWidth: 40,
            style: {
              bgColor: '#F5F5F5'
            },
            width: 40,
            ...headers[i]
          });
        }
      }
      return filteredHeaders;
    },
    footers () {
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        return [];
      } else {
        const imbalance = this.$store.getters['scheduling/getValidator'](this.selectedSchedule.id, 'ImbalanceValidator');
        const style = {
          bgColor: '#F5F5F5',
          color: '#616161',
          errorBgColor: '#FDE3E3',
          errorColor: '#E74C3C'
        };
        const hasError = (jobId, shiftId) => {
          return function (count) {
            return !imbalance.validShiftCount(jobId, shiftId, count);
          };
        };
        const data = imbalance.state.data;
        const footers = [];

        const jobTypes = this.getSelectedJobTypes();
        if (!this.hasSelectedShiftTypes) {
          for (let jt of jobTypes) {
            for (let i = 0, len = jt.associatedShiftTypes.length; i < len; i++) {
              const shiftId = jt.associatedShiftTypes[i];
              if (_.has(data, [jt.id, shiftId, 'dates'])) {
                footers.push(
                  {
                    type: 'balance',
                    label: `${jt.name} (${this.shiftTypesById[shiftId].name})`,
                    style,
                    hasError: hasError(jt.id, shiftId),
                    data: data[jt.id][shiftId].dates
                  }
                );
              }
            }
          }
        } else {
          for (let jt of jobTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (jt.associatedShiftTypes.includes(shiftTypeId)) {
                if (_.has(data, [jt.id, shiftTypeId, 'dates'])) {
                  footers.push(
                    {
                      type: 'balance',
                      label: `${jt.name} (${this.shiftTypes[shiftTypeId].name})`,
                      hasError: hasError(jt.id, shiftTypeId),
                      style,
                      data: data[jt.id][shiftTypeId].dates
                    }
                  );
                }
              }
            }
          }
        }
        return footers;
      }
    },
    allRecords () {
      return _.get(this.$store.state.scheduling.grids[this.selectedSchedule.id], ['records'], []);
    },
    records () {
      const records = _.get(this.$store.state.scheduling.grids[this.selectedSchedule.id], ['records'], []);
      const filteredRecords = [];
      const shiftFilterEnabled = _.get(this.filters, 'shift.enabled', true);
      const eventFilterEnabled = _.get(this.filters, 'event.enabled', true);
      const eventMatches = (a) => {
        if (this.filters.event) {
          return (this.filters.event.types.length === 0 || this.filters.event.types.includes(a.typeId));
        }
        return true;
      };
      for (let r of records) {
        const row = {
          user: r.user
        };
        let activityCount = 0;
        for (let d in r) {
          if (d !== 'user') {
            row[d] = {
              activities: [],
              request: r[d].request,
              exclude: r[d].exclude || false
            };

            for (let a of r[d].activities) {
              switch (a.type) {
                case 'shift':
                  if (shiftFilterEnabled && shiftMatchesFilters(a, this.filters, this.$store)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
                case 'event':
                  if (eventFilterEnabled && eventMatches(a)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
              }
            }
          }
        }
        if (!this.hasActivityFilters || activityCount > 0) {
          filteredRecords.push(row);
        }
      }
      return filteredRecords;
    },
    scheduleStateIndicator () {
      return this.getScheduleStateIndicator({
        state: this.selectedSchedule.state
      }, this.$vuetify.breakpoint.smAndDown);
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobStatusById () {
      return this.$store.state.org.jobStatus.reduce(
        (obj, jobStatus) => {
          obj[jobStatus.id] = jobStatus;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    allUserRecordMap () {
      return _.get(this.$store.state.scheduling.grids[this.selectedSchedule.id], ['userRecordMap'], {});
    },
    userRecordMap () {
      const userRecordMap = {};
      for (let i = 0, count = this.records.length; i < count; i++) {
        userRecordMap[this.records[i].user.userId] = i;
      }
      return userRecordMap;
    },
    totalErrorCount () {
      return this.$store.getters['scheduling/getErrorCount'](this.selectedSchedule.id);
    },
    filteredErrorCount () {
      let count = 0;
      const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedSchedule.id);
      let jobTypeIds = _.keys(jobTypesById);
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      for (let id of jobTypeIds) {
        if (this.hasSelectedShiftTypes) {
          for (let shiftTypeId of this.selectedShiftTypes) {
            if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
              count += this.$store.getters['scheduling/getErrorCount'](
                this.selectedSchedule.id,
                id,
                shiftTypeId
              );
            }
          }
        } else {
          count += this.$store.getters['scheduling/getErrorCount'](
            this.selectedSchedule.id,
            id
          );
        }
      }
      return count;
    },
    selectedScheduleText () {
      const startFrom = moment(this.selectedSchedule.startOn);
      const endBy = moment(this.selectedSchedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    validators () {
      const validators = _.values(this.$store.getters['scheduling/getValidator'](this.selectedSchedule.id));
      return _.sortBy(validators, [function (v) { return v.constructor.priority; }]);
    },
    ...mapState(['sidePanelOpened'])
  },

  watch: {
    department () {
      this.redraw();
    },
    filters: {
      handler () {
        if (this.grid) {
          this.filterGrid(true);
        }
      },
      deep: true
    },
    gridLanguage () {
      if (this.grid) {
        this.grid.language = this.gridLanguage;
        this.updateGridEmptyMessage();
      }
    },
    'panels.length' (newLength, prevLength) {
      if (newLength === 0) {
        this.showPanelOnCellSelect = false;
        this.$store.commit('scheduling/update_panel', { panel: 'dailySummary', prop: 'tab', value: 'summary' });
      }
    },
    retrievingSchedule () {
      if (!this.retrievingSchedule) {
        this.updateFooterHeight();
      }
    },
    'records' () {
      if (this.grid) {
        const self = this;
        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
      }
    },
    selectedJobTypeTab () {
      this.refreshPanels();
    },
    sidePanelOpened (newValue, oldValue) {
      // This is the global side panel
      if (newValue !== oldValue) {
        this.redrawGrid(true);
      }
    },
    staffFilter: _.debounce(function () {
      this.filterGrid(false);
    }, SEARCH_INPUT_DEBOUNCE),
    mobileSummaryRow () {
      if (this.grid) {
        this.redrawGrid(true);
      }
    },
    openedPanelName (openedPanelName) {
      if (openedPanelName) {
        switch (openedPanelName) {
          case 'filters':
            this.closeAllPanels();
            this.openFiltersPanel();
            break;
          case SNAPSHOT_HISTORY:
            this.openHistory();
            break;
        }
      } else {
        this.closeAllPanels();
        this.closeAllPersistentPanels();
      }
    }
  },

  mounted: function () {
    const schedulePeriods = this.department.schedulingPeriods;
    if (schedulePeriods.length > 0) {
      this.retrieveSnapshots(schedulePeriods[0].startOn).then((snapshots) => {
        if (snapshots) {
          this.selectedSchedule = snapshots[0];
          this.selectedScheduleSnapshots = snapshots;
          this.retrieveSchedule();
        } else {
          this.selectedSchedule = schedulePeriods[0];
          this.selectedScheduleSnapshots = [];
        }
      });
    } else {
      this.noSnapshots = true;
    }
    this.$nextTick(function () {
      this.updatePageTitleWidth();
    });
    window.addEventListener('resize', this.updatePageTitleWidth);
  },

  beforeDestroy: function () {
    this.unbindShortcuts();
    this.destroyGrid();
    window.removeEventListener('resize', this.updatePageTitleWidth);
  },

  methods: {
    bindShortcuts () {
      this.unbindShortcuts();
      this.mousetrap.bind(['up', 'right', 'down', 'left'], (e, key) => {
        if (this.grid) {
          this.grid.keyDownMove(e);
        }
      });

      this.mousetrap.bind(['enter'], (e, key) => {
        if (this.grid && this.grid.hasFocusGrid()) {
          this.showPanelOnCellSelect = true;
          this.showCellDetails(this.grid.selection.select);
        }
      });

      this.mousetrap.bindGlobal(['mod+/'], (e, key) => {
        this.toggleHelpPanel();
      });
    },

    unbindShortcuts () {
      this.mousetrap.reset();
    },

    destroyGrid () {
      if (this.grid) {
        this.grid.dispose();
      }
    },

    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    filterGrid (focusGrid) {
      if (this.grid) {
        const filter = this.getGridFilter();
        let newSelectedRow, field;
        // If a cell is selected by the user check if that cell is still on the grid after the filter
        // and select the cell at it's new index. If the cell is no longer in the grid then reset the
        // focus to the top left cell vertex (0, 0) which corresponds to the first cell of the 'user' column.
        // When we need to select the header row we set then row index to -1 because the focusGridCell
        // function adds 1 to the value we pass in.
        if (this.selectedCell.col) {
          if (moment.isMoment(this.selectedCell.col)) {
            field = this.selectedCell.col.valueOf();
          } else {
            field = this.selectedCell.col;
          }
          if (this.selectedCell.user) {
            const newRow = this.getCellRowByUser(this.selectedCell.user);
            if (newRow >= 0) {
              newSelectedRow = newRow;
            } else {
              newSelectedRow = -1;
              field = 'user';
            }
          } else {
            newSelectedRow = -1;
          }
        }

        this.gridfilterDataSource.filter = filter;
        this.updateGridEmptyMessage();
        this.redrawGrid();
        // Redraw a second time. Redrawing once does not render the grid correctly. Blank rows are being
        // inserted. This behavior can also be observed in the cheetah-grid demo page.
        this.redrawGrid(focusGrid);
        if (field) {
          this.grid.selectGridCell(field, newSelectedRow, focusGrid);
        }
      }
    },

    focusGrid () {
      if (this.grid) {
        this.grid.focus();
      }
    },

    /**
     * Gets the shift date for a cell
     * @param {object} cell cheetah-gird cell
     * @returns {Date}
     */
    getCellCol (cell) {
      const col = this.grid.header[cell.col];
      if (col.type === 'schedule') {
        return moment(col.caption);
      } else {
        return col.field;
      }
    },

    /**
     * Gets the user associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellUser (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row['user'];
    },

    getCellRowByUser (userId) {
      // This only looks in the set of filtered rows and returns -1 when the row is not found
      const filter = this.getGridFilter();
      let newRow = 0;
      for (let i = 0, len = this.records.length; i < len; i++) {
        if (filter ? filter(this.records[i]) : true) {
          if (this.records[i].user.userId === userId) {
            return newRow;
          }
          newRow++;
        }
      }
      return -1;
    },

    /**
     * Gets the value associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellValue (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      const col = this.grid.header[cell.col];
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row[col.field];
    },

    getGridFilter () {
      const filters = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        let filterForCharge = false;
        const associatedJobTypes = this.getSelectedJobTypes().map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue);
          return accumulator;
        }, []);
        for (let i = 0, count = associatedJobTypes.length; i < count; i++) {
          if (this.jobTypesById[associatedJobTypes[i]] && _.get(this.jobTypesById[associatedJobTypes[i]], 'settings.isChargeNurse', false)) {
            filterForCharge = true;
            break;
          }
        }
        filters.push((record) => {
          return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0 ||
            (filterForCharge && record.user.charge);
        });
      }
      const status = _.get(this.filters, 'user.status', []);
      if (status.length > 0) {
        filters.push((record) => {
          return status.includes(record.user.jobStatusId);
        });
      }
      const shiftTypes = _.get(this.filters, 'user.shiftTypes', []);
      if (shiftTypes.length > 0) {
        filters.push((record) => {
          return shiftTypes.includes(record.user.shiftTypeId);
        });
      }
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filters.push((record) => {
          return userMatchesText(record.user, text);
        });
      }

      const filtersCount = filters.length;
      let filter = null;
      if (filtersCount > 0) {
        filter = (record) => {
          if (_.isEmpty(record)) {
            return false;
          }
          let match = true;
          for (let i = 0; i < filtersCount; i++) {
            if (_.isFunction(filters[i])) {
              match &= filters[i](record);
            } else {
              match &= _.get(record, filters[i].key, null) === filters[i].value;
            }
          }
          return match;
        };
      }
      return filter;
    },

    getJobTypeTabIndex (jobId) {
      for (let i = 0, count = this.groupedJobTypes.length; i < count; i++) {
        const matchedJobType = _.find(this.groupedJobTypes[i], (jobType) => {
          return jobType.id == jobId;
        });
        if (matchedJobType) {
          return i;
        }
      }
      return -1;
    },
    getScheduleStateIndicator (schedule, mobile) {
      const stateIndicator = {
        stateLabelCssClass: 'grey darken-1',
        stateLabelKey: mobile ? 'labels.scheduleStatePastAbbr' : 'labels.scheduleStatePast'
      };
      if (!schedule) {
        return stateIndicator;
      }
      switch (schedule.state) {
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          stateIndicator.stateLabelCssClass = 'error';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePendingApprovalDirectorAbbr' : 'labels.scheduleStatePendingApprovalDirector';
          break;

        case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
          stateIndicator.stateLabelCssClass = 'info';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateUnderReviewNurseAbbr' : 'labels.scheduleStateUnderReviewNurse';
          break;

        case SCHEDULE_STATES.PUBLISHED:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePublishedAbbr' : 'labels.scheduleStatePublished';
          break;

        case SCHEDULE_STATES.DRAFT:
          stateIndicator.stateLabelCssClass = 'nb-orange';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateDraftAbbr' : 'labels.scheduleStateDraft';
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateSelfScheduleAbbr' : 'labels.scheduleStateSelfSchedule';
          break;
        case SCHEDULE_STATES.CURRENT:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateCurrentAbbr' : 'labels.scheduleStateCurrent';
          break;
        case SCHEDULE_STATES.INITIAL:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateInitialAbbr' : 'labels.scheduleStateInitial';
          break;
      }

      return stateIndicator;
    },
    getSelectedJobTypes () {
      return this.groupedJobTypes[this.selectedJobTypeTab];
    },
    getSelectedAssociatedJobTypeIds () {
      return _.uniq(this.groupedJobTypes[this.selectedJobTypeTab].reduce((jobTypes, jt) => {
        jobTypes.push(...jt.associatedJobTypes);
        return jobTypes;
      }, []));
    },
    getSelectedJobTypeIds () {
      return this.groupedJobTypes[this.selectedJobTypeTab].map((jt) => jt.id);
    },
    getValidatorName (validator) {
      return validator.constructor.name;
    },
    getValidatorErrorCount (validator) {
      let count = 0;
      const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedSchedule.id);
      let jobTypeIds = _.keys(jobTypesById);
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      for (let id of jobTypeIds) {
        if (this.hasSelectedShiftTypes) {
          for (let shiftTypeId of this.selectedShiftTypes) {
            if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
              count += validator.errorCount(
                parseInt(id),
                parseInt(shiftTypeId)
              );
            }
          }
        } else {
          count += validator.errorCount(
            parseInt(id)
          );
        }
      }
      return count;
    },
    jobTypeById (id, prop) {
      return this.$store.getters['org/getJobTypeById'](id, prop);
    },
    /**
     * Returns a value indicating whether there is (specific) validation error.
     * @param {string} (Optional) a path corresponding to a specific error to check. @see https://lodash.com/docs/4.17.15#has
     * @returns {Boolean} true if there is (specific) validation error; otherwise false.
     */
    hasValidationErrors (job) {
      let errorCount = 0;

      if (job) {
        errorCount = this.$store.getters['scheduling/getErrorCount'](this.selectedSchedule.id, job);
      } else {
        errorCount = this.totalErrorCount;
      }

      return errorCount > 0;
    },
    initGrid () {
      let self = this;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
        font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
      });

      const theme = cheetahGrid.themes.SCHEDULER.extends({
        holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
      });

      this.updateGridHeight();
      const validators = this.$store.getters['scheduling/getValidator'](this.selectedSchedule.id);
      const getValidationStyle = (shift) => {
        const styles = {};
        for (let name in validators) {
          _.merge(styles, validators[name].style(shift));
        }
        if (shift.overtime) {
          styles.color = scssVariables.error;
        }
        return styles;
      };
      const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
      const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
      const sitterBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'sitter', 'bgColor'], null);
      this.$nextTick(() => {
        this.grid = new cheetahGrid.ScheduleGrid({
          parentElement: this.$refs.gridParent,
          onResize: () => {
            this.redrawGrid();
          },
          emptyMessage: this.emptyMessage,
          header: this.headers,
          footer: this.footers,
          firstDayOfWeek: 0,
          frozenColCount: this.FROZEN_COLUMN_COUNT,
          language: this.gridLanguage,
          showStaffPerDay: true,
          showWeeklyBalance: false,
          symbols: {
            borderColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'borderColor')) {
                  return validationStyle.borderColor;
                }
              }
              return null;
            },
            bgColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'bgColor')) {
                  return validationStyle.bgColor;
                }
              }
              return null;
            },
            event (value) {
              return self.eventTypes[value.typeId].styles.web;
            },
            shift (shift) {
              if (!self.shiftTypes[shift.typeId]) {
                return {};
              }
              let style = _.cloneDeep(self.shiftTypes[shift.typeId].styles).web;
              if (shift.available) {
                style = _.cloneDeep(self.scheduleSymbols.shift.web.available);
              } else {
                const shiftType = self.shiftTypes[shift.typeId];
                let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
                let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
                if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                  let start = startTime.substring(0, 5);
                  let end = endTime.substring(0, 5);
                  if (shift.obligatory) {
                    start += '*';
                    end += '  ';
                  }
                  style.symbolType = 'time';
                  style.symbolValue = { start, end };
                }
              }

              if (shift.departmentId !== self.department.id && self.allDepartments[shift.departmentId]) {
                const departmentName = self.allDepartments[shift.departmentId].name;
                style.symbolType = 'text';
                style.symbolValue = departmentName;
              }

              if (shift.obligatory) {
                if (_.get(style, 'symbolType', '') === 'text') {
                  style.symbolValue += '*';
                }
              }
              const validationStyle = getValidationStyle(shift);
              // Validation background color will be applied to the whole cell not individual shifts.
              delete validationStyle.bgColor;
              let bgColor = null;
              if (shift.sitter) {
                bgColor = sitterBgColor;
              } else if (shift.canceled) {
                bgColor = canceledBgColor;
              } else if (shift.onCall) {
                bgColor = onCallBgColor;
              } else if (!isProductiveShift(shift, self.shiftFlags)) {
                bgColor = canceledBgColor;
              }

              return {
                ...style,
                ...validationStyle,
                bgColor,
                modified: wasShiftModifiedByManagement(shift, self.$store.state),
                differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
              };
            },
            request (value) {
              return _.get(self.$store.state.org, ['settings', 'scheduling', 'symbols', 'request', 'pendingApprovalSchedulerOrOperator'], {
                color: '#FF5378'
              });
            },
            week (user, week) {
              let style = {};
              if (validators['OvertimeValidator']) {
                style = validators['OvertimeValidator'].weekStyle(user, week);
              }
              return {
                bgColor: '#F5F5F5',
                ...style
              };
            }
          },
          theme
        });

        const { CLICK_CELL, SCROLL, SELECTED_CELL, CLICK_USER_SETTINGS, CLICK_USER_NAME } = cheetahGrid.ScheduleGrid.EVENT_TYPE;
        this.grid.listen(CLICK_CELL, (cell) => {
          // At this moment we do not allow clicking on the user column therefore ignore the click
          if (cell.col > 0) {
            this.showPanelOnCellSelect = true;
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SELECTED_CELL, (cell) => {
          if (cell.selected) {
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SCROLL, (e) => {
          this.gridState.canScrollLeft = this.grid.canScrollLeft();
          this.gridState.canScrollRight = this.grid.canScrollRight();
        });

        this.grid.listen(CLICK_USER_SETTINGS, (cell) => {
          this.showNurseDetails(this.getCellUser(cell).userId);
          setTimeout(() => {
            this.grid.setSelectedUserSettings(cell.row);
          }, 0);
        });

        this.grid.listen(CLICK_USER_NAME, (cell) => {
          this.openNurseDetails(this.getCellUser(cell));
        });

        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
        this.gridState.canScrollLeft = this.grid.canScrollLeft();
        this.gridState.canScrollRight = this.grid.canScrollRight();

        this.bindShortcuts();
      });
    },

    /**
     * @see https://momentjs.com/
     */
    moment,
    onJobTypeChange (idx) {
      this.selectedJobTypeTab = idx;
      this.filterGrid(true);
    },
    setOpenedPanelName (name) {
      this.openedPanelName = name;
    },
    openHistory () {
      this.showPanel(
        this.getHistoryPanelData,
        [],
        true
      );
    },
    getHistoryPanelData () {
      return {
        component: SnapshotHistory,
        props: {
          selectedSnapshotId: this.selectedSchedule.id,
          availableSnapshots: this.selectedScheduleSnapshots
        },
        events: {
          'close': () => {
            this.openedPanelName = '';
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          },
          'change': (snapshot) => {
            if (this.selectedSchedule.id === snapshot.id) {
              return;
            }
            this.selectedSchedule = _.cloneDeep(snapshot);
            this.closeAllPanels();
            this.closeAllPersistentPanels();
            this.retrieveSchedule().then(() => {
              setTimeout(() => {
                this.openedPanelName = SNAPSHOT_HISTORY;
              }, 0);
            });
          }
        }
      };
    },
    /**
     * Redraws the grid
     */
    redrawGrid (focusGrid) {
      if (this.grid) {
        this.updateGridHeight();
        // After updating the grid height we need to wait for vue to finished re-evaluating
        // any grid height dependencies so that cheetah-grid can calculate the correct width
        // and height for the grid.
        this.$nextTick(() => {
          this.grid.footer = this.footers;
          this.grid.resize();
          if (focusGrid) {
            this.focusGrid();
          }
        });
      }
    },

    retrieveSchedule () {
      this.retrievingSchedule = true;
      this.showSchedulePicker = false;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      return this.dispatch('scheduling/retrieveScheduleSnapshot', this.selectedSchedule.id).then((scheduleId) => {
        // DO NOT move the code below to 'finally' block like other places.
        // We need to immediately hide the skeleton loader upon receiving response
        // from the web API and render the parent container for the grid. Otherwise
        // the grid won't get initialized.
        this.retrievingSchedule = false;
        // Wait for the next tick to ensure the skeleton loader is hidden and the
        // parent container for the grid is rendered.
        this.$nextTick(() => {
          this.initGrid();
        });
        this.$store.commit('scheduling/set_active_snapshot', { snapshotId: this.selectedSchedule.id, snapshotPeriod: this.selectedSchedule });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.snapshotRetrievalFail'),
          type: 'error',
          data
        });
      });
    },

    retrieveSnapshots (date) {
      return new Promise((resolve, reject) => {
        this.dispatch('scheduling/retrieveScheduleSnapshotsByDepartment', { deptId: this.department.id, date }).then((snapshots) => {
          for (let i = 0, count = snapshots.length; i < count; i++) {
            snapshots[i].style = this.getScheduleStateIndicator(snapshots[i], false);
          }
          snapshots = _.orderBy(snapshots, [(s) => s.createdOn], ['desc']);
          if (snapshots.length > 0) {
            this.noSnapshots = false;
            resolve(snapshots);
          } else {
            this.noSnapshots = true;
            resolve(null);
          }
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.snapshotRetrievalFail'),
            type: 'error',
            data
          });
          this.noSnapshots = true;
          reject(error);
        });
      });
    },

    getDailySummaryShiftsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          const activities = _.get(this.records[i], [field, 'activities'], []);
          if (activities.length > 0) {
            summaryData.push({
              date,
              details: this.records[i][field],
              user: this.records[i].user
            });
          }
        }
      }
      return summaryData;
    },

    /**
     * Shows cell details
     * @param {object} cell cheetah-grid cell
     * @param {int} cell.row Cell row index
     * @param {int} cell.col Cell column index
     */
    showCellDetails (cell) {
      if (cell.col > 0) {
        if (cell.row > 0) {
          const value = this.getCellValue(cell);
          const activities = _.get(value, ['activities'], []);
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = this.getCellUser(cell).id;
          if (this.showPanelOnCellSelect) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              if (activities.length > 0) {
                this.showActivityDetails({ cell }, true);
              } else {
                this.showEmptyPanel(this.selectedCell.col);
              }
            } else {
              this.showUserWeeklySummary(this.getCellUser(cell), header);
            }
          }
        } else if (cell.row === 0) {
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = null;
          if (this.showPanelOnCellSelect) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              this.showDailySummary(this.getCellCol(cell), true);
            } else {
              this.showWeeklySummary(header);
            }
          }
        }
      } else {
        this.selectedCell.col = null;
        this.selectedCell.user = null;
        if (this.showPanelOnCellSelect) {
          this.showSelectCellPanel();
        }
      }
    },

    showActivityDetails (schedule, resetPanels) {
      this.showPanel(
        this.getActivityDetailsPanelData,
        [schedule],
        resetPanels
      );
    },

    getActivityDetailsPanelData (schedule) {
      let date, details, user;
      if (schedule.cell) {
        try {
          date = this.getCellCol(schedule.cell);
          details = this.getCellValue(schedule.cell);
          user = this.getCellUser(schedule.cell);
        } catch {
          // An error can happen if the cell is no on the grid due to filtering, in taht case
          // we display the empty cell panel.
          return {
            component: EmptyCell,
            props: {
              date
            },
            events: {}
          };
        }
      } else {
        date = schedule.date;
        details = schedule.details;
        user = schedule.user;
      }

      const activities = _.cloneDeep(_.get(details, 'activities', []));
      const shifts = [];
      const events = [];
      for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
        switch (activities[i].type) {
          case 'shift':
            shifts.push(activities[i]);
            break;
          case 'event':
            events.push(activities[i]);
            break;
        }
      }

      const shiftCount = shifts.length;
      const eventCount = events.length;
      if (eventCount > 1 || (shiftCount > 0 && eventCount > 0)) {
        return {
          component: ActivityDetails,
          props: {
            date,
            events,
            shifts,
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'show-event': (event) => {
              this.showEventDetails({
                date,
                event,
                user
              });
            },
            'show-shifts': (shifts) => {
              this.showShiftDetails({
                date,
                shifts,
                user
              });
            }
          }
        };
      } else if (shiftCount > 0) {
        return this.getShiftDetailsPanelData({
          date,
          shifts,
          user
        });
      } else if (eventCount > 0) {
        return this.getEventDetailsPanelData({
          date,
          event: events[0],
          user
        });
      }

      return {
        component: EmptyCell,
        props: {
          date
        },
        events: {}
      };
    },

    showShiftDetails (data, resetPanels) {
      this.showPanel(
        this.getShiftDetailsPanelData,
        [data],
        resetPanels
      );
    },

    getShiftDetailsPanelData (data) {
      const { date, shifts, user } = data;
      return {
        component: ShiftDetails,
        props: {
          'allow-delete': false,
          date,
          shifts,
          'saved-shifts': _.cloneDeep(shifts),
          'read-only': this.scheduleIsReadOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          }
        }
      };
    },

    showEventDetails (data, resetPanels) {
      this.showPanel(
        this.getEventDetailsPanelData,
        [data],
        resetPanels
      );
    },

    getEventDetailsPanelData (data) {
      const { date, event, user } = data;
      return {
        component: EventDetails,
        props: {
          'allow-delete': false,
          date,
          event,
          'saved-event': _.cloneDeep(event),
          'read-only': this.scheduleIsReadOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          }
        }
      };
    },

    showDailySummary (date, resetPanels) {
      this.showPanel(this.getDailySummaryPanelData, [date], resetPanels);
    },

    getDailySummaryPanelData (date) {
      let staffNeeded = {};
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        if (this.hasSelectedShiftTypes) {
          for (let i = 0, len = this.jobTypes.length; i < len; i++) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (this.jobTypes[i].id !== this.ALL_JOB_TYPES &&
                  _.indexOf(this.jobTypes[i].associatedShiftTypes, shiftTypeId) >= 0) {
                _.setWith(
                  staffNeeded,
                  [this.jobTypes[i].id, shiftTypeId],
                  _.get(this.jobTypes[i], ['staffNeeded', shiftTypeId], 0),
                  Object
                );
              }
            }
          }
        } else {
          const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedSchedule.id);
          for (let jobId in jobTypesById) {
            staffNeeded[jobId] = jobTypesById[jobId].staffNeeded;
          }
        }
      } else {
        const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedSchedule.id);
        let jobTypeIds = _.keys(jobTypesById);
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
          jobTypeIds = this.getSelectedJobTypeIds();
        }
        for (let id of jobTypeIds) {
          let shifts = {};
          if (this.hasSelectedShiftTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
                shifts[shiftTypeId] = _.get(jobTypesById, [id, 'staffNeeded', shiftTypeId], 0);
              }
            }
          } else {
            shifts = _.get(jobTypesById, [id, 'staffNeeded'], {});
          }
          staffNeeded[id] = shifts;
        }
      }

      return {
        component: DailySummary,
        props: {
          date,
          scheduleId: this.selectedSchedule.id,
          staffNeeded,
          data: this.getDailySummaryShiftsData(date),
          requests: []
        },
        events: {
          'show-shift-details': ({ date, shifts, user }) => {
            this.showShiftDetails({
              date,
              shifts,
              user
            });
          },
          'show-event-details': ({ date, event, user }) => {
            this.showEventDetails({
              date,
              event,
              user
            });
          }
        }
      };
    },

    /**
     * Shows errors overview panel
     */
    showErrorsOverview (validator) {
      this.showPanel(this.getErrorsOverviewPanelData, [this.getValidatorName(validator)], true);
    },

    getErrorsOverviewPanelData (validatorName) {
      let jobTypeIds = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      return {
        component: ErrorsOverview,
        props: {
          jobTypeIds,
          scheduleId: this.selectedSchedule.id,
          validatorName
        },
        events: {
          'filter': (data) => {
            const job = this.getJobTypeTabIndex(data.job);
            this.selectedJobTypeTab = job >= 0 ? job : this.ALL_JOB_TYPES;
            // Reset staff filter for all actions performed on errors panel
            this.filters = getDefaultFilters();
            if (this.staffFilter) {
              this.staffFilter = '';
            } else {
              this.filterGrid(true);
            }

            if (data.user) {
              this.$nextTick(function () {
                this.grid.focusGridCell(moment(this.selectedSchedule.startOn).valueOf(), this.getCellRowByUser(data.user.userId));
                this.grid.displayCellAtTopLeft(this.FROZEN_COLUMN_COUNT, this.getCellRowByUser(data.user.userId));
              });
            } else {
              this.refreshPanels(1);
            }
          }
        }
      };
    },
    openNurseDetails (user) {
      this.nurseDetails = user;
    },
    hideNurseDetails () {
      this.nurseDetails = null;
    },
    updateNurseDetails (user) {
      this.refreshPanels(this.panels.length - 1);
    },
    showNurseDetails (userId) {
      this.showPanel(
        this.getNurseDetailsPanelData,
        [userId],
        true
      );
    },
    getNurseDetailsPanelData (userId) {
      return {
        component: NurseDetails,
        props: {
          date: this.selectedSchedule.startOn,
          user: this.$store.state.org.employees[userId]
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            this.grid.setSelectedUserSettings(null);
            return true;
          },
          'destroyed': () => {
            if (this.grid) {
              this.grid.setSelectedUserSettings(null);
            }
          },
          'notes-saved': () => {
            this.refreshPanels(this.panels.length - 1);
          }
        }
      };
    },
    redraw () {
      this.retrievingSchedule = true;
      this.$nextTick(() => {
        this.staffFilter = '';
        this.selectedJobTypeTab = this.ALL_JOB_TYPES;
        this.retrieveSnapshots(this.selectedSchedule.startOn).then((snapshots) => {
          if (snapshots) {
            this.selectedSchedule = snapshots[0];
            this.selectedScheduleSnapshots = snapshots;
            this.closeAllPanels();
            this.retrieveSchedule();
          }
        });
      });
    },
    showEmptyPanel (date) {
      this.showPanel(() => {
        return {
          component: EmptyCell,
          props: {
            date
          },
          events: {}
        };
      }, [], true);
    },

    showSelectCellPanel () {
      this.showPanel(() => {
        return {
          component: SelectCell,
          props: {},
          events: {}
        };
      }, [], true);
    },
    showPersistentPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.persistentPanels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePersistentPanels(1, this.persistentPanels.length);
        this.updatePersistentPanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePersistentPanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.persistentPanels.push(_.cloneDeep(panelData));
      }
    },
    showPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.panels.length > 0) {
        this.closePanels(1, this.panels.length);
        this.updatePanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.push(_.cloneDeep(panelData));
      }
    },
    showWeeklySummary (week) {
      this.showPanel(this.getWeeklySummaryPanelData, [week], true);
    },
    getWeeklySummaryPanelData (header) {
      const validators = this.$store.getters['scheduling/getValidator'](this.selectedSchedule.id);
      const users = {};

      const filter = this.getGridFilter();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          for (let name in validators) {
            const weeklyErrors = validators[name].getWeeklyErrors(header.caption, this.records[i].user);
            if (weeklyErrors) {
              if (!users[this.records[i].user.userId]) {
                users[this.records[i].user.userId] = {
                  employee: { ...this.records[i].user },
                  errors: []
                };
              }
              users[this.records[i].user.userId].errors.push(weeklyErrors);
            }
          }
        }
      }
      return {
        component: WeeklySummary,
        props: {
          header,
          users
        },
        events: {
          'show-user-summary': (user) => {
            this.grid.focusGridCell(header.field, this.getCellRowByUser(user.employee.userId));
          }
        }
      };
    },
    showUserWeeklySummary (user, header) {
      this.showPanel(this.getUserWeeklySummaryPanelData, [user, header], true);
    },
    getUserWeeklySummaryPanelData (user, header) {
      const errors = [];
      const validators = this.$store.getters['scheduling/getValidator'](this.selectedSchedule.id);
      for (let name in validators) {
        const weeklyErrors = validators[name].getWeeklyErrors(header.caption, user);
        if (weeklyErrors) {
          errors.push(weeklyErrors);
        }
      }
      return {
        component: UserWeeklySummary,
        props: {
          header,
          errors,
          user
        },
        events: {
          'show-shift': (shift) => {
            const shiftDate = moment(shift.payrollDate || shift.date);
            const startOn = moment(this.selectedSchedule.startOn);
            const endOn = moment(this.selectedSchedule.endOn);
            if (shiftDate.isSameOrAfter(startOn) && shiftDate.isSameOrBefore(endOn)) {
              const shifts = _.filter(
                _.get(this.records, [this.userRecordMap[shift.assigneeId], moment(shift.payrollDate || shift.date).valueOf(), 'activities'], []),
                (a) => {
                  return a.type === 'shift';
                }
              );
              this.showShiftDetails({
                date: moment(shift.payrollDate || shift.date),
                selectedShiftIndex: Math.max(0, _.findIndex(shifts, (s) => s.id === shift.id)),
                shifts,
                user
              });
            }
          }
        }
      };
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    updatePersistentPanel (panelIdx, panelDataCallback, params) {
      const panel = this.persistentPanels[panelIdx];
      const panelData = this.preparePersistentPanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.persistentPanels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    refreshPanels (count) {
      const len = count || this.panels.length;
      for (let i = 0; i < len; i++) {
        this.updatePanel(i, this.panels[i].panelDataCallback, this.panels[i].params);
      }
    },
    refreshPersistentPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.persistentPanels.length;
        for (let i = 0; i < len; i++) {
          this.updatePersistentPanel(i, this.persistentPanels[i].panelDataCallback, this.persistentPanels[i].params);
        }
      }
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPanel();
          }
        } else {
          this.closeLastPanel();
        }
      };
      return panelData;
    },
    preparePersistentPanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPersistentPanel();
          }
        } else {
          this.closeLastPersistentPanel();
        }
      };
      return panelData;
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    closePersistentPanels (start, count) {
      this.persistentPanels.splice(start, count);
    },
    closeAllPanels () {
      this.openedPanelName = '';
      this.panels.splice(0, this.panels.length);
    },
    closeAllPersistentPanels () {
      this.openedPanelName = undefined;
      this.persistentPanels.splice(0, this.persistentPanels.length);
    },
    closeLastPanel () {
      this.panels.pop();
    },
    closeLastPersistentPanel () {
      this.persistentPanels.pop();
    },
    toggleHelpPanel () {
      this.showHelp = !this.showHelp;
    },
    openFiltersPanel () {
      this.showPersistentPanel(() => {
        this.openedPanelName = 'filters';
        return {
          props: {
            eventFilters: _.cloneDeep(_.get(this.filters, 'event', {})),
            shiftFilters: _.cloneDeep(_.get(this.filters, 'shift', {})),
            userFilters: _.cloneDeep(_.get(this.filters, 'user', {}))
          },
          component: Filters,
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            apply: (filters) => {
              this.filters = filters;
              this.refreshPersistentPanels();
            }
          }
        };
      }, [], true);
    },
    updateFooterHeight () {
      this.$nextTick(() => {
        const footer = document.getElementsByClassName('schedule-summary-row')[0];
        if (footer) {
          this.footerHeight = footer.getBoundingClientRect().height;
        }
      });
    },
    updateGrid () {
      if (this.grid) {
        this.grid.footer = this.footers;
        this.grid.header = this.headers;
      }
    },
    updateGridHeight () {
      this.updateFooterHeight();
      const grid = document.getElementsByClassName('grid-parent')[0];
      if (grid) {
        const top = grid.getBoundingClientRect().top;
        this.gridTop = top;
        this.gridHeight = window.innerHeight - top;
        this.gridWidth = grid.offsetWidth;
      }
    },
    updateGridEmptyMessage () {
      if (this.grid) {
        this.grid.emptyMessage = this.emptyMessage;
      }
    },
    updatePageTitleWidth () {
      const header = document.getElementsByClassName('schedule-header')[0];
      if (header) {
        const cols = header.getElementsByClassName('col');
        const PADDING = this.$vuetify.breakpoint.smAndDown ? 10 : 45;
        const DEFAULT_WIDTH = 115;
        // Start at index 1 to ignore the title column.
        let width = header.offsetWidth;
        for (let i = 1, len = cols.length; i < len; i++) {
          width -= cols[i].offsetWidth ? cols[i].offsetWidth : DEFAULT_WIDTH;
        }
        this.pageTitleWidth = width - PADDING;
      }
    },
    setSelectedSchedule (schedule) {
      if (schedule) {
        const updateSelections = () => {
          if (this.getSelectedJobTypes() === undefined) {
            this.selectedJobTypeTab = this.ALL_JOB_TYPES;
          }
        };
        this.retrievingSchedule = true;
        this.$nextTick(() => {
          this.retrieveSnapshots(schedule.startOn).then((snapshots) => {
            if (snapshots) {
              this.selectedSchedule = snapshots[0];
              this.selectedScheduleSnapshots = snapshots;
              updateSelections();
              this.closeAllPanels();
              this.retrieveSchedule();
            } else {
              this.selectedSchedule = schedule;
              this.selectedScheduleSnapshots = [];
              updateSelections();
            }
          });
        });
      }
    }
  }
};
</script>

<style lang="scss">
.container.schedule {
  height: 100%;

  .cell-details {
    transition: width 0.3s;
  }

  .v-tabs {
    .v-tabs-bar {
      border-color: #eeeeee;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      margin-right: 10px;
    }
  }

  .grid-action-bar {
    background-color: white;
    border-color: map-get($grey, 'lighten-3');
    border-style: solid;
    border-width: 1px 1px 0 1px;
    margin-left: 0px;
    margin-right: 10px;
    min-height: 60px;
  }

  .grid-empty-container {
    text-align: left;
  }
  .schedule-dropdown {
    border: 1px solid map-get($grey, 'lighten-2');
    .v-btn__content {
      justify-content: left;
      i {
        position: absolute;
        right: 8px;
      }
    }
  }
  .search-staff {
    position: absolute;
    z-index: 1;

    .v-icon {
      margin-top: 0px !important;
    }
  }
  .shift-type {
    padding: 0px 5px !important;
    min-width: 34px !important;
    width: 34px !important;
  }
}

$summary-color: #FDE3E3;

.schedule-summary-row {
  background-color: $summary-color !important;
  .v-btn {
    background-color: rgba(255,255,255,0.4) !important;
  }
}
.schedule-summary-row-toggle {
  background-color: $summary-color !important;
  bottom: 54px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: bottom;
  -webkit-transition-property: bottom;
  transition-property: bottom;

  &.active {
    bottom: 108px;
    -webkit-transition-duration: 0.05s;
    transition-duration: 0.05s;
  }
}

.schedule-picker {
  .v-input__control > .v-input__slot {
    background-color: map-get($grey, 'lighten-5') !important;

    fieldset {
      border-color: map-get($grey, 'lighten-2');
    }

    .v-select__selection {
      color: $primary;
    }
  }
}
</style>
