<template>
  <v-container
    class="px-8 py-0 schedule-filters"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-filter</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.filter') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="pa-0"
      :style="containerStyle"
    >
      <v-row dense>
        <v-col cols="12">
          <JobStatusSelection
            v-model="filters.user.status"
            class="mb-1"
            :label="$t('labels.allJobStatus')"
            multiple
            vid="jobStatus"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="filters.user.shiftTypes"
            class="mb-1 inline primary-shift-types"
            dense
            :hint="$t('descriptions.allPrimaryShiftTypes')"
            item-text="name"
            item-value="id"
            :items="shiftTypes"
            :label="filters.user.shiftTypes.length === 0 ? $t('labels.allPrimaryShiftTypes') : $t('labels.primaryShiftTypes')"
            :menu-props="{ top: false, offsetY: true }"
            multiple
            outlined
            persistent-hint
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="filters.user.shiftTypes = []"
              >
                <v-list-item-action>
                  <v-icon
                    color="secondary"
                    small
                  >
                    {{ filters.user.shiftTypes.length === 0 ? 'far fa-check-square' : 'far fa-square' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('labels.allPrimaryShiftTypes') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
            <template #selection="{ index, item }">
              {{ item.name + (index === filters.user.shiftTypes.length - 1 ? '' : ',') }}
            </template>
            <template #item="{ item }">
              <v-list-item-action>
                <v-icon
                  color="secondary"
                  small
                >
                  {{ filters.user.shiftTypes.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-card
        class="pa-4 mb-4"
        outlined
      >
        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="filters.shift.enabled"
              class="pt-0 mt-0 ml-1 d-inline-block shift-enabled"
              color="success"
              dense
              hide-details
              inset
            >
              <template v-slot:label>
                <span
                  class="mr-4 body-2 grey--text text--darken-3"
                  :title="$t('labels.shift')"
                >
                  {{ $t('labels.shift') }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-combobox
              ref="selectShiftFlag"
              v-model="filters.shift.flags"
              small-chips
              class="shift-flags"
              dense
              :filter="flagsFilter"
              hide-details
              item-text="shortCode"
              item-value="id"
              :items="shiftFlags"
              :label="filters.shift.flags.length === 0 ? $t('labels.allFlags') : $tc('labels.flag', 2)"
              multiple
              outlined
              :return-object="false"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="filters.shift.flags = []"
                >
                  <v-list-item-action>
                    <v-icon
                      color="secondary"
                      small
                    >
                      {{ filters.shift.flags.length === 0 ? 'far fa-check-square' : 'far fa-square' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.allFlags') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
              <template #selection="{ item: flagId }">
                <v-chip
                  v-if="shiftFlagsById[flagId]"
                  class="lighten-2 grey--text text--darken-3 flag-short-code mb-1"
                  color="info"
                  small
                  :title="shiftFlagsById[flagId].name"
                >
                  {{ shiftFlagsById[flagId].shortCode }}
                </v-chip>
              </template>
              <template v-slot:item="{ attrs, item: flag, on }">
                <v-list-item
                  :class="[filters.shift.flags.includes(flag.id) ? 'v-list-item--active' : '', 'flag-item']"
                  :title="flag.name"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-icon
                      color="secondary"
                      small
                    >
                      {{ filters.shift.flags.includes(flag.id) ? 'far fa-check-square' : 'far fa-square' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-icon
                    class="mr-0"
                    style="width: 60px"
                  >
                    <v-chip
                      class="lighten-2 grey--text text--darken-3 flag-short-code"
                      color="info"
                      small
                    >
                      {{ flag.shortCode }}
                    </v-chip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span
                      class="grey--text text--darken-3 body-2 d-inline-block text-truncate"
                      style="width: 100px;"
                    >
                      {{ flag.name }}
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <v-card
              class="grey lighten-5 mt-3 pa-4"
              outlined
            >
              <div class="grey--text text--darken-3 font-weight-medium body-2 mb-2">
                {{ $t('labels.showResultsContaining') }}
              </div>
              <v-select
                v-model="filters.shift.flagsMode"
                class="mb-2"
                dense
                hide-details
                item-text="name"
                item-value="id"
                :items="flagModes"
                :menu-props="{ top: false, offsetY: true }"
                outlined
              >
                <template v-slot:item="{ attrs, item, on }">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-radio-group
                v-model="filters.shift.flagsOp"
                class="mt-0 pt-0 extra-dense-radio flags-op"
                dense
                hide-details
                mandatory
              >
                <v-radio
                  class="mb-2 any-flag"
                  color="secondary"
                  :label="$t('labels.anySelectedFlags')"
                  value="or"
                />
                <v-radio
                  class="all-flag"
                  color="secondary"
                  :label="$t('labels.allSelectedFlags')"
                  value="and"
                />
              </v-radio-group>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="filters.shift.types"
              class="inline shift-types"
              dense
              hide-details
              item-text="name"
              item-value="id"
              :items="shiftTypes"
              :label="filters.shift.types.length === 0 ? $t('labels.allShiftTypes') : $t('labels.shiftTypes')"
              :menu-props="{ top: false, offsetY: true }"
              multiple
              outlined
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="filters.shift.types = []"
                >
                  <v-list-item-action>
                    <v-icon
                      color="secondary"
                      small
                    >
                      {{ filters.shift.types.length === 0 ? 'far fa-check-square' : 'far fa-square' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.allShiftTypes') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
              <template #selection="{ index, item }">
                {{ item.name + (index === filters.shift.types.length - 1 ? '' : ',') }}
              </template>
              <template #item="{ item }">
                <v-list-item-action>
                  <v-icon
                    color="secondary"
                    small
                  >
                    {{ filters.shift.types.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="filters.shift.mode"
              class="inline shift-mode"
              dense
              :hint="selectedShiftModeHint"
              item-text="name"
              item-value="id"
              :items="shiftModes"
              :menu-props="{ top: false, offsetY: true }"
              outlined
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="pa-4 mb-4"
        outlined
      >
        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="filters.event.enabled"
              class="pt-0 mt-0 ml-1 d-inline-block event-enabled"
              color="success"
              dense
              hide-details
              inset
            >
              <template v-slot:label>
                <span
                  class="mr-4 body-2 grey--text text--darken-3"
                  :title="$t('labels.event')"
                >
                  {{ $tc('labels.event', 1) }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="filters.event.types"
              class="inline event-type"
              dense
              hide-details
              item-text="name"
              item-value="id"
              :items="eventTypes"
              :label="filters.event.types.length === 0 ? $t('labels.allEventTypes') : $t('labels.eventTypes')"
              :menu-props="{ top: false, offsetY: true }"
              multiple
              outlined
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="filters.event.types = []"
                >
                  <v-list-item-action>
                    <v-icon
                      color="secondary"
                      small
                    >
                      {{ filters.event.types.length === 0 ? 'far fa-check-square' : 'far fa-square' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.allEventTypes') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
              <template #selection="{ index, item }">
                {{ item.name + (index === filters.event.types.length - 1 ? '' : ',') }}
              </template>
              <template #item="{ item }">
                <v-list-item-action>
                  <v-icon
                    color="secondary"
                    small
                  >
                    {{ filters.event.types.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-row
      class="filter-actions"
      dense
    >
      <v-col cols="6">
        <v-btn
          class="reset-filter"
          color="primary"
          outlined
          width="100%"
          @click="reset"
        >
          {{ $t('labels.reset') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          class="apply-filter"
          color="secondary"
          width="100%"
          @click="apply"
        >
          {{ $t('labels.apply') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { getDefaultFilters } from '@/utils/scheduling';
import JobStatusSelection from '@/components/JobStatusSelection';

export default {
  components: {
    JobStatusSelection
  },
  props: {
    eventFilters: {
      default: function () {
        return {};
      },
      type: Object
    },
    shiftFilters: {
      default: function () {
        return {};
      },
      type: Object
    },
    userFilters: {
      default: function () {
        return {};
      },
      type: Object
    }
  },
  data () {
    const filters = getDefaultFilters();
    return {
      filters: {
        event: {
          ...filters.event,
          ...this.eventFilters
        },
        shift: {
          ...filters.shift,
          ...this.shiftFilters
        },
        user: {
          ...filters.user,
          ...this.userFilters
        }
      },
      height: 300
    };
  },
  computed: {
    containerStyle () {
      return {
        height: `${this.height}px`,
        'overflow-y': 'auto'
      };
    },
    eventTypes () {
      if (this.$store.getters['account/isStaff']()) {
        return _.sortBy(_.filter(this.$store.state.org.eventTypes, (et) => et.visibleToOtherStaff), ['name']);
      } else {
        return _.sortBy(this.$store.state.org.eventTypes, ['name']);
      }
    },
    flagModes () {
      return [
        {
          id: 'contains',
          name: this.$t('labels.containing')
        },
        {
          id: 'not_contains',
          name: this.$t('labels.notContaining')
        }
      ];
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    selectedShiftModeHint () {
      const selectedMode = _.find(this.shiftModes, (sm) => sm.id === this.filters.shift.mode);
      return selectedMode.hint;
    },
    shiftFlags () {
      if (this.$store.getters['account/isStaff']()) {
        return _.sortBy(_.filter(this.$store.state.org.flags, (f) => f.visibleToOtherStaff), ['name']);
      } else {
        return _.sortBy(this.$store.state.org.flags, ['name']);
      }
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftModes () {
      return [
        {
          hint: this.$t('descriptions.allShiftsFilter'),
          id: 'all',
          name: this.$t('labels.allShifts')
        },
        {
          hint: this.$t('descriptions.availableShiftsFilter'),
          id: 'available',
          name: this.$t('labels.availableFilter')
        },
        {
          hint: this.$t('descriptions.canceledShiftsFilter'),
          id: 'canceled',
          name: this.$t('labels.canceledFilter')
        }
      ];
    },
    shiftTypes () {
      return _.sortBy(this.$store.state.org.shiftTypes.filter(shift => shift.partakeInScheduling), ['name']);
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce((shiftTypes, shiftType) => {
        shiftTypes[shiftType.id] = { ...shiftType };
        return shiftTypes;
      }, {});
    }
  },
  mounted: function () {
    this.updateContainerHeight();
    window.addEventListener('resize', _.debounce(this.updateContainerHeight, 200));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateContainerHeight);
  },
  methods: {
    apply () {
      const filters = _.cloneDeep(this.filters);
      filters.shift.flags = _.filter(filters.shift.flags, _.isFinite);
      this.$emit('apply', filters);
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    reset () {
      const filters = getDefaultFilters();
      this.filters = filters;
      this.$emit('apply', _.cloneDeep(filters));
    },
    updateContainerHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      if (el) {
        const detailsEl = this.$el.getElementsByClassName('filter-actions')[0];
        const HEADER_HEIGHT = 75; // This includes padding
        let height = el.clientHeight - HEADER_HEIGHT;
        if (detailsEl) {
          height -= detailsEl.getBoundingClientRect().height;
        }
        this.height = height;
      }
    }
  }
};
</script>

<style lang="scss">
.schedule-filters {
  &> .v-card {
    &> .row {
      margin-bottom: 8px;
    }
  }

  .inline {
    .v-select__selections {
      font-size: 14px;
      display: inline-block;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      input {
        position: absolute;
        left: 0px;
        width: 10px;
      }
    }
  }
  .primary-shift-types, .shift-mode {
    .v-text-field__details {
      padding: 0px;
      .v-messages__message {
        color: map-get($grey, 'base');
      }
    }
  }
}
</style>
