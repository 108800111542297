<template>
  <v-container
    class="schedule"
    fluid
  >
    <template v-if="retrievingSchedule && !gridInitialized && showSkeletonLoader">
      <v-container
        class="schedule"
        fluid
      >
        <v-row dense>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col
              v-for="n in 2"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="n in 5"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              class="no-action"
              type="table"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="!retrievingSchedule">
      <v-container
        class="schedule"
        fluid
        :style="gridMaxWidth"
      >
        <portal to="page-title">
          <template v-if="$vuetify.breakpoint.width < 600">
            <v-container class="schedule-header py-0">
              <v-row justify="space-between">
                <v-col
                  align-self="center"
                  class="pl-0"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <DepartmentSelector />
                    <v-switch
                      v-if="!readOnly"
                      v-model="applyTemplateAutomatically"
                      class="ml-4"
                      color="success"
                      dense
                      :disabled="updatingSettings"
                      hide-details
                      inset
                      :loading="updatingSettings"
                      @change="updateTemplateAutomation"
                    >
                      <template v-slot:label>
                        <v-tooltip
                          max-width="300px"
                          bottom
                        >
                          <template #activator="{ on, attrs }">
                            <span
                              class="mr-4 body-2 grey--text text--darken-3"
                              :title="$t('labels.automate')"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ $t('labels.automate') }}
                            </span>
                          </template>
                          <span class="body-2">
                            {{ $t('labels.applyTemplateAutomatically') }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-row>
                </v-col>
                <v-spacer />
                <v-col
                  align-self="center"
                  class="px-0"
                  cols="auto"
                >
                  <v-menu
                    min-width="200"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-1"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon>
                          fal fa-ellipsis-v
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="toggleHelpPanel"
                      >
                        <v-list-item-action>
                          <v-icon small>
                            fal fa-info-circle
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.information') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-row
              class="schedule-header"
              justify="space-between"
            >
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector />
                  <v-switch
                    v-if="!readOnly"
                    v-model="applyTemplateAutomatically"
                    class="ml-4"
                    color="success"
                    dense
                    :disabled="updatingSettings"
                    hide-details
                    inset
                    :loading="updatingSettings"
                    @change="updateTemplateAutomation"
                  >
                    <template v-slot:label>
                      <template v-if="$vuetify.breakpoint.width < 930">
                        <span
                          class="body-2 grey--text text--darken-3"
                        >
                          {{ $t('labels.automate') }}
                          <v-tooltip
                            max-width="300px"
                            bottom
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                x-small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fa-fal fa-info-circle
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ $t('labels.applyTemplateAutomatically') }}
                            </span>
                          </v-tooltip>
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="body-2 grey--text text--darken-3"
                        >
                          {{ $t('labels.applyTemplateAutomatically') }}
                        </span>
                      </template>
                    </template>
                  </v-switch>
                </v-row>
              </v-col>
              <v-spacer />
              <template v-if="!$vuetify.breakpoint.smAndDown">
                <v-col
                  align-self="center"
                  class="pr-0"
                  cols="auto"
                >
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="mx-1"
                        icon
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': toggleHelpPanel}"
                      >
                        <v-icon color="primary">
                          fal fa-info-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.information') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-row>
          </template>
        </portal>
        <v-row
          no-gutters
        >
          <v-alert
            class="caption dense font-weight-medium mx-0"
            color="info"
            dense
            outlined
            text
          >
            <v-icon
              slot="prepend"
              class="ml-n1 mr-3"
              color="info"
              size="12"
            >
              fas fa-info-circle
            </v-icon>
            {{ $t('descriptions.scheduleTemplates') }}
          </v-alert>
        </v-row>
        <v-row
          justify="end"
          no-gutters
        >
          <v-col cols="12">
            <v-tabs
              class="job-types"
              mobile-breakpoint="768"
              show-arrows
              slider-color="accent"
              slider-size="3"
              :value="selectedJobTypeTab"
            >
              <v-tab
                v-for="(jobTypeGroup, index) in groupedJobTypes"
                :key="index"
                @click.native.prevent.stop.capture="onJobTypeChange(index)"
              >
                <template
                  v-for="(jobType, jobIndex) in jobTypeGroup"
                >
                  <span
                    v-if="jobIndex > 0"
                    :key="`${jobType.id}-plus`"
                    class="ml-2"
                  >
                    +
                  </span>
                  <span
                    :key="`${jobType.id}-name`"
                    :class="jobIndex > 0 ? 'ml-2' : ''"
                  >
                    {{ jobType.name }}
                  </span>
                </template>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          class="grid-action-bar"
          justify="center"
          justify-md="end"
        >
          <v-spacer />
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.filters.button.classes"
                  icon
                  value="filters"
                  v-bind="attrs"
                  v-on="on"
                  @click="setOpenedPanelName('filters')"
                >
                  <v-icon
                    v-if="hasFilters && openedPanelName !== 'filters'"
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fas fa-filter
                  </v-icon>
                  <v-icon
                    v-else
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fal fa-filter
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.filter') }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <StaffSearch
          v-model.trim="staffFilter"
          :append-icon="staffFilter ? '' : 'fal fa-search'"
          target-class="search-staff py-3 ml-4"
          :clearable="!!staffFilter"
          dense
          :disabled="hasChanges"
          hide-details
          nudge-right="100"
          solo
          :target-style="searchStaffStyle"
        />
        <div
          ref="gridParent"
          class="grid-parent"
          :style="gridStyle"
        />
        <svg
          :style="gridOverlayStyle"
        >
          <polygon
            :points="gridOverlayPath"
            style="fill-opacity:0.05;stroke:transparent;stroke-width:0;"
          />
        </svg>
      </v-container>
      <SidePanel
        :panels="sidePanels"
        @transitionend="redrawGrid(true)"
      />
      <UserDialog
        v-if="nurseDetails"
        :show-hint="false"
        :user="{ ...nurseDetails }"
        @close="hideNurseDetails"
        @saved="updateNurseDetails"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';
import * as Sentry from '@sentry/vue';
import { mapState } from 'vuex';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE_TABLE,
  SEARCH_INPUT_DEBOUNCE,
  getUnsavedChangesDialogProps
} from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import Mousetrap from '@/plugins/mousetrap';
import DailySummary from '@/views/scheduling/schedule_template/DailySummary';
import ActivityDetails from '@/views/scheduling/schedule_template/ActivityDetails';
import NurseDetails from '@/views/scheduling/panels/NurseDetails';
import ShiftDetails from '@/views/scheduling/schedule_template/ShiftDetails';
import ActivitySelection from '@/views/scheduling/schedule_template/ActivitySelection';
import SelectCell from '@/views/scheduling/panels/SelectCell';
import EmptyCell from '@/views/scheduling/panels/EmptyCell';
import Help from '@/views/scheduling/panels/Help';
import Filters from '@/views/scheduling/panels/Filters';
import SidePanel from '@/components/SidePanel';
import DepartmentSelector from '@/components/DepartmentSelector';
import StaffSearch from '@/components/StaffSearch';
import UserDialog from '@/views/admin/users/UserDialog';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { getDefaultFilters, isProductiveShift, isWorkingShiftForDisplay, shiftMatchesFilters, wasShiftModifiedByManagement } from '@/utils/scheduling';
import { userMatchesText } from '@/utils/org';

const DeepDiff = require('deep-diff').diff;
const cheetahGrid = require('@nurse-brite/cheetah-grid');

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;

export default {
  components: {
    DepartmentSelector,
    SidePanel,
    StaffSearch,
    UserDialog
  },

  props: {
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },

  data () {
    const ALL_SHIFT_TYPES = 0;
    const ALL_JOB_TYPES = 0;

    return {
      ALL_JOB_TYPES,
      ALL_SHIFT_TYPES,
      COL_WIDTH_SCHEDULE: 40,
      COL_WIDTH_USER: 200,
      FROZEN_COLUMN_COUNT: 1,
      SIDE_PANEL_WIDTH: 440,
      applyTemplateAutomatically: _.get(this.$store.getters['org/getActiveDepartment'](), 'scheduleRules.applyTemplateAutomatically', true),
      departmentId: null,
      filters: getDefaultFilters(),
      grid: null,
      gridHeight: 500,
      gridTop: 0,
      gridWidth: 500,
      gridState: {
        canScrollLeft: false,
        canScrollRight: false,
        scrollLeft: 0,
        todaysRectangle: null
      },
      gridfilterDataSource: null,
      hasChanges: false,
      helpPanel: [],
      helpPanelData: {
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      },
      innerHeight: 500,
      innerWidth: 500,
      showHelp: false,
      mousetrap: new Mousetrap(this.$el),
      nurseDetails: null,
      openedPanelName: undefined,
      panels: [], // Opened right side panels
      persistentPanels: [],
      retrievingSchedule: true, // Flag for paginating through different time period of the schedule.
      selectedCell: {
        col: null,
        user: null
      },
      selectedJobTypeTab: ALL_JOB_TYPES,
      showPanelOnCellSelect: false,
      staffFilter: '',
      updatingSettings: false
    };
  },

  computed: {
    actionStyles () {
      const defaultButtonClasses = ['grey', 'lighten-2', 'mr-2'];
      const defaultIconClasses = ['grey--text', 'text--darken-3'];
      const styles = {
        filters: {
          button: {
            classes: defaultButtonClasses.concat(['filters'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        viewOpenShift: {
          button: {
            classes: defaultButtonClasses.concat(['view-open-shift'])
          },
          icon: {
            classes: defaultIconClasses
          }
        }
      };

      if (this.hasFilters) {
        styles.filters = {
          button: {
            classes: ['primary', 'lighten-2', 'mr-2', 'filters']
          },
          icon: {
            classes: ['white--text']
          }
        };
      }

      if (this.openedPanelName && styles[this.openedPanelName]) {
        styles[this.openedPanelName].button.classes = ['primary', 'mr-2', this.openedPanelName];
        styles[this.openedPanelName].icon.classes = ['white--text'];
      }

      return styles;
    },
    allDepartments () {
      return this.$store.state.org.departments.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    emptyMessage () {
      let message = '';
      const containerClasses = 'body-1 font-weight-medium grey--text text--darken-3 pa-5';
      if (this.staffFilter) {
        message = `
          <div class="${containerClasses}">
            ${this.$t('descriptions.emptyGridStaffFilter', { staff_filter: _.escape(this.staffFilter) })}
            <hr role="separator" aria-orientation="horizontal" class="my-5 v-divider theme--light">
            <div class="mb-5">
              ${this.$t('descriptions.emptyGridHelpTitle')}
            </div>
            <ul>
              <li>${this.$t('descriptions.emptyGridHelpRemoveFilters')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShiftTypes')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShorterWords')}</li>
              <li>${this.$t('descriptions.emptyGridHelpSpelling')}</li>
            </ul>
          </div>
        `;
      } else {
        const jobTypes = this.getSelectedJobTypes();
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES && jobTypes) {
          const descriptions = jobTypes.map((jt) => jt.description.toLowerCase()).join('/');
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridSpecificJobType', { job_type: _.escape(descriptions) })}
            </div>
          `;
        } else {
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridAllJobTypes')}
            </div>
          `;
        }
      }

      return message;
    },
    eventTypes () {
      return this.$store.state.org.eventTypes.reduce(function (eventTypes, eventType) {
        eventTypes[eventType.id] = eventType;
        return eventTypes;
      }, {});
    },
    gridLanguage () {
      return {
        'clear': this.$t('labels.clear'),
        'daysOfWeek': [
          this.$t('dates.dayOfWeek.0'),
          this.$t('dates.dayOfWeek.1'),
          this.$t('dates.dayOfWeek.2'),
          this.$t('dates.dayOfWeek.3'),
          this.$t('dates.dayOfWeek.4'),
          this.$t('dates.dayOfWeek.5'),
          this.$t('dates.dayOfWeek.6')
        ],
        'months': [
          this.$t('dates.month.0'),
          this.$t('dates.month.1'),
          this.$t('dates.month.2'),
          this.$t('dates.month.3'),
          this.$t('dates.month.4'),
          this.$t('dates.month.5'),
          this.$t('dates.month.6'),
          this.$t('dates.month.7'),
          this.$t('dates.month.8'),
          this.$t('dates.month.9'),
          this.$t('dates.month.10'),
          this.$t('dates.month.11')
        ],
        'disclaimerHint': this.$t('descriptions.disclaimer'),
        'visibilityHint': this.$t('descriptions.commentVisibilitySchedulers'),
        'notes': this.$t('labels.notes'),
        'notesPlaceholder': `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.userNotesPlaceholder')}`,
        'save': this.$t('labels.save'),
        'viewStaffDetails': this.$t('labels.viewStaffDetails'),
        'week': this.$t('labels.week')
      };
    },
    groupedJobTypes () {
      const groupedJobTypes = [];
      const jobTypes = this.jobTypes;
      let group = [jobTypes[0]];
      for (let i = 1, count = jobTypes.length; i < count; i++) {
        if (group[group.length - 1].groupRight) {
          group.push(jobTypes[i]);
        } else {
          groupedJobTypes.push([...group]);
          group = [jobTypes[i]];
        }
      }
      groupedJobTypes.push(group);

      return groupedJobTypes;
    },
    hasFilters () {
      const diff = DeepDiff(getDefaultFilters(), this.filters) || [];
      return diff.length > 0;
    },
    hasActivityFilters () {
      const activityFilters = _.cloneDeep(this.filters);
      delete activityFilters.user;
      const defaultFilters = getDefaultFilters();
      delete defaultFilters.user;
      const diff = DeepDiff(defaultFilters, activityFilters) || [];
      return diff.length > 0;
    },
    hasTabFilters () {
      return this.selectedJobTypeTab !== this.ALL_JOB_TYPES;
    },
    jobTypes () {
      let jobTypes = [
        {
          description: 'All Job Types',
          groupRight: false,
          id: 0,
          name: 'All',
          associatedShiftTypes: [],
          associatedJobTypes: []
        }
      ];
      const scheduleJobTypes = this.$store.getters['org/getJobTypes'](this.department.id);

      for (let i = 0, count = scheduleJobTypes.length; i < count; i++) {
        const jobInfo = scheduleJobTypes[i];
        jobTypes.push({
          ...jobInfo
        });
        jobTypes[0].associatedShiftTypes.push(...jobInfo.associatedShiftTypes);
        jobTypes[0].associatedJobTypes.push(...jobInfo.associatedJobTypes);
      }

      jobTypes[0].associatedShiftTypes = _.uniq(jobTypes[0].associatedShiftTypes);
      jobTypes[0].associatedJobTypes = _.uniq(jobTypes[0].associatedJobTypes);

      return jobTypes;
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getActiveDepartment']() || {};
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    scheduleSymbols () {
      return this.$store.state.org.settings.scheduling.symbols;
    },
    searchStaffStyle () {
      return {
        width: `${this.COL_WIDTH_USER * 0.85}px`
      };
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(function (shiftTypes, shiftType) {
        shiftTypes[shiftType.id] = { ...shiftType };
        // Filter out empty properties from the styles object that have empty values to allow code to easily
        // override styles for validation errors.
        shiftTypes[shiftType.id].styles = _.pickBy(shiftTypes[shiftType.id].styles, _.identity);
        return shiftTypes;
      }, {});
    },
    shiftTypesForSelectedJobType () {
      const jobTypes = this.getSelectedJobTypes();
      const shiftTypes = [];
      for (let jt of jobTypes) {
        shiftTypes.push(...jt.associatedShiftTypes);
      }

      return _.map(_.uniq(shiftTypes), (id) => {
        return this.shiftTypes[id];
      });
    },
    shiftTypesForSelectedJobTypeWithoutOnCall () {
      return _.filter(this.shiftTypesForSelectedJobType, (shiftType) => !shiftType.onCall);
    },
    shortcutActions () {
      const actions = {};
      let shortcut, modifier;
      const action = (shortcut, userId, date) => {
        if (this.getShiftTypeIdsForUser(this.$store.state.org.employees[userId]).includes(shortcut.id)) {
          const activities = [{
            flags: [],
            comments: '',
            endTime: this.shiftTypesById[shortcut.id].endTime,
            internalComments: '',
            obligatory: shortcut.obligatory,
            onCall: false,
            overtime: false,
            settings: {
              sitter: {
                reason: '',
                room: ''
              }
            },
            sitter: false,
            startTime: this.shiftTypesById[shortcut.id].startTime,
            type: 'shift',
            typeId: shortcut.id
          }];
          this.dispatch('scheduleTemplate/updateScheduleTemplate', {
            profileId: this.$store.state.org.employees[userId].id,
            offset: _.find(this.headers, (h) => h.field === moment(date).toDate().getTime()).offset,
            activities
          }).then(() => {
            this.$store.commit('scheduleTemplate/update_activities', {
              date,
              deptId: this.department.id,
              userId,
              activities,
              orgState: this.$store.state.org
            });
            showStatus({
              text: this.$t('descriptions.shiftSaveSuccess')
            });
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
            });
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.shiftSaveFail'),
              type: 'error',
              data
            });
          });
        }
      };
      for (let shiftId in this.shiftTypes) {
        shortcut = _.get(this.shiftTypes, [shiftId, 'styles', 'web', 'keyboardShortcut'], '');
        modifier = _.trim(_.get(this.shiftTypes, [shiftId, 'styles', 'web', 'keyboardModifier'], '') || '');
        if (shortcut) {
          if (modifier) {
            shortcut = `${modifier}+${shortcut}`;
          }
          actions[shortcut] = {
            id: this.shiftTypes[shiftId].id,
            obligatory: false,
            action
          };
          actions[`shift+${shortcut}`] = {
            id: this.shiftTypes[shiftId].id,
            obligatory: true,
            action
          };
        }
      }
      return actions;
    },
    gridOverlayStyle () {
      return {
        display: 'none'
      };
    },
    gridOverlayPath () {
      return '';
    },
    gridStyle () {
      // The grid has two parent containers and each one has 12px padding and here
      // we add an extra 6px buffer.
      let padding = 0;
      if (this.$vuetify.breakpoint.smAndDown) {
        // smAndDown triggers the navigation bar to the bottom of the screen therefore
        // the offset here is the height of that navigation bar.
        padding += 60;
      } else {
        padding += 50;
      }

      return {
        height: `${this.gridHeight - padding}px`,
        opacity: 1.0
      };
    },
    gridInitialized () {
      return !!this.grid;
    },
    gridMaxWidth () {
      const MARGIN = 40; // Compensate left and right margin of the grid.
      const widthForScheduleCols = this.department.numOfWeeksPerSchedule * 7 * this.COL_WIDTH_SCHEDULE; // Hospital opens 7 days a week.
      const maxWidthForGrid = this.COL_WIDTH_USER + widthForScheduleCols + MARGIN;

      return {
        maxWidth: maxWidthForGrid + 'px'
      };
    },
    needsReview () {
      return _.indexOf([SCHEDULE_STATES.PENDING_POST_APPROVAL, SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL], this.state) >= 0;
    },
    scheduleAllowsShiftDeletion () {
      return true;
    },
    hasSelectedShiftTypes () {
      return this.selectedShiftTypes.length > 0;
    },
    selectedShiftTypes () {
      return _.get(this.filters, 'shift.types', []);
    },
    sidePanels () {
      const helpPanel = [{
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      }];

      if (this.showHelp) {
        return helpPanel;
      } else {
        return [...this.persistentPanels, ...this.panels];
      }
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    state () {
      return this.schedule ? this.schedule.state : '';
    },
    summaryRowStyle () {
      return {
        'left': this.$vuetify.breakpoint.smAndDown ? '0px' : '100px'
      };
    },
    headers () {
      const headers = _.cloneDeep(_.get(this.$store.state.scheduleTemplate.templates[this.department.id], ['headers'], []));
      const filteredHeaders = [];
      for (let i = 0, len = headers.length; i < len; i++) {
        if (headers[i].type === 'user') {
          filteredHeaders.push({
            columnType: 'user',
            headerType: 'user',
            ignoreHoliday: true,
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            renderNameAsLink: true,
            showAvatar: true,
            showSettings: false,
            status: (user) => {
              let status = user.jobTypeName ? user.jobTypeName : '';
              status += user.jobStatusShortCode ? ` ${user.jobStatusShortCode}` : '';
              if (user.departmentId !== this.department.id) {
                status += ` │ ${user.departmentName}`;
              }
              return status;
            },
            ...headers[i]
          });
        } else if (headers[i].type === 'schedule') {
          filteredHeaders.push({
            columnType: 'schedule',
            dayOfWeekOnly: true,
            headerType: 'schedule',
            ignoreHoliday: true,
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            ...headers[i]
          });
        } else if (headers[i].type === 'week') {
          filteredHeaders.push({
            columnType: 'week',
            headerType: 'week',
            maxWidth: 40,
            minWidth: 40,
            style: {
              bgColor: '#F5F5F5'
            },
            width: 40,
            ...headers[i]
          });
        }
      }
      return filteredHeaders;
    },
    todaysColumnIndex () {
      return _.findIndex(
        this.headers,
        (h) => {
          return h.columnType === 'schedule' && moment(h.caption).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
        }
      );
    },
    footers () {
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        return [];
      } else {
        const imbalance = this.$store.getters['scheduleTemplate/getValidator'](this.department.id, 'ImbalanceValidator');
        if (!imbalance) {
          return [];
        }
        const style = {
          bgColor: '#F5F5F5',
          color: '#616161',
          errorBgColor: '#FDE3E3',
          errorColor: '#E74C3C'
        };
        const hasError = (jobId, shiftId) => {
          return function (count) {
            return false;
          };
        };
        const data = imbalance.state.data;
        const footers = [];

        const jobTypes = this.getSelectedJobTypes();
        if (!this.hasSelectedShiftTypes) {
          for (let jt of jobTypes) {
            for (let i = 0, len = jt.associatedShiftTypes.length; i < len; i++) {
              const shiftId = jt.associatedShiftTypes[i];
              if (_.has(data, [jt.id, shiftId, 'dates'])) {
                footers.push(
                  {
                    type: 'balance',
                    label: `${jt.name} (${this.shiftTypesById[shiftId].name})`,
                    style,
                    hasError: hasError(jt.id, shiftId),
                    data: data[jt.id][shiftId].dates
                  }
                );
              }
            }
          }
        } else {
          for (let jt of jobTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (jt.associatedShiftTypes.includes(shiftTypeId)) {
                if (_.has(data, [jt.id, shiftTypeId, 'dates'])) {
                  footers.push(
                    {
                      type: 'balance',
                      label: `${jt.name} (${this.shiftTypes[shiftTypeId].name})`,
                      hasError: hasError(jt.id, shiftTypeId),
                      style,
                      data: data[jt.id][shiftTypeId].dates
                    }
                  );
                }
              }
            }
          }
        }
        return footers;
      }
    },
    allRecords () {
      return _.get(this.$store.state.scheduleTemplate.templates[this.department.id], ['records'], []);
    },
    readOnly () {
      return !this.$can('edit', 'template');
    },
    records () {
      const records = _.get(this.$store.state.scheduleTemplate.templates[this.department.id], ['records'], []);
      const filteredRecords = [];
      const shiftFilterEnabled = _.get(this.filters, 'shift.enabled', true);
      for (let r of records) {
        const row = {
          user: r.user
        };
        let activityCount = 0;
        for (let d in r) {
          if (d !== 'user') {
            row[d] = {
              activities: [],
              request: r[d].request
            };

            for (let a of r[d].activities) {
              switch (a.type) {
                case 'shift':
                  if (shiftFilterEnabled && shiftMatchesFilters(a, this.filters, this.$store)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
              }
            }
          }
        }
        if (!this.hasActivityFilters || activityCount > 0) {
          filteredRecords.push(row);
        }
      }
      return filteredRecords;
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobStatusById () {
      return this.$store.state.org.jobStatus.reduce(
        (obj, jobStatus) => {
          obj[jobStatus.id] = jobStatus;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    allUserRecordMap () {
      return _.get(this.$store.state.scheduleTemplate.templates[this.department.id], ['userRecordMap'], {});
    },
    userRecordMap () {
      const userRecordMap = {};
      for (let i = 0, count = this.records.length; i < count; i++) {
        userRecordMap[this.records[i].user.userId] = i;
      }
      return userRecordMap;
    },
    ...mapState(['sidePanelOpened'])
  },
  watch: {
    department () {
      if (this.department.id !== this.departmentId) {
        this.departmentId = this.department.id;
        this.applyTemplateAutomatically = _.get(this.department, 'scheduleRules.applyTemplateAutomatically', true);
        this.redraw();
      }
    },
    filters: {
      handler () {
        if (this.grid) {
          this.filterGrid(true);
        }
      },
      deep: true
    },
    gridLanguage () {
      if (this.grid) {
        this.grid.language = this.gridLanguage;
        this.updateGridEmptyMessage();
      }
    },
    'records' () {
      if (this.grid) {
        const self = this;
        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
      }
    },
    selectedJobTypeTab () {
      this.refreshPanels();
    },
    sidePanelOpened (newValue, oldValue) {
      // This is the global side panel
      if (newValue !== oldValue) {
        this.redrawGrid(true);
      }
    },
    staffFilter: _.debounce(function () {
      this.filterGrid(false);
    }, SEARCH_INPUT_DEBOUNCE),
    openedPanelName (openedPanelName) {
      if (openedPanelName) {
        switch (openedPanelName) {
          case 'filters':
            this.closeAllPanels();
            this.openFiltersPanel();
            break;
        }
      } else {
        this.closeAllPanels();
        this.closeAllPersistentPanels();
      }
    }
  },
  mounted: function () {
    this.departmentId = this.department.id;
    this.retrieveTemplate();
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'schedule_page', callback: this.onWsUpdate },
      { root: true }
    );
  },
  beforeDestroy: function () {
    this.unbindShortcuts();
    this.destroyGrid();
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(
      'remove_ws_update_callback',
      'schedule_page',
      { root: true }
    );
    next();
  },
  methods: {
    bindShortcuts () {
      this.unbindShortcuts();
      if (!this.$can('edit', 'template')) {
        return;
      }
      for (let shortcutKey in this.shortcutActions) {
        this.mousetrap.bind(shortcutKey, () => {
          if (this.grid) {
            const cell = this.grid.selection.select;
            if (cell.col !== 0 && cell.row !== 0) {
              const user = this.getCellUser(cell);
              const date = this.getCellCol(cell);
              if (moment.isMoment(date)) {
                this.shortcutActions[shortcutKey].action(this.shortcutActions[shortcutKey], user.userId, date);
              }
            }
          }
        });
      }
      this.mousetrap.bind(['backspace', 'del'], () => {
        if (this.grid) {
          const cell = this.grid.selection.select;
          const value = this.getCellValue(cell);
          if (value) {
            const date = this.getCellCol(cell);
            if (!moment.isMoment(date)) {
              return;
            }
            const user = this.getCellUser(cell);
            // Only allow delete shortcut when there is one activity.
            const activities = _.get(this.records, [this.userRecordMap[user.userId], date.valueOf(), 'activities'], []);
            if (activities.length !== 1) {
              return;
            }
            const activity = activities[0];
            switch (activity.type) {
              case 'shift':
                if (this.scheduleAllowsShiftDeletion) {
                  this.dispatch('scheduleTemplate/updateScheduleTemplate', {
                    profileId: this.$store.state.org.employees[user.userId].id,
                    offset: _.find(this.headers, (h) => h.field === moment(date).toDate().getTime()).offset,
                    activities: []
                  }).then(() => {
                    this.$store.commit('scheduleTemplate/update_activities', {
                      date,
                      deptId: this.department.id,
                      userId: user.userId,
                      activities: [],
                      orgState: this.$store.state.org
                    });
                    showStatus({
                      text: this.$t('descriptions.shiftDeleteSuccess')
                    });
                    this.$nextTick(() => {
                      this.redrawGrid();
                      this.refreshPanels();
                    });
                  }).catch(error => {
                    const data = {
                      error: _.get(error, 'response.data')
                    };

                    showStatus({
                      text: this.$t('descriptions.shiftDeleteFail'),
                      type: 'error',
                      data
                    });
                  });
                }
                break;
            }
          }
        }
      });

      this.mousetrap.bind(['up', 'right', 'down', 'left'], (e, key) => {
        if (this.grid) {
          this.grid.keyDownMove(e);
        }
      });

      this.mousetrap.bind(['enter'], (e, key) => {
        if (this.grid && this.grid.hasFocusGrid()) {
          this.showPanelOnCellSelect = true;
          this.showCellDetails(this.grid.selection.select);
        }
      });

      this.mousetrap.bindGlobal(['mod+/'], (e, key) => {
        this.toggleHelpPanel();
      });
    },
    unbindShortcuts () {
      this.mousetrap.reset();
    },
    destroyGrid () {
      if (this.grid) {
        this.grid.dispose();
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    filterGrid (focusGrid) {
      if (this.grid) {
        const filter = this.getGridFilter();
        let newSelectedRow, field;
        // If a cell is selected by the user check if that cell is still on the grid after the filter
        // and select the cell at it's new index. If the cell is no longer in the grid then reset the
        // focus to the top left cell vertex (0, 0) which corresponds to the first cell of the 'user' column.
        // When we need to select the header row we set then row index to -1 because the focusGridCell
        // function adds 1 to the value we pass in.
        if (this.selectedCell.col) {
          if (moment.isMoment(this.selectedCell.col)) {
            field = this.selectedCell.col.valueOf();
          } else {
            field = this.selectedCell.col;
          }
          if (this.selectedCell.user) {
            const newRow = this.getCellRowByUser(this.selectedCell.user);
            if (newRow >= 0) {
              newSelectedRow = newRow;
            } else {
              newSelectedRow = -1;
              field = 'user';
            }
          } else {
            newSelectedRow = -1;
          }
        }

        this.gridfilterDataSource.filter = filter;
        this.updateGridEmptyMessage();
        this.redrawGrid();
        // Redraw a second time. Redrawing once does not render the grid correctly. Blank rows are being
        // inserted. This behavior can also be observed in the cheetah-grid demo page.
        this.redrawGrid(focusGrid);
        if (field) {
          this.grid.selectGridCell(field, newSelectedRow, focusGrid);
        }
      }
    },
    findShiftById (userId, id) {
      const row = _.get(this.allRecords, [this.allUserRecordMap[userId]], {});
      for (let prop in row) {
        const timestamp = moment(parseInt(prop));
        if (!timestamp.isValid()) {
          continue;
        }

        const shift = _.find(_.get(row[prop], 'activities', []), (a) => {
          return a.type === 'shift' && a.id === id;
        });
        if (shift) {
          return shift;
        }
      }
      return null;
    },
    focusGrid () {
      if (this.grid) {
        this.grid.focus();
      }
    },
    /**
     * Gets the shift date for a cell
     * @param {object} cell cheetah-gird cell
     * @returns {Date}
     */
    getCellCol (cell) {
      const col = this.grid.header[cell.col];
      if (col.type === 'schedule') {
        return moment(col.caption);
      } else {
        return col.field;
      }
    },
    /**
     * Gets the user associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellUser (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row['user'];
    },
    getCellRowByUser (userId) {
      // This only looks in the set of filtered rows and returns -1 when the row is not found
      const filter = this.getGridFilter();
      let newRow = 0;
      for (let i = 0, len = this.records.length; i < len; i++) {
        if (filter ? filter(this.records[i]) : true) {
          if (this.records[i].user.userId === userId) {
            return newRow;
          }
          newRow++;
        }
      }
      return -1;
    },
    /**
     * Gets the value associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellValue (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      const col = this.grid.header[cell.col];
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row[col.field];
    },
    getGridFilter () {
      const filters = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        let filterForCharge = false;
        const associatedJobTypes = this.getSelectedJobTypes().map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue);
          return accumulator;
        }, []);
        for (let i = 0, count = associatedJobTypes.length; i < count; i++) {
          if (this.jobTypesById[associatedJobTypes[i]] && _.get(this.jobTypesById[associatedJobTypes[i]], 'settings.isChargeNurse', false)) {
            filterForCharge = true;
            break;
          }
        }
        filters.push((record) => {
          return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0 ||
            (filterForCharge && record.user.charge);
        });
      }
      const status = _.get(this.filters, 'user.status', []);
      if (status.length > 0) {
        filters.push((record) => {
          return status.includes(record.user.jobStatusId);
        });
      }
      const shiftTypes = _.get(this.filters, 'user.shiftTypes', []);
      if (shiftTypes.length > 0) {
        filters.push((record) => {
          return shiftTypes.includes(record.user.shiftTypeId);
        });
      }
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filters.push((record) => {
          return userMatchesText(record.user, text);
        });
      }

      const filtersCount = filters.length;
      let filter = null;
      if (filtersCount > 0) {
        filter = (record) => {
          if (_.isEmpty(record)) {
            return false;
          }
          let match = true;
          for (let i = 0; i < filtersCount; i++) {
            if (_.isFunction(filters[i])) {
              match &= filters[i](record);
            } else {
              match &= _.get(record, filters[i].key, null) === filters[i].value;
            }
          }
          return match;
        };
      }
      return filter;
    },
    getJobTypeTabIndex (jobId) {
      for (let i = 0, count = this.groupedJobTypes.length; i < count; i++) {
        const matchedJobType = _.find(this.groupedJobTypes[i], (jobType) => {
          return jobType.id == jobId;
        });
        if (matchedJobType) {
          return i;
        }
      }
      return -1;
    },
    getSelectedJobTypes () {
      return this.groupedJobTypes[this.selectedJobTypeTab];
    },
    getSelectedAssociatedJobTypeIds () {
      return _.uniq(this.groupedJobTypes[this.selectedJobTypeTab].reduce((jobTypes, jt) => {
        jobTypes.push(...jt.associatedJobTypes);
        return jobTypes;
      }, []));
    },
    getSelectedJobTypeIds () {
      if (this.groupedJobTypes[this.selectedJobTypeTab]) {
        return this.groupedJobTypes[this.selectedJobTypeTab].map((jt) => jt.id);
      }
      return [];
    },
    getShiftTypeIdsForUser (user) {
      const jobType = this.jobTypesById[user.jobTypeId];
      const shiftTypes = _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      });

      if (user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniq(shiftTypes.map((st) => st.id));
    },
    jobTypeById (id, prop) {
      return this.$store.getters['org/getJobTypeById'](id, prop);
    },
    initGrid () {
      let self = this;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
        font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
      });

      const theme = cheetahGrid.themes.SCHEDULER.extends({
        holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
      });

      this.updateGridHeight();

      const validators = this.$store.getters['scheduleTemplate/getValidator'](this.department.id);
      const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
      const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
      const sameColumns = (column1, column2) => {
        if (moment.isMoment(column1) && moment.isMoment(column2)) {
          return column1.isSame(column2);
        } else {
          return column1 != column2;
        }
      };
      this.$nextTick(() => {
        this.grid = new cheetahGrid.ScheduleGrid({
          parentElement: this.$refs.gridParent,
          onBeforeSelect: (cell) => {
            return new Promise((resolve, reject) => {
              const user = this.getCellUser(cell);
              const col = this.getCellCol(cell);
              const userId = user ? user.userId : null;
              if (this.hasChanges && (!sameColumns(col, this.selectedCell.col) || userId != this.selectedCell.user)) {
                this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {
                  reject(new Error('Unsaved changes'));
                }).catch(() => {
                  this.hasChanges = false;
                  this.$store.commit('unmark_all_unsaved_changes');
                  resolve();
                });
              } else {
                resolve();
              }
            });
          },
          onResize: () => {
            this.redrawGrid();
          },
          emptyMessage: this.emptyMessage,
          header: this.headers,
          footer: this.footers,
          firstDayOfWeek: 0,
          frozenColCount: this.FROZEN_COLUMN_COUNT,
          language: this.gridLanguage,
          showStaffPerDay: true,
          showWeeklyBalance: false,
          symbols: {
            borderColor (cell) {
              return null;
            },
            bgColor (cell) {
              return null;
            },
            shift (shift) {
              if (!self.shiftTypes[shift.typeId]) {
                return {};
              }
              const style = _.cloneDeep(self.shiftTypes[shift.typeId].styles).web;
              const shiftType = self.shiftTypes[shift.typeId];
              let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
              let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
              if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                let start = startTime.substring(0, 5);
                let end = endTime.substring(0, 5);
                if (shift.obligatory) {
                  start += '*';
                  end += '  ';
                }
                style.symbolType = 'time';
                style.symbolValue = { start, end };
              }

              if (shift.obligatory) {
                if (_.get(style, 'symbolType', '') === 'text') {
                  style.symbolValue += '*';
                }
              }

              let bgColor = null;
              if (shift.onCall) {
                bgColor = onCallBgColor;
              } else if (!isProductiveShift(shift, self.shiftFlags)) {
                bgColor = canceledBgColor;
              }

              return {
                ...style,
                bgColor,
                modified: wasShiftModifiedByManagement(shift, self.$store.state),
                differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
              };
            },
            week (user, week) {
              let style = {};
              if (validators['OvertimeValidator']) {
                style = validators['OvertimeValidator'].weekStyle(user, week);
              }
              return {
                bgColor: '#F5F5F5',
                ...style
              };
            }
          },
          theme
        });

        setTimeout(() => {
          if (this.todaysColumnIndex >= 0) {
            this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
          }
        }, 0);

        const { CLICK_CELL, SCROLL, SELECTED_CELL, CLICK_USER_NAME, CLICK_USER_SETTINGS } = cheetahGrid.ScheduleGrid.EVENT_TYPE;
        this.grid.listen(CLICK_CELL, (cell) => {
          // At this moment we do not allow clicking on the user column therefore ignore the click
          if (cell.col > 0) {
            this.showPanelOnCellSelect = true;
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SELECTED_CELL, (cell) => {
          const user = this.getCellUser(cell);
          const col = this.getCellCol(cell);
          const userId = user ? user.userId : null;
          if (cell.selected && !this.hasChanges && (!sameColumns(col, this.selectedCell.col) || userId != this.selectedCell.user)) {
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SCROLL, (e) => {
          this.gridState.canScrollLeft = this.grid.canScrollLeft();
          this.gridState.canScrollRight = this.grid.canScrollRight();
          this.gridState.scrollLeft = e.event.target.scrollLeft;
        });

        this.grid.listen(CLICK_USER_SETTINGS, (cell) => {
          const user = this.getCellUser(cell);
          if (this.hasChanges) {
            this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
              this.hasChanges = false;
              this.$store.commit('unmark_all_unsaved_changes');
              this.showNurseDetails(user.userId);
              setTimeout(() => {
                this.grid.setSelectedUserSettings(cell.row);
              }, 0);
            });
          } else {
            this.showNurseDetails(user.userId);
            setTimeout(() => {
              this.grid.setSelectedUserSettings(cell.row);
            }, 0);
          }
        });

        this.grid.listen(CLICK_USER_NAME, (cell) => {
          this.openNurseDetails(this.getCellUser(cell));
        });

        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
        this.gridState.canScrollLeft = this.grid.canScrollLeft();
        this.gridState.canScrollRight = this.grid.canScrollRight();

        this.bindShortcuts();
      });
    },
    moment,
    onJobTypeChange (idx) {
      if (this.hasChanges && !this.isSelectedCellInViewAfterFilter({ 'selectedJobTypeTab': idx })) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.closeLastPanel();
          this.selectedJobTypeTab = idx;
          this.filterGrid(true);
        });
      } else {
        this.selectedJobTypeTab = idx;
        this.filterGrid(true);
      }
    },
    redrawGrid (focusGrid) {
      if (this.grid) {
        this.updateGridHeight();
        // After updating the grid height we need to wait for vue to finished re-evaluating
        // any grid height dependencies so that cheetah-grid can calculate the correct width
        // and height for the grid.
        this.$nextTick(() => {
          this.grid.header = this.headers;
          this.grid.footer = this.footers;
          this.grid.resize();
          if (focusGrid) {
            this.focusGrid();
          }
          setTimeout(() => {
            if (this.todaysColumnIndex >= 0) {
              this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
            }
          }, 0);
        });
      }
    },
    retrieveTemplate () {
      this.retrievingSchedule = true;
      this.openedPanelName = undefined;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      this.dispatch('scheduleTemplate/retrieveTemplate', this.department.id).then(() => {
        // DO NOT move the code below to 'finally' block like other places.
        // We need to immediately hide the skeleton loader upon receiving response
        // from the web API and render the parent container for the grid. Otherwise
        // the grid won't get initialized.
        this.retrievingSchedule = false;
        // Wait for the next tick to ensure the skeleton loader is hidden and the
        // parent container for the grid is rendered.
        this.$nextTick(() => {
          this.initGrid();
        });
      }).catch(error => {
        Sentry.captureException(error);
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.scheduleRetrievalFail'),
          type: 'error',
          data
        });
      });
    },
    getDailySummaryShiftsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          const activities = _.get(this.records[i], [field, 'activities'], []);
          if (activities.length > 0) {
            summaryData.push({
              date,
              details: this.records[i][field],
              user: this.records[i].user
            });
          }
        }
      }
      return summaryData;
    },
    /**
     * Shows cell details
     * @param {object} cell cheetah-grid cell
     * @param {int} cell.row Cell row index
     * @param {int} cell.col Cell column index
     */
    showCellDetails (cell) {
      if (cell.col > 0) {
        if (cell.row > 0) {
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = this.getCellUser(cell).userId;
          if (this.showPanelOnCellSelect && this.headers[cell.col]) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              this.showActivityDetails({ cell }, true);
            }
          }
        } else if (cell.row === 0) {
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = null;
          if (this.showPanelOnCellSelect) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              this.showDailySummary(this.getCellCol(cell), true);
            }
          }
        }
      } else {
        this.selectedCell.col = null;
        this.selectedCell.user = null;
        if (this.showPanelOnCellSelect) {
          this.showSelectCellPanel();
        }
      }
    },
    showActivityDetails (template, resetPanels) {
      this.showPanel(
        this.getActivityDetailsPanelData,
        [template],
        resetPanels
      );
    },
    getActivityDetailsPanelData (template) {
      let date, details, user;
      if (template.cell) {
        try {
          date = this.getCellCol(template.cell);
          details = this.getCellValue(template.cell);
          user = this.getCellUser(template.cell);
        } catch {
          // An error can happen if the cell is no on the grid due to filtering, in taht case
          // we display the empty cell panel.
          return {
            component: EmptyCell,
            props: {
              date
            },
            events: {}
          };
        }
      } else {
        date = template.date;
        details = template.details;
        user = template.user;
      }

      const activities = _.cloneDeep(_.get(details, 'activities', []));
      const shifts = [];
      const events = [];
      for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
        switch (activities[i].type) {
          case 'shift':
            shifts.push(activities[i]);
            break;
          case 'event':
            events.push(activities[i]);
            break;
        }
      }

      const shiftCount = shifts.length;
      const eventCount = events.length;
      if (shiftCount === 0 && eventCount === 0) {
        return {
          component: ActivitySelection,
          props: {
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'add-shift': (shift) => {
              this.showShiftDetails({
                date,
                selectedShiftIndex: shifts.length,
                shifts: [...shifts, shift],
                user
              }, true);
            }
          }
        };
      } else if (eventCount > 1 || (shiftCount > 0 && eventCount > 0)) {
        return {
          component: ActivityDetails,
          props: {
            date,
            events,
            shifts,
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'show-shifts': (shifts) => {
              this.showShiftDetails({
                date,
                shifts,
                user
              });
            }
          }
        };
      } else if (shiftCount > 0) {
        return this.getShiftDetailsPanelData({
          date,
          shifts,
          user
        });
      }

      return {
        component: EmptyCell,
        props: {
          date
        },
        events: {}
      };
    },
    openNurseDetails (user) {
      this.nurseDetails = user;
    },
    hideNurseDetails () {
      this.nurseDetails = null;
    },
    updateNurseDetails (user) {
      this.refreshPanels(this.panels.length - 1);
    },
    showNurseDetails (userId) {
      this.showPanel(
        this.getNurseDetailsPanelData,
        [userId],
        true
      );
    },
    getNurseDetailsPanelData (userId) {
      return {
        component: NurseDetails,
        props: {
          date: this.selectedSchedule.startOn,
          user: this.$store.state.org.employees[userId]
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            this.grid.setSelectedUserSettings(null);
            return true;
          },
          'destroyed': () => {
            if (this.grid) {
              this.grid.setSelectedUserSettings(null);
            }
          },
          'notes-saved': () => {
            this.refreshPanels(this.panels.length - 1);
          }
        }
      };
    },
    showShiftDetails (data, resetPanels) {
      this.showPanel(
        this.getShiftDetailsPanelData,
        [data],
        resetPanels
      );
    },
    getShiftDetailsPanelData (data) {
      const { date, shifts, user } = data;
      return {
        component: ShiftDetails,
        props: {
          offset: _.find(this.headers, (h) => h.field === moment(date).toDate().getTime()).offset,
          shifts,
          'read-only': this.readOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          },
          'updated': (activities) => {
            this.$store.commit('scheduleTemplate/update_activities', {
              date,
              deptId: this.department.id,
              userId: user.userId,
              activities,
              orgState: this.$store.state.org
            });
            const lastPanel = this.panels.length - 1;
            const params = this.panels[lastPanel].params;
            params[0].shifts = activities;
            this.updatePanel(lastPanel, this.panels[lastPanel].panelDataCallback, params);
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
            });
          },
          'has-changes': (hasChanges) => {
            this.hasChanges = hasChanges;
          },
          'removed': () => {
            this.$store.commit('scheduleTemplate/update_activities', {
              date,
              deptId: this.department.id,
              userId: user.userId,
              activities: [],
              orgState: this.$store.state.org
            });
            this.closeLastPanel();
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
            });
          }
        }
      };
    },
    showDailySummary (date, resetPanels) {
      this.showPanel(this.getDailySummaryPanelData, [date], resetPanels);
    },
    getDailySummaryPanelData (date) {
      return {
        component: DailySummary,
        props: {
          data: this.getDailySummaryShiftsData(date),
          date
        },
        events: {
          'show-shift-details': ({ date, shifts, user }) => {
            this.showShiftDetails({
              date,
              shifts,
              user
            });
          }
        }
      };
    },
    redraw () {
      this.retrievingSchedule = true;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      this.$nextTick(() => {
        this.staffFilter = '';
        this.selectedJobTypeTab = this.ALL_JOB_TYPES;
        this.closeAllPanels();
        this.retrieveTemplate();
      });
    },
    onWsUpdate (eventInfo) {
      // TODO
    },
    showEmptyPanel (date) {
      this.showPanel(() => {
        return {
          component: EmptyCell,
          props: {
            date
          },
          events: {}
        };
      }, [], true);
    },
    showSelectCellPanel () {
      this.showPanel(() => {
        return {
          component: SelectCell,
          props: {},
          events: {}
        };
      }, [], true);
    },
    showPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.panels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePanels(1, this.panels.length);
        this.updatePanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.push(_.cloneDeep(panelData));
      }
    },
    showPersistentPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.persistentPanels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePersistentPanels(1, this.persistentPanels.length);
        this.updatePersistentPanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePersistentPanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.persistentPanels.push(_.cloneDeep(panelData));
      }
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    updatePersistentPanel (panelIdx, panelDataCallback, params) {
      const panel = this.persistentPanels[panelIdx];
      const panelData = this.preparePersistentPanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.persistentPanels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    refreshPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.panels.length;
        for (let i = 0; i < len; i++) {
          this.updatePanel(i, this.panels[i].panelDataCallback, this.panels[i].params);
        }
      }
    },
    refreshPersistentPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.persistentPanels.length;
        for (let i = 0; i < len; i++) {
          this.updatePersistentPanel(i, this.persistentPanels[i].panelDataCallback, this.persistentPanels[i].params);
        }
      }
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPanel();
          }
        } else {
          this.closeLastPanel();
        }
      };
      return panelData;
    },
    preparePersistentPanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPersistentPanel();
          }
        } else {
          this.closeLastPersistentPanel();
        }
      };
      return panelData;
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    closePersistentPanels (start, count) {
      this.persistentPanels.splice(start, count);
    },
    closeAllPanels () {
      this.openedPanelName = undefined;
      this.panels.splice(0, this.panels.length);
    },
    closeAllPersistentPanels () {
      this.openedPanelName = undefined;
      this.persistentPanels.splice(0, this.persistentPanels.length);
    },
    closeLastPanel () {
      this.panels.pop();
    },
    closeLastPersistentPanel () {
      this.persistentPanels.pop();
    },
    toggleHelpPanel () {
      this.showHelp = !this.showHelp;
    },
    updateGrid () {
      if (this.grid) {
        this.grid.footer = this.footers;
        this.grid.header = this.headers;
      }
    },
    updateGridHeight () {
      const grid = document.getElementsByClassName('grid-parent')[0];
      if (grid) {
        const top = grid.getBoundingClientRect().top;
        this.gridTop = top;
        this.gridHeight = window.innerHeight - top;
        this.gridWidth = grid.offsetWidth;
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
      }
    },
    updateGridEmptyMessage () {
      if (this.grid) {
        this.grid.emptyMessage = this.emptyMessage;
      }
    },
    openFiltersPanel () {
      this.showPersistentPanel(() => {
        this.openedPanelName = 'filters';
        return {
          props: {
            eventFilters: _.cloneDeep(_.get(this.filters, 'event', {})),
            shiftFilters: _.cloneDeep(_.get(this.filters, 'shift', {})),
            userFilters: _.cloneDeep(_.get(this.filters, 'user', {}))
          },
          component: Filters,
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            apply: (filters) => {
              this.filters = filters;
              this.refreshPersistentPanels();
            }
          }
        };
      }, [], true);
    },
    setOpenedPanelName (name) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.openedPanelName = name;
        });
      } else {
        this.openedPanelName = name;
      }
    },
    isSelectedCellInViewAfterFilter (criteria) {
      if (this.selectedCell.col) {
        if (this.selectedCell.user) {
          const filters = [];
          const selectedJobTypeTab = criteria['selectedJobTypeTab'] || this.selectedJobTypeTab;
          if (selectedJobTypeTab !== this.ALL_JOB_TYPES) {
            const associatedJobTypes = this.groupedJobTypes[selectedJobTypeTab].map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
              accumulator.push(...currentValue);
              return accumulator;
            }, []);
            filters.push((record) => {
              return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0;
            });
          }

          const staffFilter = criteria['staffFilter'] || this.staffFilter;
          if (staffFilter) {
            const text = staffFilter.toLowerCase();
            filters.push((record) => {
              return userMatchesText(record.user, text);
            });
          }

          const filtersCount = filters.length;
          let filter = null;
          if (filtersCount > 0) {
            filter = (record) => {
              let match = true;
              for (let i = 0; i < filtersCount; i++) {
                if (_.isFunction(filters[i])) {
                  match &= filters[i](record);
                } else {
                  match &= _.get(record, filters[i].key, null) === filters[i].value;
                }
              }
              return match;
            };
          }
          for (let i = 0, len = this.records.length; i < len; i++) {
            if (filter ? filter(this.records[i]) : true) {
              if (this.records[i].user.userId === this.selectedCell.user) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    updateTemplateAutomation () {
      if (!this.updatingSettings) {
        this.updatingSettings = true;
        this.dispatch('org/updateDepartmentScheduleRules', {
          deptId: this.department.id,
          scheduleRules: [{
            path: ['apply_template_automatically'],
            value: this.applyTemplateAutomatically
          }]
        }).then(() => {
          showStatus({
            text: this.$t('descriptions.settingsUpdateSuccess')
          });
        }).catch(error => {
          this.applyTemplateAutomatically = !this.applyTemplateAutomatically;
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.settingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.updatingSettings = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.container.schedule {
  height: 100%;

  .cell-details {
    transition: width 0.3s;
  }

  .v-tabs {
    .v-tabs-bar {
      border-color: #eeeeee;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      margin-right: 10px;
    }
  }

  .grid-action-bar {
    background-color: white;
    border-color: map-get($grey, 'lighten-3');
    border-style: solid;
    border-width: 1px 1px 0 1px;
    margin-left: 0px;
    margin-right: 10px;
    min-height: 60px;

    .schedule-dropdown {
      border: 1px solid map-get($grey, 'lighten-2');
      .v-btn__content {
        justify-content: left;
        i {
          position: absolute;
          right: 8px;
        }
      }
    }
  }

  .grid-empty-container {
    text-align: left;
  }

  .search-staff {
    position: absolute;
    z-index: 1;

    .v-icon {
      margin-top: 0px !important;
    }
  }
  .shift-type {
    padding: 0px 5px !important;
    min-width: 34px !important;
    width: 34px !important;
  }
}
</style>
