import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '@/utils/ui';

export function calculateScheduleRangeForDate (date, schedulePeriods, settings) {
  const startOfFutureSchedule = moment(schedulePeriods[0].endOn).add(1, 'd');
  let weekDay = startOfFutureSchedule.weekday();
  if (date >= startOfFutureSchedule.format(DATE_FORMAT)) {
    const initialStartDate = startOfFutureSchedule.startOf('w');
    const thisWeek = moment(date).startOf('w');
    const period = settings.scheduling.period;
    const weekDiff = thisWeek.diff(initialStartDate, 'w');
    const weeksRemainder = weekDiff % (period / 7);
    const startOfNextSchedule = initialStartDate.clone().add(weekDiff - weeksRemainder, 'w').weekday(weekDay);
    return {
      endOn: startOfNextSchedule.clone().add(period - 1, 'd').format(DATE_FORMAT),
      id: null,
      startOn: startOfNextSchedule.format(DATE_FORMAT)
    };
  } else {
    const hospitalInitialStartDate = settings.scheduling.initialStartDate;
    const startOfInitialSchedule = moment(hospitalInitialStartDate);
    if (date >= hospitalInitialStartDate) {
      const initialStartDate = startOfInitialSchedule.startOf('w');
      const thisWeek = moment(date).startOf('w');
      const period = settings.scheduling.period;
      const weekDiff = thisWeek.diff(initialStartDate, 'w');
      const weeksRemainder = weekDiff % (period / 7);
      const startOfNextSchedule = initialStartDate.clone().add(weekDiff - weeksRemainder, 'w').weekday(weekDay);
      return {
        endOn: startOfNextSchedule.clone().add(period - 1, 'd').format(DATE_FORMAT),
        id: null,
        startOn: startOfNextSchedule.format(DATE_FORMAT)
      };
    } else {
      const endOfPreviousInitialSchedule = startOfInitialSchedule.clone().subtract(1, 'd');
      weekDay = endOfPreviousInitialSchedule.weekday();
      const initialEndDate = endOfPreviousInitialSchedule.endOf('w');
      const thisWeek = moment(date).endOf('w');
      const period = settings.scheduling.period;
      const weekDiff = initialEndDate.diff(thisWeek, 'w');
      const scheduleCount = Math.floor(weekDiff / (period / 7));
      const endOfPastSchedule = initialEndDate.clone().subtract(scheduleCount * (period / 7), 'w').weekday(weekDay);
      return {
        endOn: endOfPastSchedule.format(DATE_FORMAT),
        id: null,
        startOn: endOfPastSchedule.clone().subtract(period - 1, 'd').format(DATE_FORMAT)
      };
    }
  }
}
/**
 * Calculates the start of the week for a date
 * @param {string} date Date string
 * @retun {moment}
 */
export function calculateStartOfWeek (dateString, startOfWeekDay = 'Sunday') {
  let date = moment(dateString);
  const startOfWeek = moment().day(startOfWeekDay).day();
  const weekday = date.day();
  if (weekday > startOfWeek) {
    date.subtract(weekday - startOfWeek, 'd');
  } else if (weekday < startOfWeek) {
    date.subtract((6 - startOfWeek) + (weekday + 1), 'd');
  }

  return date;
}

/**
 * Calculates the shift hours
 * @param {object} shift Shift to calculate hours
 * @param {object} shiftType Shift type
 * @retun {moment}
 */
export function calculateShiftHours (shift, shiftType) {
  let seconds = moment(shiftType.endTime, 'HH:mm:ss').diff(moment(shiftType.startTime, 'HH:mm:ss'), 'seconds');
  if (seconds < 0) {
    seconds += 86400;
  }
  let hours = seconds / 3600;
  if (shift.startTime || shift.endTime) {
    const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
    const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
    let seconds = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'seconds');
    if (seconds < 0) {
      seconds += 86400;
    }
    hours = seconds / 3600;
  }

  return hours;
}

/**
 * Filters a list of data
 * @param {Array} data Data to filter
 * @param {Array} filters Data filters
 * @returns {Object} List of filtered data
 */
export function filterData (data, filters) {
  let filteredData = [];

  for (let i = 0, count = data.length; i < count; i++) {
    if (matchesFilters(data[i], filters)) {
      filteredData.push(data[i]);
    }
  }

  return filteredData;
}

/**
 * Formats text with placeholders
 * @param {string} text Text to format with placeholders
 * @param {object} instance Object containing data for placeholders
 * @returns
 */
export function formatText (text, instance, ignoreJobTitle) {
  let formattedText = text;
  const matches = (text.match(/\{(.*?)\}/g) || []).reduce((keyedMatches, match) => {
    keyedMatches[_.trim(match, '{}')] = match;
    return keyedMatches;
  }, {});

  if (!_.isEmpty(matches)) {
    for (let match in matches) {
      let replacement = _.get(instance, match, '');
      if (replacement) {
        if (['alias', 'assignee.alias'].includes(match)) {
          replacement = `(${replacement})`;
        } else if (['type.startTime', 'type.endTime'].includes(match)) {
          replacement = moment(`2020-01-01 ${replacement}`).format('hA');
        } else if (!ignoreJobTitle && ['jobTypeName', 'assignee.jobTypeName'].includes(match)) {
          const matchJobType = match.replace('jobTypeName', 'jobTitle');
          const jobTitle = _.get(instance, matchJobType, '');
          if (jobTitle) {
            replacement = jobTitle;
          }
        }
      }
      formattedText = formattedText.replace(new RegExp(matches[match], 'g'), replacement);
    }
  }

  return formattedText;
}

/**
 * Gets the index of an activity within a list of activities.
 * @param {Object} activity Activity to search for
 * @param {Object} activities List of activities
 * @returns {int} Array index of the activity. -1 if the activity is not found.
 */
export function getActivityIndex (activity, activities) {
  return _.findIndex(activities, (a) => a.id === activity.id);
}

export function getDefaultFilters () {
  const filters = {
    event: {
      enabled: true,
      types: []
    },
    shift: {
      enabled: true,
      flags: [],
      flagsMode: 'contains',
      flagsOp: 'or',
      types: [],
      mode: 'all'
    },
    user: {
      status: [],
      shiftTypes: []
    }
  };
  return filters;
}

/**
 * Gets the duration between a start and end time in "{x} h {y} m" format.
 * @param {string} startTime Start time in HH:mm:ss format
 * @param {string} endTime End time in HH:mm:ss format
 * @returns {string}
 */
export function getDuration (startTime, endTime) {
  const duration = [];
  let minutes = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'minutes');
  if (endTime < startTime) {
    minutes = moment(endTime, 'HH:mm:ss').add(1, 'day').diff(moment(startTime, 'HH:mm:ss'), 'minutes');
  }
  const hours = Math.floor(minutes / 60);
  if (hours > 0) {
    duration.push(`${hours} h`);
    const remainder = minutes - (hours * 60);
    if (remainder > 0) {
      duration.push(`${remainder} m`);
    }
  } else {
    duration.push(`${minutes} m`);
  }

  return duration.join(' ');
}

export function getCensusDefaultModel (department) {
  return {
    acuity1: null,
    acuity2: null,
    acuity3: null,
    acuity4: null,
    acuity5: null,
    acuityStaff: null,
    censusBySpecialty: department.settings.censusBySpecialty,
    dayOffset: 0,
    description: '',
    extraStaff: [],
    id: null,
    name: '',
    notes: '',
    settings: {
      acuity: department.settings.acuity,
      censusBySpecialty: department.settings.censusBySpecialty,
      showTotalAcuityByClass: department.settings.showTotalAcuityByClass
    },
    staffCount: [],
    time: '',
    total: null,
    dailyScheduleId: null
  };
}

/**
 * Gets the census ratio.
 * @param {Object} census Census instance
 * @param {Array} jobTypes List of joob types
 * @param {Object} dailyScheduleType Daily schedule type instance
 * @returns Object keyed by job type
 */
export function getCensusRatio (census, jobTypes, dailyScheduleType) {
  const ratio = {};
  const censusTotal = getCensusTotal(census);
  const shiftTypes = dailyScheduleType.shiftTypes;
  if (censusTotal > 0) {
    for (let i = 0, count = jobTypes.length; i < count; i++) {
      const jobType = jobTypes[i];
      for (let j = 0, shiftTypeCount = shiftTypes.length; j < shiftTypeCount; j++) {
        if (jobType.associatedShiftTypes.includes(shiftTypes[j])) {
          if (!ratio[jobType.id]) {
            ratio[jobType.id] = 0;
          }
          ratio[jobType.id] += _.get(jobType.staffingMatrix, [shiftTypes[j], censusTotal, 'value'], 0);
        }
      }
    }
  }
  return ratio;
}

/**
 * Gets the census total.
 * @param {Array} census Census instance
 * @returns Number
 */
export function getCensusTotal (census) {
  let total = 0;
  const acuities = _.keys(census.settings.acuity);
  if (census.settings.showTotalAcuityByClass) {
    if (census.total) {
      total += census.total;
    } else {
      for (let a = 0, aCount = acuities.length; a < aCount; a++) {
        total += (census[`acuity${acuities[a]}`] || 0);
      }
    }
  } else if (census.censusBySpecialty) {
    const specialties = census.censusBySpecialty;
    for (let s = 0, sCount = specialties.length; s < sCount; s++) {
      if (specialties[s].showAcuitySubtotal && specialties[s].subtotal) {
        total += specialties[s].subtotal;
      } else if (specialties[s].acuityBreakdown) {
        for (let a = 0, aCount = acuities.length; a < aCount; a++) {
          total += (_.get(specialties[s].acuityBreakdown, [acuities[a], 'value'], null) || 0);
        }
      }
    }
  }

  return total;
}

/**
 * Gets the schedule ID for an event.
 * ! This is only to determine the schedule ID used by the front end.
 * @param {Object} state Schedule store state
 * @param {Object} event Event to determine schedule ID
 */
export function getEventScheduleIds (state, event) {
  const ids = [];
  const eventDates = event.dates.map((d) => moment(d).format(DATE_FORMAT));
  for (let id in state.schedules) {
    if (state.schedules[id].snapshot) {
      continue;
    }
    const deptMatches = state.schedules[id].departmentId === event.departmentId;
    const userInSchedule = _.has(state.grids, [id, 'userRecordMap', event.assigneeId]);
    const endDate = moment(state.schedules[id].endOn);
    const scheduleDates = [];
    let date = moment(state.schedules[id].startOn);
    while (date.isSameOrBefore(endDate)) {
      scheduleDates.push(date.format(DATE_FORMAT));
      date.add(1, 'd');
    }
    if ((deptMatches || userInSchedule) && _.intersection(eventDates, scheduleDates).length > 0) {
      ids.push(id);
    }
  }
  return ids;
}

/**
 * Gets the latest acuity from a list of census.
 * @param {Array} census List of census
 * @returns Object keyed by classification level
 */
export function getLatestAcuity (census) {
  let totals = {};
  for (let i = census.length - 1; i >= 0; i--) {
    const currentCensus = census[i];
    let total = 0;
    totals = {};
    for (let level in currentCensus.settings.acuity) {
      totals[level] = {
        ...currentCensus.settings.acuity[level],
        level,
        value: 0
      };
    }

    const acuities = _.keys(currentCensus.settings.acuity);
    if (currentCensus.settings.showTotalAcuityByClass) {
      for (let a = 0, aCount = acuities.length; a < aCount; a++) {
        totals[acuities[a]].value = (currentCensus[`acuity${acuities[a]}`] || 0);
        total += totals[acuities[a]].value;
      }
    } else if (currentCensus.censusBySpecialty) {
      const specialties = currentCensus.censusBySpecialty;
      for (let s = 0, sCount = specialties.length; s < sCount; s++) {
        if (specialties[s].acuityBreakdown) {
          for (let a = 0, aCount = acuities.length; a < aCount; a++) {
            totals[acuities[a]].value += (_.get(specialties[s].acuityBreakdown, [acuities[a], 'value'], null) || 0);
            total += totals[acuities[a]].value;
          }
        }
      }
    }

    if (total > 0) {
      return totals;
    }
  }

  return totals;
}

/**
 * Gets the latest census from a list of census.
 * @param {Array} list List of census
 * @returns Object Census
 */
export function getLatestCensus (list) {
  // Loop backwards since census should already be sorted. The latest cencus will be the first
  // census we encountered that has data.
  for (let i = list.length - 1; i >= 0; i--) {
    const census = list[i];
    const acuities = _.keys(census.settings.acuity);
    if (census.settings.showTotalAcuityByClass) {
      if (census.total) {
        return census;
      } else {
        for (let a = 0, aCount = acuities.length; a < aCount; a++) {
          if (census[`acuity${acuities[a]}`]) {
            return census;
          }
        }
      }
    }
    if (census.censusBySpecialty) {
      const specialties = census.censusBySpecialty;
      for (let s = 0, sCount = specialties.length; s < sCount; s++) {
        if (specialties[s].showAcuitySubtotal && specialties[s].subtotal) {
          return census;
        } else if (specialties[s].acuityBreakdown) {
          for (let a = 0, aCount = acuities.length; a < aCount; a++) {
            if (_.get(specialties[s].acuityBreakdown, [acuities[a], 'value'], null)) {
              return census;
            }
          }
        }
      }
    }
    if (census.extraStaff.length > 0) {
      return census;
    }
  }

  return list[0];
}

export function getRangeDisplayText (startOn, endOn, store) {
  const startFrom = moment(startOn);
  const endBy = moment(endOn);
  const dateFormatShort = store.getters['org/getDateFormatShort']();
  const dateFormatLong = store.getters['org/getDateFormatLong']();
  let text = '';
  if (endBy.isSame(startFrom, 'year')) {
    text = startFrom.format(dateFormatShort) + ' - ' + endBy.format(dateFormatLong);
  } else {
    // Display year info for both start and end date if the end date is in different year.
    text = startFrom.format(dateFormatLong) + ' - ' + endBy.format(dateFormatLong);
  }

  return text;
}

/**
 * Gets the schedule ID for a shift.
 * ! This is only to determine the schedule ID used by the front end.
 * ! Do not use this value to set the schedule ID for a shift.
 * @param {Object} state Schedule store state
 * @param {Object} shift Shift to determine
 */
export function getScheduleId (state, shift) {
  const shiftDate = moment(shift.payrollDate || shift.date).format(DATE_FORMAT);
  for (let id in state.schedules) {
    if (state.schedules[id].snapshot) {
      continue;
    }
    const deptMatches = state.schedules[id].departmentId === shift.departmentId;
    const userInSchedule = _.has(state.grids, [id, 'userRecordMap', shift.assigneeId]);
    if ((deptMatches || userInSchedule) && shiftDate >= state.schedules[id].startOn && shiftDate <= state.schedules[id].endOn) {
      return id;
    }
  }
  return null;
}

/**
 * Groups a list of data
 * @param {Array} data Data to group
 * @param {Array} groups Data groups
 * @param {Array} orderBy Sort fields
 * @param {Function} callback Function to call for processing the last level. Given list of group data.
 * @returns {Object} Grouped data
 */
export function groupData (data, groups, callback) {
  if (groups.length === 0) {
    return [];
  }
  let groupedData = {};
  const group = groups[0];

  for (let i = 0, count = data.length; i < count; i++) {
    const fieldValue = formatText(group.label, data[i]);
    if (!groupedData[fieldValue]) {
      groupedData[fieldValue] = [];
    }
    groupedData[fieldValue].push(data[i]);
  }

  if (groups[1]) {
    for (let groupName in groupedData) {
      groupedData[groupName] = groupData(groupedData[groupName], groups.slice(1), callback);
    }
  } else {
    for (let groupName in groupedData) {
      groupedData[groupName] = callback ? callback(groupedData[groupName]) : groupedData[groupName];
    }
  }

  return groupedData;
}

/**
 * Checks if an instance matches the given filters
 * @param {Object} instance Object containing the data to match
 * @param {Array} filters Filters to test the match
 * @returns {Boolean} True if the filters match. Otherwise false.
 */
export function matchesFilters (instance, filters) {
  if (filters.length === 0) {
    return true;
  }

  for (let i = 0, orCount = filters.length; i < orCount; i++) {
    let matches = true;
    for (let j = 0, andCount = filters[i].length; j < andCount; j++) {
      const {
        field,
        op,
        value
      } = filters[i][j];
      const instanceValue = _.get(instance, field, null);
      switch (op) {
        case 'contains':
          if (_.isArray(instanceValue)) {
            matches &= _.intersection(instanceValue, _.isArray(value) ? value : [value]).length > 0;
          }
          break;
        case 'not_contains':
          if (_.isArray(instanceValue)) {
            matches &= _.intersection(instanceValue, _.isArray(value) ? value : [value]).length === 0;
          }
          break;
        case 'in':
          matches &= value.includes(_.get(instance, field, null));
          break;
        case '=':
          matches &= _.get(instance, field, null) === value;
          break;
      }
    }
    if (matches) {
      return true;
    }
  }

  return false;
}

export function isCommitmentShift (shift, flags) {
  return (_.isEmpty(shift.flags) || !shift.flags.some(flagId => flags[flagId] && !flags[flagId].commitment));
}

export function isProductiveShift (shift, flags) {
  return (_.isEmpty(shift.flags) || !shift.flags.some(flagId => flags[flagId] && !flags[flagId].productivity));
}

/**
 * Checks if a shift has at least 1 flag marked as working shift
 * @param {Object} shift Shift to check
 * @param {Array} flags List of hospital flags
 * @returns {Boolean}
 */
export function isWorkingShiftForValidation (shift, flags) {
  return !shift.available && !shift.canceled && !shift.onCall && !shift.giveaway && !shift.swapped &&
    (_.isEmpty(shift.flags) || !shift.flags.some(flagId => flags[flagId] && !flags[flagId].working));
}

export function isOnCallWorkingShiftForValidation (shift, flags) {
  return shift.onCall && !shift.available && !shift.canceled && !shift.giveaway && !shift.swapped &&
    (_.isEmpty(shift.flags) || !shift.flags.some(flagId => flags[flagId] && !flags[flagId].working));
}

/**
 * Checks if a shift should be shown with a strike through
 * @param {Object} shift Shift to check
 * @param {Array} flags List of hospital flags
 * @returns {Boolean}
 */
export function isWorkingShiftForDisplay (shift, flags) {
  return !shift.canceled && !shift.giveaway && !shift.swapped && (_.isEmpty(shift.flags) || !shift.flags.some(flagId => flags[flagId] && !flags[flagId].working));
}

/**
 * Sorts data
 * @param {Array} data Data to sort
 * @param {Array} order Sort orders
 * @returns {Array} Sorted data
 */
export function sortData (data, order) {
  if (order.length > 0) {
    return _.orderBy(data, order.map((o) => o.field), order.map((o) => o.direction));
  }

  return data;
}

/**
 * Sorts and group shifts by user
 * @param {Array} data Shifts to sort
 * @param {Array} order Sort orders
 * @param {Object} extraInfo Extra info to add to the data. Keyed by assignee ID
 * @returns {Array} Sorted data
 */
export function sortAndGroupShifts (shifts, order, extraInfo) {
  const sortedShifts = sortData(shifts, order);
  const groupedShifts = {};
  let index = 0;
  for (let i = 0, count = sortedShifts.length; i < count; i++) {
    const { assigneeId } = sortedShifts[i];
    let extras = {};
    if (extraInfo && extraInfo[assigneeId]) {
      extras = extraInfo[assigneeId];
    }
    if (!groupedShifts[assigneeId]) {
      groupedShifts[assigneeId] = {
        assignee: sortedShifts[i].assignee,
        index,
        activities: [],
        ...extras
      };
      index++;
    }
    groupedShifts[assigneeId].activities.push(sortedShifts[i]);
  }

  return _.sortBy(_.values(groupedShifts), (g) => g.index);
}

export function prepareLastDates (lastDate, userInfo, type) {
  for (let assigneeId in lastDate) {
    _.set(userInfo, [assigneeId, 'last', type], lastDate[assigneeId].last);
  }
  return userInfo;
}

/**
 *
 * @param {Arrray} weeklyShifts List of shifts for one week
 * @param {Object} employees Hospital employees keyed by user ID
 * @returns {Object} User info prepareShiftHours
 */
export function prepareShiftHours (store, weeklyShifts, employees) {
  const shiftTypes = store.state.org.shiftTypes.reduce(
    (obj, shiftType) => (obj[shiftType.id] = shiftType, obj), // eslint-disable-line no-return-assign, no-sequences
    {}
  );
  const flags = store.state.org.flags.reduce((flags, value) => {
    flags[value.id] = value;
    return flags;
  }, {});
  for (let i = 0, count = weeklyShifts.length; i < count; i++) {
    const shift = weeklyShifts[i];
    if (employees[shift.assigneeId] && isWorkingShiftForValidation(shift, flags)) {
      const hours = calculateShiftHours(shift, shiftTypes[shift.typeId]);
      if (!employees[shift.assigneeId].hours.daily[shift.date]) {
        employees[shift.assigneeId].hours.daily[shift.date] = 0;
      }
      employees[shift.assigneeId].hours.daily[shift.date] += hours;
      employees[shift.assigneeId].hours.weekly += hours;
    }
  }
  return employees;
}

export function shiftMatchesFilters (shift, filters, store) {
  let flagIds = store.state.org.flags.map((f) => f.id);
  if (_.get(filters, 'shift.flags', []).length > 0) {
    flagIds = filters.shift.flags;
  }
  if (filters.shift) {
    let matches = (filters.shift.types.length === 0 || filters.shift.types.includes(shift.typeId));
    if (matches) {
      switch (filters.shift.mode) {
        case 'available':
          matches &= shift.available;
          break;
        case 'canceled':
          matches &= shift.canceled;
          break;
      }
    }
    if (matches) {
      if (filters.shift.flagsMode === 'contains') {
        const intersection = _.intersection(flagIds, shift.flags).length;
        switch (filters.shift.flagsOp) {
          case 'or':
            if (filters.shift.flags.length === 0) {
              matches &= (intersection > 0 || shift.flags.length === 0);
            } else {
              matches &= intersection > 0;
            }
            break;
          case 'and':
            matches &= intersection === flagIds.length;
            break;
        }
      } else {
        const difference = _.difference(flagIds, shift.flags).length;
        switch (filters.shift.flagsOp) {
          case 'or':
            if (filters.shift.flags.length === 0) {
              matches &= (difference === flagIds.length || shift.flags.length === 0);
            } else {
              matches &= difference === flagIds.length;
            }
            break;
          case 'and':
            matches &= difference > 0;
            break;
        }
      }
    }
    return matches;
  }
  return true;
}

/**
 * Checks if the given shift was modified after creation
 * @param {Object} shift Shift to check
 */
export function wasShiftModified (shift) {
  // createdOn and modifiedOn have miroseconds data so we only compare up to seconds to avoid the very small
  // difference between the two fields when a shift is created.
  return moment(shift.createdOn).format('YYYY-MM-DD HH:mm:ss') !== moment(shift.modifiedOn).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Checks if the given shift was modified by management and modification includes creation of the shift
 * @param {Object} shift Shift to check
 * @param {Object} state Store state
 */
export function wasShiftModifiedByManagement (shift, state) {
  // createdOn and modifiedOn have miroseconds data so we only compare up to seconds to avoid the very small
  // difference between the two fields when a shift is created.
  const createdBy = _.get(state.org.employees, [shift.createdBy, 'classification'], '');
  return wasShiftModified(shift) || createdBy !== 'staff';
}
